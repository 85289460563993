import { useMediaQuery } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import Colors from "../../assets/colors/Colors";
import { Autocomplete } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import React from "react";

const CustomAutocomplete = (props) => {
  const {
    open,
    setOpen,
    loading,
    onChange,
    onBlur,
    setInputValueAuto,
    customers,
    selectAllRef,
    handleKeyDown,
    formik,
    setOptions,
    customerRef,
    buttonDisable,
    onClose,
    disabled,
    renderOption,
    isExtraLarge,
    isLarge,
    isMeduim1,
    handleCheckboxChange,
  } = props;
  return (
    <Autocomplete
      multiple
      size="small"
      filterOptions={(x) => x}
      id="autoselect"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={onClose}
      loading={loading}
      onBlur={onBlur}
      disabled={disabled}
      onInputChange={(event, newInputValue) => {
        if (event.type == "change") {
          setInputValueAuto(newInputValue);
        }
      }}
      style={{
        width: isExtraLarge
          ? "20rem"
          : isLarge
          ? "17rem"
          : isMeduim1
          ? "13.32rem"
          : "100%",
        backgroundColor: "rgba(88, 129, 254, 0.05)",
        borderRadius: "0.625rem",
        fontSize: "0.94rem",
        fontWeight: 300,
        color: "#010048",
        height: props.height,
        borderRadius: "10px",
        border: "1px solid rgba(183, 183, 183, 0.12)",
      }}
      panelStyle={{
        backgroundColor: "#F4F4F4",
        width: "20.625rem",
        borderRadius: "0.625rem",
        border: "1px solid rgba(183, 183, 183, 0.12)",
      }}
      renderOption={(props, option, { selected }) => {
        let isSelected = formik?.values?.selectedCustomers?.find(
          (customer) => customer.id === option.id
        );
        const { key, ...optionProps } = props;
        return (
          <li {...optionProps} key={option.id}>
            <Checkbox
              checked={isSelected}
              onChange={(e) => handleCheckboxChange(e, option)}
              // onChange={(e) => {
              //   onChange(e, option);
              //   if (e.target.checked) {
              //     // Add to selected customers
              //     customerRef.current = [
              //         ...customerRef.current,
              //         { id: option.id, name: option.name },
              //     ];
              // } else {
              //     // Remove from selected customers
              //     customerRef.current = customerRef.current.filter(
              //         (customer) => customer.id !== option.id
              //     );
              // }
              // // Update formik state
              // formik.setFieldValue("selectedCustomers", customerRef.current);
              // }}
            />
            {option.name}
          </li>
        );
      }}
      getOptionLabel={(option) => option?.name || ""}
      options={customers || []}
      disableCloseOnSelect={true}
      renderTags={() => null}
      renderInput={(params) => (
        <TextField
          {...params}
          label=""
          placeholder={"Select Reward Customers*"}
          onKeyDown={handleKeyDown}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null
                // (<ChevronDownIcon fontSize={28} fontWeight="300"/>)
                }
                {/* {params.InputProps.endAdornment} */}
              </React.Fragment>
            ),
            style: {
              fontSize: "0.94rem",
              fontStyle: "normal",
              fontWeight: "300",
              lineHeight: "0.74rem",
              letterSpacing: "-0.01rem",
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
              "&.Mui-focused fieldset": {
                // border: "0.1rem solid",
                borderColor: Colors.buttonHover,
                // boxShadow: "0rem 0rem 0rem 0.188rem " + Colors.inputHover,
                boxShadow: "0 0 0 0.2rem #c7d2fe",
              },
            },
          }}
        />
      )}
    />
  );
};

export default CustomAutocomplete;
