import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import Tippy from "@tippyjs/react";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useSubmit,
} from "react-router-dom";
import "tippy.js/dist/tippy.css";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import toolTip from "../../../assets/images/tool_tip_hover_icon.svg";
import {
  AppContext,
  PosBreadCrumb,
  PosCountryStateDropDown,
  PosFormButton,
  PosInput,
  PosLable,
  PosSwitchButton,
  PosTab,
  PosTable,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { twofix } from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import {
  getAllCountriesState,
  getPriceBooksData,
  getStateListForSelectedCountry,
} from "./RewardCardService";

const RewardCardsCreate = (props) => {
  const myContext = useContext(AppContext);
  const [buttonDisable, setButtonDisable] = useState(false);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const stateData = useLocation();
  const loaderResponse = useLoaderData();
  const effectRun = useRef(true);
  const actionResponse = useActionData();
  const actionRun = useRef(false);
  const [countries, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [customerDataBalance, setCustomerDataBalance] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [paramData, setParamData] = useState(stateData.state); // store in state
  const [countryError, setCountryError] = useState("");
  const [stateError, setStateError] = useState("");
  const [previousSelectedCountry, setPreviousSelectedCountry] = useState("");
  const [isScreenSize480] = useMediaQuery("(max-width: 3rem)");
  const [userBalance, setUserBalance] = useState(0);
  const [totalcount, setTotalCount] = useState(0);
  const [stateAvailable, setStatesAvailability] = useState(1);
  const [curruntTab, setCurruntTab] = useState(0);
  const [isSecondTabVisible, setIsSecondTabVisible] = useState(false);
  const tabNames = ["Basic Details", "Credit Account"];
  const [priceBooks, setPriceBooks] = useState([]);
  const [selectedPriceBooks, setSelectedPriceBooks] = useState([]);
  const [selectedPriceBookDetails, setSelectedPriceBookDetails] = useState({});
  const [selectedPriceBooksProducts, setSelectedPriceBookProducts] = useState([]);

  const {
    isOpen: isCoupProdViewModelOpen,
    onOpen: onCoupProdViewModelOpen,
    onClose: onCoupProdViewModelClose,
  } = useDisclosure();
  const [selectedPriceProdViewDetails, setSelectedPriceBookProductDetails] =
    useState([]);

  // const priceBookDetailColumns = [
  //   {
  //     columnNames: "Price Book Id",
  //     sortName: "",
  //     width: "",
  //   },
  //   {
  //     columnNames: "Price Book Name",
  //     sortName: "",
  //     width: "",
  //   },
  //   {
  //     columnNames: "Product Details",
  //     sortName: "",
  //     width: "",
  //   },
  //   {
  //     columnNames: "Delete",
  //     sortName: "",
  //     width: "",
  //   },
  // ];

  const priceBookProductsDetailColumns = [
    {
      columnNames: "Product Id",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Name",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Barcode",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Sale Price($)",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Price Book Amount($)",
      sortName: "",
      width: "",
    },
  ];

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          getAllPriceBooks();
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (actionRun.current === true) {
      if (
        undefined != actionResponse &&
        null != actionResponse &&
        {} != actionResponse
      ) {
        setButtonDisable(false);
        if (
          undefined != actionResponse?.error &&
          null != actionResponse?.error &&
          actionResponse?.error
        ) {
          let actionData = actionResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else {
          props.navigate(Constants.REWARD_CARDS_LIST);
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REWARD_CARD_MODULE,
            alertDescription: actionResponse.data.message,
          });
          myContext.handleLoading(false);
        }
      }
      // return () => {
      // actionRun.current = false;
      // };
    }
  }, [actionResponse]);
  const handleSelectedCountry = (value) => {
    try {
      setSelectedCountry(value);
      setStates([]);
      setSelectedState(null);
      setStateError("");
      formikCustomer.setFieldValue("selectedState", null);

      myContext.handleLoading(true);
      getStateListForSelectedCountry(value)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            const statesData = response.data.data.states;
            const arrayStates = Object.keys(statesData).map((key) => ({
              label: statesData[key],
              value: key,
            }));
            setStates(arrayStates);
            const customerState =
              loaderResponse?.data?.data?.customerData?.state;
            if (
              customerState &&
              customerState !== "N.A" &&
              customerState !== null &&
              statesData[customerState]
            ) {
              if (
                loaderResponse?.data?.data?.customerData?.country !==
                previousSelectedCountry
              ) {
                setSelectedState(customerState);
              } else if (
                loaderResponse?.data?.data?.customerData?.country ===
                previousSelectedCountry
              ) {
                setSelectedState("");
              }
              setPreviousSelectedCountry(
                loaderResponse?.data?.data?.customerData?.country
              );
              formikCustomer.setFieldValue("selectedState", customerState);
              setStatesAvailability(0);
            } else {
              // setSelectedState("N.A");
              // formikCustomer.setFieldValue("selectedState", "N.A");
              setStatesAvailability(1);
            }
          } else {
            setStates([]);
            setSelectedState("N.A");
            formikCustomer.setFieldValue("selectedState", "N.A");
            setStatesAvailability(1);
          }
          myContext.handleLoading(false);
        })
        .catch((err) => {
          setStates([]);
          setSelectedState("N.A");
          formikCustomer.setFieldValue("selectedState", "N.A");
          setStatesAvailability(1);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setStates([]);
      setSelectedState("N.A");
      formikCustomer.setFieldValue("selectedState", "N.A");
      setStatesAvailability(1);
      let actionData = error;
      error({ actionData });
      myContext.handleLoading(false);
    }
  };
  const handleSelectedState = (value) => {
    setSelectedState(value);
    if (value == "N.A") {
      setStatesAvailability(1);
    } else {
      setStatesAvailability(0);
    }
  };
  const getCountryStates = async () => {
    try {
      getAllCountriesState()
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            const arrayCountries = Object.keys(
              response.data.data.countries
            ).map((key) => ({
              label: response.data.data.countries[key],
              value: key,
            }));
            setCountry(arrayCountries);
            myContext.handleLoading(false);
          } else {
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      myContext.handleLoading(false);
    }
  };
  const getAllPriceBooks = async () => {
    try {
      getPriceBooksData()
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            if (
              undefined != response.data?.data?.priceBooks &&
              null != response.data?.data?.priceBooks &&
              "" != response.data?.data?.priceBooks
            ) {
              setPriceBooks(response.data?.data?.priceBooks);
            }
            myContext.handleLoading(false);
          } else {
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      myContext.handleLoading(false);
    }
  };

  const handleTabChange = (index) => {
    setCurruntTab(index);
    // if (index == 1) {
    //   setSearchQuery("");
    //   setPaginationModel({
    //     page: 0,
    //     pageSize: 25,
    //   });
    //   effectRun.current = true;
    //   setLoading(true);
    // } else if (index == 2) {
    //   setSearchQuery("");
    //   setPaginationModel({
    //     page: 0,
    //     pageSize: 25,
    //   });
    //   // fetchOrderData();
    //   effectRun.current = true;
    //   setLoading(true);
    // }
  };
  const onSelectPriceB = (event) => {
    // const distinctCheck = selectedPriceBooks.find(element => element['id'] == event.value.id);
    if(event.value){
      let data = Object.assign({}, event.value);
      setSelectedPriceBooks([event.value]);
      setSelectedPriceBookDetails(data);
      setSelectedPriceBookProducts(data.products);
    }
    else{
      onDeletepriceBook(selectedPriceBooks[0].id);
    }
  };
  const priceBookProductViewColumns = [
    {
      columnNames: "Id",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Name",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Barcode",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Sale Price($)",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Price Book Sale Price($)",
      sortName: "",
      width: "",
    },
  ];

  const selectedpriceBookTemplate = (option, props) => {
    if (option) {
      return (
        <Box>
          <Stack direction={"row"} spacing="0.38rem">
            <Box>
              <Stack direction={"column"} spacing="0.44rem">
                <Box>
                  <Heading
                    color={Colors.posTextInfo}
                    fontSize={"0.94rem"}
                    fontStyle={"normal"}
                    fontWeight={"500"}
                    lineHeight={"normal"}
                    letterSpacing={"-0.01rem"}
                  >
                    {option.name}
                  </Heading>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Box>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const priceBookOptionTemplate = (option) => {
    return (
      <Box>
        <Stack direction={"row"} spacing="0.38rem">
          <Box>
            <Stack direction={"column"} spacing="0.44rem">
              <Box>
                <Heading
                  color={Colors.posTextInfo}
                  fontSize={"0.94rem"}
                  fontStyle={"normal"}
                  fontWeight={"500"}
                  lineHeight={"normal"}
                  letterSpacing={"-0.01rem"}
                >
                  {option.name}
                </Heading>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Box>
    );
  };

  const viewProductDetails = (productsDetails) => {
    var uniqueProducts = productsDetails.reduce((uniqueProdArray, element) => {
      if (!uniqueProdArray.find((v) => v["barcode"] === element["barcode"])) {
        const productPayload = {
          id: element.products.id,
          name: element.products.name,
          barcode: element.products.barcode,
          sale_price: element.products.sale_price,
          price_book_sale_price: element.price_book_sale_price,
        };
        uniqueProdArray.push(productPayload);
      }
      return uniqueProdArray;
    }, []);
    setSelectedPriceBookProductDetails(uniqueProducts);
    onCoupProdViewModelOpen();
  };
  // const finalSelectedpriceBooks = selectedPriceBooks.map((priceBook) => ({
  //   id: priceBook.id,
  //   name: priceBook.name,
  //   productDetailsIcon: (
  //     <IconButton
  //       sx={{ color: Colors.posNavbarLink }}
  //       onClick={() => viewProductDetails(priceBook.products)}
  //     >
  //       <VisibilityIcon />
  //     </IconButton>
  //   ),
  // }));

  const finalSelectedpriceBooksProducts = selectedPriceBooksProducts.map((priceBook) => ({
    id: priceBook.products.id,
    name: priceBook.products.name,
    barcode: priceBook.products.barcode,
    sale_price: priceBook.products.sale_price,
    price_book_sale_price: priceBook.price_book_sale_price,
  }));
  const onDeletepriceBook = (priceBookId) => {
    const updatedSelectedpriceBooksArray = selectedPriceBooks.filter(
      (priceBook) => priceBook.id !== priceBookId
    );
    setSelectedPriceBooks(updatedSelectedpriceBooksArray);
    setSelectedPriceBookProducts([]);
  };
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      notes: "",
      reward_customer: false,
    },
    validationSchema: Yup.object({
      // first_name: Yup.string()
      //   .trim()
      //   .when("reward_customer", {
      //     is: true,
      //     then: (schema) =>
      //       schema
      //         .trim()
      //         .required(Constants.CUSTOMER_NAME_REQUIRED)
      //         .max(191, Constants.CUSTOMER_NAME_LENGTH_GREATER_THAN_191),
      //     otherwise: (schema) => schema.nullable(),
      //   }),
      email: Yup.string().when("reward_customer", {
        is: true,
        then: (schema) =>
          schema
            .trim()
            .required(Constants.USER_EMAIL_REQUIRED)
            .email(Constants.USER_EMAIL_NOT_VALID)
            .matches(Constants.EMAIL_REGEX, Constants.USER_EMAIL_NOT_VALID)
            .max(254, Constants.USER_EMAIL_LESS_THEN_OR_EQUAL_255_CHARACTER),
        otherwise: (schema) => schema.nullable(),
      }),
      first_name: Yup.string()
        .trim()
        .nullable()
        .max(191, Constants.REWARD_CARD_FIRST_NAME_LENGTH_GREATE_THEN_255),
      last_name: Yup.string()
        .trim()
        .nullable()
        .max(191, Constants.REWARD_CARD_LAST_NAME_LENGTH_GREATE_THEN_255),
      // email: Yup.string()
      //   .trim()
      //   .email(Constants.USER_EMAIL_NOT_VALID)
      //   .nullable()
      //   .matches(Constants.EMAIL_REGEX, Constants.USER_EMAIL_NOT_VALID)
      //   // .trim()
      //   .max(254, Constants.USER_EMAIL_LESS_THEN_OR_EQUAL_255_CHARACTER),
      phone: Yup.number()
        .required(Constants.PHONE_IS_REQUIRED)
        .typeError(Constants.PHONE_NOT_VALID)
        .integer(Constants.PHONE_NOT_VALID)
        .min(100, Constants.PHONE_MIN_VALUE)
        .max(9999999999, Constants.PHONE_MAX_VALUE),
      notes: Yup.string()
        // .trim()
        .max(191, Constants.REWARD_CARD_NOTES_LENGTH_GREATE_THEN_255)
        .nullable(),
    }),
    onSubmit: async (values) => {
      if (
        undefined != values.reward_customer &&
        true == values.reward_customer
      ) {
        formikCustomer.values.tax_exempt = formikCustomer.values.tax_exempt
          ? 1
          : 0;
        formikCustomer.values.exclude_from_reward_calculation = formikCustomer
          .values.exclude_from_reward_calculation
          ? 1
          : 0;
        formikCustomer.values.country = selectedCountry;
        formikCustomer.values.state = selectedState || "N.A";
        formikCustomer.values.stateNotAvailable = stateAvailable;
      }
      const payload = {
        ...values,
        price_book:
          undefined != selectedPriceBooks && selectedPriceBooks.length > 0
            ? selectedPriceBooks[0].id
            : "",
        reward_customer: values.reward_customer ? 1 : 0,
        customer_data:
          undefined != values.reward_customer && true == values.reward_customer
            ? JSON.stringify(formikCustomer.values)
            : [],
      };
      actionRun.current = true;
      myContext.handleLoading(true);
      setButtonDisable(true);
      submit(payload, {
        method: Constants.POST,
        path: Constants.TAX_CREATE_API_ROUTE,
      });
    },
  });
  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(
        formik.values.first_name.length <= 0 ||
        formik.errors.first_name ||
        formik.errors.last_name ||
        formik.errors.email ||
        formik.errors.phone ||
        formik.errors.notes ||
        buttonDisable
      )
    ) {
      formik.handleSubmit();
    }
  };
  const formikCustomer = useFormik({
    initialValues: {
      // name: "",
      // email: "",
      name:"",
      country: "",
      state: "",
      city: "",
      // phone: "",
      address1: "",
      address2: "",
      selectedState: "",
      selectedCountry: "",
      notes: "",
      tax_exempt: false,
      credit_limit: "",
      exclude_from_reward_calculation: false,
      amount: "",
      external_reference:"",
      zipcode:"",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required(Constants.USER_BUSINESS_NAME_REQUIRED)
        .max(191, Constants.USER_BUSINESS_NAME_LENGTH_GREATER_THAN_191),
      address1: Yup.string()
        .trim()
        .required(Constants.CUSTOMER_ADDRESS_NAME_REQUIRED)
        .max(5000, Constants.CUSTOMER_ADDRESS_LENGTH_GREATE_THEN_5000),
      zipcode: Yup.number()
        .required(Constants.LOCATION_ZIP_REQUIRED)
        .integer(Constants.LOCATION_ZIP_VALID)
        .typeError(Constants.LOCATION_ZIP_VALID)
        .min(9, Constants.LOCATION_ZIP_MUST_BE_2_DIGITS)
        .max(9999999999, Constants.LOCATION_ZIP_MUST_BE_10_DIGITS),
      address2: Yup.string().max(
        5000,
        Constants.CUSTOMER_ADDRESS_LENGTH_GREATE_THEN_5000
      ),
      city: Yup.string()
        .trim()
        .required(Constants.CUSTOMER_PLEASE_ENTER_A_CITY)
        .max(191, Constants.CUSTOMER_CITY_LENGTH_GREATE_THEN_191),
      // phone: Yup.string()
      //   .required(Constants.CUSTOMER_PLEASE_ENTER_A_PHONE_NUMBER)
      //   .max(
      //     14,
      //     Constants.CUSTOMER_PHONE_NUMBER_MUST_BE_LESS_THAN_15_DIGITS_LONG
      //   )
      //   .min(
      //     3,
      //     Constants.CUSTOMER_PHONE_NUMBER_MUST_BE_GREATER_THAN_2_DIGITS_LONG
      //   )
      //   .matches(/^\d*$/, Constants.THIS_IS_NOT_A_VALID_PHONE_NUMBER),
      // email: Yup.string()
      //   .required(Constants.USER_EMAIL_REQUIRED)
      //   .email(Constants.USER_EMAIL_NOT_VALID)
      //   .matches(Constants.EMAIL_REGEX, Constants.VENDOR_EMAIL_NOT_VALID)
      //   .max(191, Constants.CUSTOMER_EMAIL_LENGTH_GREATE_THEN_191),
      notes: Yup.string().max(
        191,
        Constants.CUSTOMER_NOTES_LENGTH_GREATE_THEN_191
      ),
      credit_limit: Yup.number()
        .typeError(Constants.CUSTOMER_PLEASE_ENTER_A_VALID_CREDIT_LIMIT)
        .max(999999.99, Constants.CUSTOMER_CREDIT_LIMIT_MAX_ERROR)
        .min(0.01, Constants.CUSTOMER_CREDIT_LIMIT_MIN_ERROR),
      selectedCountry: Yup.string().required(
        Constants.CUSTOMER_PLEASE_SELECT_A_COUNTRY
      ),
      selectedState: Yup.string().required(
        Constants.CUSTOMER_PLEASE_SELECT_A_STATE
      ),
      // country: Yup.string().required(
      //   Constants.CUSTOMER_PLEASE_SELECT_A_COUNTRY
      // ),
      // state: Yup.string().required(Constants.CUSTOMER_PLEASE_SELECT_A_STATE),
      tax_exempt: Yup.string().required(
        Constants.CUSTOMER_PLEASE_SELECT_A_TAX_EXEMPT_OPTION
      ),
      exclude_from_reward_calculation: Yup.string().required(
        Constants.CUSTOMER_PLEASE_SELECT_A_REWARD_CALCULATION_OPTION
      ),
      amount: Yup.number()
        // .required(Constants.CUSTOMER_PLEASE_ENTER_A_AMOUNT)
        .typeError("Please enter a valid amount")
        .max(999999.99, Constants.CUSTOMER_AMOUNT_MAX_VALUE)
        .min(0.01, Constants.CUSTOMER_CREDIT_LIMIT_MIN_ERROR),
      external_reference: Yup.string().max(
        191,
        Constants.CUSTOMER_EXTERNAL_REFERENCE_LENGTH_GREATE_THEN_191
      ),
    }),
    onSubmit: async (values) => {
      myContext.handleLoading(true);
      const payload = {
        ...values,
        tax_exempt: values.tax_exempt ? 1 : 0,
        exclude_from_reward_calculation: values.exclude_from_reward_calculation
          ? 1
          : 0,
        country: selectedCountry,
        state: selectedState || "N.A",
        stateNotAvailable: stateAvailable,
      };
      setButtonDisable(true);
      actionRun.current = true;
      submit(payload, {
        method: Constants.POST,
        path: Constants.CUSTOMER_CREATE_API_ROUTE,
      });
    },
  });
  return (
    <Flex bg={Colors.loginAuthBackground}>
      <Box flex="1">
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={"flex-start"}
          pt={{ base: "1.5rem", md: "3.06rem" }}
          pb={{ base: "1.5rem", md: "0" }}
          pl={{ base: "1rem", lg: "1.88rem" }}
          pr={{ base: "0.5rem", md: "1.88rem" }}
          gap={2}
        >
          <PosBreadCrumb
            handleClick={(i) => {
              props.navigate(Constants.REWARD_CARDS_LIST, {
                state: stateData.state,
              });
            }}
            breadCrumNames={["Reward Customers", "Create a Reward Customer"]}
            breadCrumTitle={"Create a Reward Customer"}
          />
          <Spacer />
          <Stack direction={"row"} alignSelf={"flex-end"}>
            <PosFormButton
              onClick={() => {
                props.navigate(Constants.REWARD_CARDS_LIST, {
                  state: stateData.state,
                });
              }}
              isDisabled={buttonDisable}
              buttonText={"Cancel"}
              CancelButton={true}
            />
            <PosFormButton
              isDisabled={
                (formik.values.reward_customer == true &&
                  (
                    // formik.values.first_name.length <= 0 ||
                    // formik.errors.first_name ||
                    formikCustomer.errors.address1 ||
                    formikCustomer.errors.city ||
                    formikCustomer.errors.credit_limit ||
                    formikCustomer.errors.country ||
                    formikCustomer.errors.state ||
                    !formikCustomer.values.selectedState ||
                    stateError.length > 0 ||
                    !formikCustomer.isValid)) ||
                !formik.isValid ||
                !formik.dirty ||
                buttonDisable
                  ? true
                  : false
              }
              buttonsubmit={"Submit"}
              SubmitButton={true}
              onClick={formik.handleSubmit}
            />
          </Stack>
        </Flex>
        <Box
          mt={{ base: 4, lg: 12 }}
          ml={{ base: 4, lg: 14 }}
          mr={{ base: 4, lg: 14 }}
          mb={{ base: 2, lg: 3 }}
          borderRadius="0.63rem"
          bg={Colors.posPageDataBackground}
          boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
          p="8"
        >
          <Tabs onChange={(index) => handleTabChange(index)}>
            <PosTab tabNames={tabNames} />
            <TabPanels>
              <TabPanel>
                <Flex
                  spacing={4}
                  gap={{ base: 0, md: 14, lg: 20 }}
                  direction={{ base: "column", md: "row" }}
                >
                  <Box flex={1}>
                    <Stack spacing={1}>
                      <PosLable
                        fontWeight={500}
                        label={true}
                        requiredLabel={false}
                        name={"First Name"}
                      />
                      <PosInput
                        id="first_name"
                        placeholder={"Jane"}
                        handleInputChange={formik.handleChange}
                        inputValue={formik.values.first_name}
                        handleBlur={(e) => {
                          formik.setFieldValue(
                            "first_name",
                            formik.values.first_name.trim()
                          );
                          formik.handleBlur(e);
                        }}
                        onKeyDown={handleKeyDown}
                        posInputGroup={true}
                        inputType={"text"}
                        inputError={
                          formik.touched.first_name && formik.errors.first_name
                        }
                      >
                        <DriveFileRenameOutlineIcon
                          style={{ fill: "#5881FE" }}
                        />
                      </PosInput>
                      <Text color={Colors.errorColor}>
                        {formik.touched.first_name &&
                        formik.errors.first_name ? (
                          <span>{formik.errors.first_name}</span>
                        ) : null}
                      </Text>
                    </Stack>
                  </Box>
                  <Box flex={1}>
                    <Stack spacing={1}>
                      <PosLable
                        fontWeight={500}
                        label={true}
                        name={"Last Name"}
                      />
                      <PosInput
                        id="last_name"
                        placeholder={"Doe"}
                        handleInputChange={formik.handleChange}
                        inputValue={formik.values.last_name}
                        handleBlur={(e) => {
                          formik.setFieldValue(
                            "last_name",
                            formik.values.last_name.trim()
                          );
                          formik.handleBlur(e);
                        }}
                        onKeyDown={handleKeyDown}
                        posInputGroup={true}
                        inputType={"text"}
                        inputError={
                          formik.touched.last_name && formik.errors.last_name
                        }
                      >
                        <DriveFileRenameOutlineIcon
                          style={{ fill: "#5881FE" }}
                        />
                      </PosInput>
                      <Text color={Colors.errorColor}>
                        {formik.touched.last_name && formik.errors.last_name ? (
                          <span>{formik.errors.last_name}</span>
                        ) : null}
                      </Text>
                    </Stack>
                  </Box>
                </Flex>
                <Flex
                  spacing={4}
                  gap={{ base: 0, md: 14, lg: 20 }}
                  direction={{ base: "column", md: "row" }}
                >
                  <Box flex="1">
                    <Stack spacing={1}>
                      <PosLable
                        fontWeight={500}
                        label={true}
                        requiredLabel={formik.values.reward_customer}
                        name={"Email Address"}
                      />
                      <PosInput
                        id="email"
                        placeholder={"Janedoe@example.com"}
                        handleInputChange={formik.handleChange}
                        inputValue={formik.values.email}
                        handleBlur={(e) => {
                          formik.setFieldValue(
                            "email",
                            formik.values.email.trim()
                          );
                          formik.handleBlur(e);
                        }}
                        onKeyDown={handleKeyDown}
                        posInputGroup={true}
                        inputType={"email"}
                        inputError={formik.touched.email && formik.errors.email}
                      >
                        <EmailIcon color="#5881FE" />
                      </PosInput>
                      <Text color={Colors.errorColor}>
                        {formik.touched.email && formik.errors.email ? (
                          <span>{formik.errors.email}</span>
                        ) : null}
                      </Text>
                    </Stack>
                  </Box>
                  <Box flex={1}>
                    <Stack spacing={1}>
                      <PosLable
                        fontWeight={500}
                        requiredLabel={true}
                        name={"Phone Number"}
                      />
                      <PosInput
                        id="phone"
                        placeholder={"5555551234"}
                        handleInputChange={formik.handleChange}
                        inputValue={formik.values.phone}
                        handleBlur={(e) => {
                          formik.setFieldValue(
                            "phone",
                            formik.values.phone.trim()
                          );
                          formik.handleBlur(e);
                        }}
                        onKeyDown={handleKeyDown}
                        posInputGroup={true}
                        inputType={"text"}
                        inputError={formik.touched.phone && formik.errors.phone}
                      >
                        <PhoneIcon color="#5881FE" />
                      </PosInput>
                      <Text color={Colors.errorColor}>
                        {formik.touched.phone && formik.errors.phone ? (
                          <span>{formik.errors.phone}</span>
                        ) : null}
                      </Text>
                    </Stack>
                  </Box>
                </Flex>
                <Stack spacing={1}>
                  <PosLable fontWeight={500} label={true} name={"Notes"} />
                  <PosInput
                    id="notes"
                    placeholder={""}
                    handleInputChange={formik.handleChange}
                    inputValue={formik.values.notes}
                    handleBlur={(e) => {
                      formik.setFieldValue("notes", formik.values.notes.trim());
                      formik.handleBlur(e);
                    }}
                    onKeyDown={handleKeyDown}
                    inputType={"text"}
                    posInput={true}
                    inputError={formik.touched.notes && formik.errors.notes}
                  />
                  <Text color={Colors.errorColor}>
                    {formik.touched.notes && formik.errors.notes ? (
                      <span>{formik.errors.notes}</span>
                    ) : null}
                  </Text>
                </Stack>
                <Stack spacing={1}>
                  <PosLable
                    label={true}
                    name={"Please select a Price Book"}
                    fontWeight={"500"}
                  />
                  <Dropdown
                    // disabled={selectedPriceBooks.length > 0}
                    onChange={(e) => onSelectPriceB(e)}
                    options={priceBooks}
                    optionLabel="name"
                    placeholder="-- Select a Price Book --"
                    filter
                    value={selectedPriceBooks[0]}
                    // valueTemplate={selectedpriceBookTemplate}
                    // itemTemplate={priceBookOptionTemplate}
                    className="w-full md:w-14rem"
                    style={{ width: "100%" }}
                    resetFilterOnHide={true}
                    showClear={true}
                  />
                  {/* <Dropdown
                    disabled={selectedPriceBooks.length > 0}
                    onChange={(e) => onSelectPriceB(e)}
                    options={priceBooks}
                    optionLabel="name"
                    placeholder="-- Select a Price Book --"
                    filter
                    valueTemplate={selectedpriceBookTemplate}
                    itemTemplate={priceBookOptionTemplate}
                    className="w-full md:w-14rem"
                    style={{ width: "100%" }}
                    resetFilterOnHide={true}
                  /> */}
                  {/* {selectedPriceBooks.length > 0 && (
                    <Box mt={{ base: "2rem", md: "2rem" }}>
                      <PosTable
                        columnNames={priceBookDetailColumns}
                        rawdata={finalSelectedpriceBooks}
                        totalcount={selectedPriceBooks.length}
                        footerHide={true}
                        singleDeleteButton={true}
                        goSingleDelete={(id) => onDeletepriceBook(id)}
                        sortColumnName={null}
                        page={null}
                        rowsPerPage={null}
                        order={null}
                      />
                    </Box>
                  )} */}
                  {selectedPriceBooksProducts.length > 0 && (
                    <Box>
                      <Text
                        mt={"1.3rem"}
                        mb={"2.3rem"}
                        fontSize="1.13rem"
                        color={Colors.posTextInfo}
                        style={{
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "normal",
                          letterSpacing: "-0.01rem",
                        }}
                      >
                        Price Book Product Details
                      </Text>
                      <Box maxHeight={"31.25rem"} overflow={"auto"}>
                      <PosTable
                        columnNames={priceBookProductsDetailColumns}
                        rawdata={finalSelectedpriceBooksProducts}
                        totalcount={selectedPriceBooksProducts.length}
                        footerHide={true}
                        noAction={true}
                        // singleDeleteButton={true}
                        // goSingleDelete={(id) => onDeletepriceBook(id)}
                        sortColumnName={null}
                        page={null}
                        rowsPerPage={null}
                        order={null}
                      />
                      </Box>
                    </Box>
                  )}
                  {/* <Modal
                    isOpen={isCoupProdViewModelOpen}
                    onClose={onCoupProdViewModelClose}
                    size={"3xl"}
                    isCentered
                    closeOnOverlayClick={false}
                    scrollBehavior={"inside"}
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>Product Details</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <PosTable
                          columnNames={priceBookProductViewColumns}
                          rawdata={selectedPriceProdViewDetails}
                          totalcount={selectedPriceProdViewDetails.length}
                          footerHide={true}
                          noAction={true}
                          sortColumnName={null}
                          page={null}
                          rowsPerPage={null}
                          order={null}
                        />
                      </ModalBody>
                    </ModalContent>
                  </Modal> */}
                </Stack>
              </TabPanel>
              <TabPanel>
                <Stack flexDirection={"row"} spacing={1}>
                  <PosLable
                    fontWeight={500}
                    label={true}
                    name={"Do you want to treat this reward customer as a credit account customer?"}
                  />
                  <PosSwitchButton
                    mt={"2rem"}
                    ml={"1rem"}
                    id="reward_customer"
                    switchValue={formik.values.reward_customer}
                    onChange={(e) => {
                      formik.handleChange(e);
                      getCountryStates();
                      formikCustomer.resetForm();
                    }}
                  />
                </Stack>
                {formik.values.reward_customer && (
                  <>
                    {/* <Box flex="1">
                      <Stack spacing={2}>
                        <PosLable
                          name={"Name"}
                          requiredLabel={true}
                          fontSize="0.94rem"
                          fontStyle="normal"
                          fontWeight={500}
                          lineHeight="normal"
                          letterSpacing="-0.02rem"
                        />
                        <PosInput
                          id="name"
                          mt={"1rem"}
                          placeholder={"Name"}
                          color={Colors.posCommon}
                          handleInputChange={formikCustomer.handleChange}
                          inputValue={formikCustomer.values.name}
                          handleBlur={formikCustomer.handleBlur}
                          // onKeyDown={handleKeyDown}
                          posInputGroup={true}
                          inputType={"text"}
                          inputError={
                            formikCustomer.touched.name &&
                            formikCustomer.errors.name
                          }
                        >
                          <DriveFileRenameOutlineIcon
                            sx={{
                              height: "1.21rem",
                              width: "1.21rem",
                              background: Colors.posInputGroupBackground,
                              borderRadius: "0.31rem",
                              color: Colors.posNavbarLink,
                            }}
                          />
                        </PosInput>
                        <Text color={Colors.posCancleButtonRed}>
                          {formikCustomer.touched.name &&
                          formikCustomer.errors.name ? (
                            <span>{formikCustomer.errors.name}</span>
                          ) : null}
                        </Text>
                      </Stack>
                    </Box> */}
                    {/* <Box flex="1">
                      <Stack spacing={2}>
                        <PosLable
                          name={"Phone Number"}
                          requiredLabel={true}
                          fontSize="0.94rem"
                          fontStyle="normal"
                          fontWeight={500}
                          lineHeight="normal"
                          letterSpacing="-0.02rem"
                        />
                        <PosInput
                          id="phone"
                          mt={"1rem"}
                          placeholder={"Phone"}
                          color={Colors.posCommon}
                          handleInputChange={formikCustomer.handleChange}
                          inputValue={formikCustomer.values.phone}
                          handleBlur={formikCustomer.handleBlur}
                          // onKeyDown={handleKeyDown}
                          posInputGroup={true}
                          inputType={"text"}
                          inputError={
                            formikCustomer.touched.phone &&
                            formikCustomer.errors.phone
                          }
                        >
                          <Img
                            src={Phone}
                            alt="Phone"
                            sx={{
                              height: "1.21rem",
                              width: "1.21rem",
                              background: Colors.posInputGroupBackground,
                              borderRadius: "0.31rem",
                              color: Colors.posNavbarLink,
                            }}
                          />
                        </PosInput>
                        <Text color={Colors.posCancleButtonRed}>
                          {formikCustomer.touched.phone &&
                          formikCustomer.errors.phone ? (
                            <span>{formikCustomer.errors.phone}</span>
                          ) : null}
                        </Text>
                      </Stack>
                    </Box> */}
                    {/* <Stack spacing={2}>
                      <PosLable
                        name={"Email"}
                        requiredLabel={true}
                        fontStyle="normal"
                        fontSize="0.94rem"
                        fontWeight={500}
                        lineHeight="normal"
                        letterSpacing="-0.02rem"
                      />
                      <PosInput
                        id="email"
                        mt={"1rem"}
                        placeholder={"email"}
                        color={Colors.posCommon}
                        handleInputChange={formikCustomer.handleChange}
                        inputValue={formikCustomer.values.email}
                        handleBlur={formikCustomer.handleBlur}
                        posInputGroup={true}
                        inputType={"text"}
                        inputError={
                          formikCustomer.touched.email &&
                          formikCustomer.errors.email
                        }
                      >
                        <DriveFileRenameOutlineIcon
                          sx={{
                            height: "1.21rem",
                            width: "1.21rem",
                            background: Colors.posInputGroupBackground,
                            borderRadius: "0.31rem",
                            color: Colors.posNavbarLink,
                          }}
                        />
                      </PosInput>
                      <Text color={Colors.posCancleButtonRed}>
                        {formikCustomer.touched.email &&
                        formikCustomer.errors.email ? (
                          <span>{formikCustomer.errors.email}</span>
                        ) : null}
                      </Text>
                    </Stack> */}
                    {(<Flex
                      spacing={4}
                      gap={{ base: 0, md: 14, lg: 20 }}
                      direction={{ base: "column", md: "row" }}
                    >
                      <Box flex={1}>
                        <Stack spacing={1}>
                          <PosLable
                            fontWeight={500}
                            label={true}
                            requiredLabel={formik.values.reward_customer}
                            name={"Business Name"}
                          />
                          <PosInput
                            id="name"
                            placeholder={"Enter Business Name"}
                            handleInputChange={formikCustomer.handleChange}
                            inputValue={formikCustomer.values.name}
                            handleBlur={(e) => {
                              formikCustomer.setFieldValue(
                                "name",
                                formikCustomer.values.name.trim()
                              );
                              formikCustomer.handleBlur(e);
                            }}
                            onKeyDown={handleKeyDown}
                            posInputGroup={true}
                            inputType={"text"}
                            inputError={
                              formikCustomer.touched.name && formikCustomer.errors.name
                            }
                          >
                            <DriveFileRenameOutlineIcon
                              style={{ fill: "#5881FE" }}
                            />
                          </PosInput>
                          <Text color={Colors.errorColor}>
                            {formikCustomer.touched.name &&
                            formikCustomer.errors.name ? (
                              <span>{formikCustomer.errors.name}</span>
                            ) : null}
                          </Text>
                        </Stack>
                      </Box>
                      <Box flex={1}>

                    <Stack spacing={1}>
                      <PosLable
                        fontWeight={500}
                        label={true}
                        requiredLabel={formik.values.reward_customer}
                        name={"Email Address"}
                      />
                      <PosInput
                        id="email"
                        placeholder={"Janedoe@example.com"}
                        handleInputChange={formik.handleChange}
                        inputValue={formik.values.email}
                        handleBlur={(e) => {
                          formik.setFieldValue(
                            "email",
                            formik.values.email.trim()
                          );
                          formik.handleBlur(e);
                        }}
                        onKeyDown={handleKeyDown}
                        posInputGroup={true}
                        inputType={"email"}
                        inputError={formik.touched.email && formik.errors.email}
                      >
                        <EmailIcon color="#5881FE" />
                      </PosInput>
                      <Text color={Colors.errorColor}>
                        {formik.touched.email && formik.errors.email ? (
                          <span>{formik.errors.email}</span>
                        ) : null}
                      </Text>
                    </Stack>
                      </Box>
                      {/* <Box flex={1}>
                        <Stack spacing={1}>
                          <PosLable
                            fontWeight={500}
                            label={true}
                            name={"Last Name"}
                          />
                          <PosInput
                            id="last_name"
                            placeholder={"Doe"}
                            handleInputChange={formik.handleChange}
                            inputValue={formik.values.last_name}
                            handleBlur={(e) => {
                              formik.setFieldValue(
                                "last_name",
                                formik.values.last_name.trim()
                              );
                              formik.handleBlur(e);
                            }}
                            onKeyDown={handleKeyDown}
                            posInputGroup={true}
                            inputType={"text"}
                            inputError={
                              formik.touched.last_name && formik.errors.last_name
                            }
                          >
                            <DriveFileRenameOutlineIcon
                              style={{ fill: "#5881FE" }}
                            />
                          </PosInput>
                          <Text color={Colors.errorColor}>
                            {formik.touched.last_name && formik.errors.last_name ? (
                              <span>{formik.errors.last_name}</span>
                            ) : null}
                          </Text>
                        </Stack>
                      </Box> */}
                    </Flex>)}
                    {
                    (<>
                    {/* <Stack spacing={1}>
                      <PosLable
                        fontWeight={500}
                        label={true}
                        requiredLabel={formik.values.reward_customer}
                        name={"Email Address"}
                      />
                      <PosInput
                        id="email"
                        placeholder={"Janedoe@example.com"}
                        handleInputChange={formik.handleChange}
                        inputValue={formik.values.email}
                        handleBlur={(e) => {
                          formik.setFieldValue(
                            "email",
                            formik.values.email.trim()
                          );
                          formik.handleBlur(e);
                        }}
                        onKeyDown={handleKeyDown}
                        posInputGroup={true}
                        inputType={"email"}
                        inputError={formik.touched.email && formik.errors.email}
                      >
                        <EmailIcon color="#5881FE" />
                      </PosInput>
                      <Text color={Colors.errorColor}>
                        {formik.touched.email && formik.errors.email ? (
                          <span>{formik.errors.email}</span>
                        ) : null}
                      </Text>
                    </Stack> */}
                    <Box flex={1}>
                    <Stack spacing={1}>
                      <PosLable
                        fontWeight={500}
                        requiredLabel={true}
                        name={"Phone Number"}
                      />
                      <PosInput
                        id="phone"
                        placeholder={"5555551234"}
                        handleInputChange={formik.handleChange}
                        inputValue={formik.values.phone}
                        handleBlur={(e) => {
                          formik.setFieldValue(
                            "phone",
                            formik.values.phone.trim()
                          );
                          formik.handleBlur(e);
                        }}
                        onKeyDown={handleKeyDown}
                        posInputGroup={true}
                        inputType={"text"}
                        inputError={formik.touched.phone && formik.errors.phone}
                      >
                        <PhoneIcon color="#5881FE" />
                      </PosInput>
                      <Text color={Colors.errorColor}>
                        {formik.touched.phone && formik.errors.phone ? (
                          <span>{formik.errors.phone}</span>
                        ) : null}
                      </Text>
                    </Stack>
                  </Box>
                  </>
                  )}
                    <Stack spacing={2}>
                      <PosLable
                        name={"Address Line 1"}
                        requiredLabel={true}
                        fontStyle="normal"
                        fontSize="0.94rem"
                        fontWeight={500}
                        lineHeight="normal"
                        letterSpacing="-0.02rem"
                      />
                      <PosInput
                        id="address1"
                        mt={"1rem"}
                        placeholder={"Enter Address Line 1"}
                        color={Colors.posCommon}
                        handleInputChange={formikCustomer.handleChange}
                        inputValue={formikCustomer.values.address1}
                        handleBlur={formikCustomer.handleBlur}
                        // onKeyDown={handleKeyDown}
                        posInputGroup={true}
                        inputType={"text"}
                        inputError={
                          formikCustomer.touched.address1 &&
                          formikCustomer.errors.address1
                        }
                      >
                        <DriveFileRenameOutlineIcon
                          sx={{
                            height: "1.21rem",
                            width: "1.21rem",
                            background: Colors.posInputGroupBackground,
                            borderRadius: "0.31rem",
                            color: Colors.posNavbarLink,
                          }}
                        />
                      </PosInput>
                      <Text color={Colors.posCancleButtonRed}>
                        {formikCustomer.touched.address1 &&
                        formikCustomer.errors.address1 ? (
                          <span>{formikCustomer.errors.address1}</span>
                        ) : null}
                      </Text>
                    </Stack>
                    <Stack spacing={2}>
                      <PosLable
                        name={"Address Line 2"}
                        label={true}
                        fontStyle="normal"
                        fontSize="0.94rem"
                        fontWeight={500}
                        lineHeight="normal"
                        letterSpacing="-0.02rem"
                      />
                      <PosInput
                        id="address2"
                        mt={"1rem"}
                        placeholder={"Enter Address Line 2"}
                        color={Colors.posCommon}
                        handleInputChange={formikCustomer.handleChange}
                        inputValue={formikCustomer.values.address2}
                        handleBlur={formikCustomer.handleBlur}
                        // onKeyDown={handleKeyDown}
                        posInputGroup={true}
                        inputType={"text"}
                        inputError={
                          formikCustomer.touched.address2 &&
                          formikCustomer.errors.address2
                        }
                      >
                        <DriveFileRenameOutlineIcon
                          sx={{
                            height: "1.21rem",
                            width: "1.21rem",
                            background: Colors.posInputGroupBackground,
                            borderRadius: "0.31rem",
                            color: Colors.posNavbarLink,
                          }}
                        />
                      </PosInput>
                      <Text color={Colors.posCancleButtonRed}>
                        {formikCustomer.touched.address2 &&
                        formikCustomer.errors.address2 ? (
                          <span>{formikCustomer.errors.address2}</span>
                        ) : null}
                      </Text>
                    </Stack>

                    <Flex
                      p={{ base: "0" }}
                      gap={{ base: "0", md: "9" }}
                      direction={{ base: "column", md: "row" }}
                    >
                      <Box flex="1">
                        <PosLable
                          name={"City"}
                          requiredLabel={true}
                          fontStyle="normal"
                          fontSize="0.94rem"
                          fontWeight={500}
                          lineHeight="normal"
                          letterSpacing="-0.02rem"
                        />
                        <PosInput
                          id="city"
                          mt={"1rem"}
                          placeholder={"--Select City--"}
                          color={Colors.posCommon}
                          handleInputChange={formikCustomer.handleChange}
                          inputValue={formikCustomer.values.city}
                          handleBlur={formikCustomer.handleBlur}
                          // onKeyDown={handleKeyDown}
                          posInputGroup={true}
                          inputType={"text"}
                          inputError={
                            formikCustomer.touched.city &&
                            formikCustomer.errors.city
                          }
                        >
                          <DriveFileRenameOutlineIcon
                            sx={{
                              height: "1.21rem",
                              width: "1.21rem",
                              background: Colors.posInputGroupBackground,
                              borderRadius: "0.31rem",
                              color: Colors.posNavbarLink,
                            }}
                          />
                        </PosInput>
                        <Text color={Colors.posCancleButtonRed}>
                          {formikCustomer.touched.city &&
                          formikCustomer.errors.city ? (
                            <span>{formikCustomer.errors.city}</span>
                          ) : null}
                        </Text>
                        </Box>
                        <Box flex="1">
                        <PosLable
                          name={"Zipcode"}
                          label={true}
                          requiredLabel={true}
                          fontWeight={500}
                        ></PosLable>
                        <PosInput
                          posInputGroup={true}
                          id={"zipcode"}
                          posNumberInput={true}
                          inputType={"number"}
                          placeholder={"Enter Zipcode"}
                          inputValue={formikCustomer.values.zipcode || ""}
                          handleBlur={formikCustomer.handleBlur}
                          handleInputChange={formikCustomer.handleChange}
                          inputError={formikCustomer.touched.zipcode && formikCustomer.errors.zipcode}
                        >
                          <DriveFileRenameOutlineIcon style={{ fill: "#5881FE" }} />
                        </PosInput>
                        <Text color={Colors.errorColor}>
                          {formikCustomer.touched.zipcode && formikCustomer.errors.zipcode ? (
                            <span>{formikCustomer.errors.zipcode}</span>
                          ) : null}
                        </Text>
                      </Box>
                    </Flex>
                    <Flex
                      p={{ base: "0" }}
                      gap={{ base: "0", md: "9" }}
                      direction={{ base: "column", md: "row" }}
                    >
                      <Box flex="1">
                        <PosLable
                          name={"Country"}
                          requiredLabel={true}
                          fontStyle="normal"
                          fontSize="0.94rem"
                          fontWeight={500}
                          lineHeight="normal"
                          letterSpacing="-0.02rem"
                        />
                        <PosCountryStateDropDown
                          options={countries}
                          value={formikCustomer.values.selectedCountry}
                          countriesSelect={true}
                          onChange={(e) => {
                            if (
                              undefined != e.target.value &&
                              null != e.target.value &&
                              "" != e.target.value
                            ) {
                              handleSelectedCountry(e.target.value);
                              formikCustomer.setFieldValue(
                                "selectedCountry",
                                e.target.value
                              );
                            }
                          }}
                          onBlur={(e) => {
                            formikCustomer.setFieldTouched(
                              "selectedCountry",
                              true
                            );
                            // if (!e.target.value) {
                            //   setCountryError(Constants.CUSTOMER_PLEASE_SELECT_A_COUNTRY);
                            // } else {
                            //   setCountryError("");
                            // }
                          }}
                          optionLabel="label"
                          placeholder="--Select Country--"
                          width={"100%"}
                          maxWidth={"42rem"}
                          height={"2.5rem"}
                          resetFilterOnHide={true}
                          className="w-full md:w-20rem"
                          lableAvailable={true}
                          disabled={false}
                        />
                        <Text color={Colors.errorColor}>
                          {formikCustomer.touched.selectedCountry &&
                          countryError.length > 0 ? (
                            <span>{countryError}</span>
                          ) : null}
                        </Text>
                      </Box>

                      <Box flex="1">
                        <PosLable
                          name={"State"}
                          requiredLabel={true}
                          fontStyle="normal"
                          fontSize="0.94rem"
                          fontWeight={500}
                          lineHeight="normal"
                          letterSpacing="-0.02rem"
                          pb={"0.31rem"}
                        />
                        {states.length === 0 ? (
                          <Select
                            id="state"
                            value={formikCustomer.values.selectedState || "N.A"}
                            sx={{
                              color: "gray",
                              fontSize: "0.94rem !important",
                              fontWeight: 300,
                              letterSpacing: "-0.01rem !important",
                              backgroundColor: "#5881fe0d",
                              borderRadius: "0.625rem",
                              height: "2.5rem",
                              border: "1px solid #b7b7b71f",
                              "&[disabled]": {
                                backgroundColor: "#5881fe0d !important",
                                opacity: 1,
                              },
                            }}
                            // onChange={(e) => {
                            //   handleSelectedState(e.target.value);
                            //   formikCustomer.setFieldValue("selectedState", e.target.value);
                            // }}
                            isDisabled
                            placeholder={
                              states.length > 0 ? "N.A" : "--Select State--"
                            }
                          >
                            <option value="N.A">N.A</option>
                          </Select>
                        ) : (
                          <PosCountryStateDropDown
                            options={states}
                            value={selectedState}
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              if (
                                undefined != e.value &&
                                null != e.value &&
                                "" != e.value
                              ) {
                                handleSelectedState(selectedValue);
                                formikCustomer.setFieldValue(
                                  "selectedState",
                                  selectedValue
                                );
                                formikCustomer.setFieldTouched(
                                  "selectedState",
                                  true
                                );
                                if (!selectedValue) {
                                  formikCustomer.setFieldError(
                                    "selectedState",
                                    Constants.CUSTOMER_PLEASE_SELECT_A_STATE
                                  );
                                } else {
                                  formikCustomer.setFieldError(
                                    "selectedState",
                                    ""
                                  );
                                }
                              }
                            }}
                            onBlur={() => {
                              formikCustomer.setFieldTouched(
                                "selectedState",
                                true
                              );
                            }}
                            optionLabel="label"
                            placeholder="--Select State--"
                            width={"100%"}
                            maxWidth={"42rem"}
                            height={"2.5rem"}
                            resetFilterOnHide={true}
                            className="w-full md:w-20rem"
                            lableAvailable={true}
                            disabled={false}
                          />
                        )}
                        {states.length > 0 && (
                          <Text color={Colors.errorColor}>
                            {formikCustomer.touched.selectedState &&
                            formikCustomer.errors.selectedState ? (
                              <span>{formikCustomer.errors.selectedState}</span>
                            ) : null}
                          </Text>
                        )}
                      </Box>
                    </Flex>
                    <Text
                      fontSize="1.13rem"
                      mt={"2rem"}
                      color={Colors.posTextInfo}
                      style={{
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                        letterSpacing: "-0.01rem",
                      }}
                    >
                      Other Details
                    </Text>
                    <Stack spacing={3}>
                      <PosLable
                        fontWeight={500}
                        name={"Intial Amount"}
                        label={true}
                      />
                      <PosInput
                        id={"amount"}
                        placeholder={"Enter Initial Amount"}
                        handleInputChange={formikCustomer.handleChange}
                        inputValue={formikCustomer.values.amount}
                        // handleBlur={formikCustomer.handleBlur}
                        // onKeyDown={handleKeyDown}
                        handleBlur={(e) => {
                          formikCustomer.handleBlur(e);
                          formikCustomer.setFieldValue(
                            "amount",
                            twofix(formikCustomer.values.amount) || ""
                          );
                        }}
                        onKeyDown={(e) => {
                          if (
                            e.key === "-" ||
                            e.key === "ArrowUp" ||
                            e.key === "ArrowDown"
                          ) {
                            e.preventDefault();
                          }
                        }}
                        inputType={"number"}
                        posNumberInput={true}
                      />
                      <Text color={Colors.posCancleButtonRed}>
                        {formikCustomer.touched.amount &&
                        formikCustomer.errors.amount ? (
                          <span>{formikCustomer.errors.amount}</span>
                        ) : null}
                      </Text>
                    </Stack>
                    <Stack spacing={2}>
                      <PosLable
                        name={"Reference/Reason"}
                        label={true}
                        fontStyle="normal"
                        fontSize="0.94rem"
                        fontWeight={500}
                        lineHeight="normal"
                        letterSpacing="-0.02rem"
                      />
                      <PosInput
                        id="external_reference"
                        placeholder={"Enter Reference/Reason"}
                        color={Colors.posCommon}
                        handleInputChange={formikCustomer.handleChange}
                        inputValue={formikCustomer.values.external_reference}
                        handleBlur={formikCustomer.handleBlur}
                        // onKeyDown={handleKeyDown}
                        posInputGroup={true}
                        inputType={"text"}
                        inputError={
                          formikCustomer.touched.external_reference &&
                          formikCustomer.errors.external_reference
                        }
                      >
                        <DriveFileRenameOutlineIcon
                          sx={{
                            height: "1.21rem",
                            width: "1.21rem",
                            background: Colors.posInputGroupBackground,
                            borderRadius: "0.31rem",
                            color: Colors.posNavbarLink,
                          }}
                        />
                      </PosInput>
                      <Text color={Colors.posCancleButtonRed}>
                        {formikCustomer.touched.external_reference &&
                        formikCustomer.errors.external_reference ? (
                          <span>{formikCustomer.errors.external_reference}</span>
                        ) : null}
                      </Text>
                    </Stack>
                    <Stack spacing={3} mt="0.13rem">
                      <PosLable
                        label={true}
                        name={"Notes"}
                        mt="0.31rem"
                        fontStyle="normal"
                        fontSize="0.94rem"
                        fontWeight={500}
                        lineHeight="normal"
                        letterSpacing="-0.02rem"
                      />
                      <Textarea
                        id="notes"
                        placeholder=""
                        borderRadius={"0.63rem"}
                        border={Colors.posTextAreaBorder}
                        background={Colors.posInputBackground}
                        onChange={formikCustomer.handleChange}
                        value={formikCustomer.values.notes}
                        onBlur={formikCustomer.handleBlur}
                        // onKeyDown={handleKeyDown}
                        inputerror={
                          formikCustomer.touched.notes &&
                          formikCustomer.errors.notes
                        }
                      />
                      <Text color={Colors.errorColor}>
                        {formikCustomer.touched.notes &&
                        formikCustomer.errors.notes ? (
                          <span>{formikCustomer.errors.notes}</span>
                        ) : null}
                      </Text>
                    </Stack>
                    {null != formikCustomer.values.notes &&
                    "" != formikCustomer.values.notes &&
                    191 - formikCustomer.values.notes.length > 0 ? (
                      <Text
                        pt={"0.13rem"}
                        color={Colors.textareaColor}
                        fontSize={"0.94rem"}
                        fontStyle={"normal"}
                        fontWeight={300}
                        lineHeight={"normal"}
                        letterSpacing={"-0.01rem"}
                      >
                        {191 - formikCustomer.values.notes.length} characters
                        left for notes
                      </Text>
                    ) : (
                      ""
                    )}
                    <Stack spacing={3}>
                      <PosLable
                        fontWeight={500}
                        name={"Credit Limit"}
                        label={true}
                      />
                      <PosInput
                        id={"credit_limit"}
                        placeholder={"Credit Limit"}
                        handleInputChange={formikCustomer.handleChange}
                        inputValue={formikCustomer.values.credit_limit}
                        // handleBlur={formikCustomer.handleBlur}
                        handleBlur={(e) => {
                          formikCustomer.handleBlur(e);
                          formikCustomer.setFieldValue(
                            "credit_limit",
                            twofix(formikCustomer.values.credit_limit)
                          );
                        }}
                        // onKeyDown={handleKeyDown}
                        inputType={"number"}
                        posNumberInput={true}
                      />
                      <Text color={Colors.posCancleButtonRed}>
                        {formikCustomer.touched.credit_limit &&
                        formikCustomer.errors.credit_limit ? (
                          <span>{formikCustomer.errors.credit_limit}</span>
                        ) : null}
                      </Text>
                    </Stack>
                    <Flex flexDirection={"row"}>
                      <PosLable
                        fontWeight={500}
                        label={true}
                        name={"Tax Exempt"}
                      />
                      <Tippy
                        content="Turn on, to exempt this customer on tax payments."
                        interactive={true}
                        maxWidth={390}
                        placement="top"
                        animation="fade"
                        inertia={true}
                        moveTransition="transform 0.2s ease-out"
                        theme="tomato"
                      >
                        <Image
                          src={toolTip}
                          alt="Tooltip"
                          ml={{ base: "0.4rem", md: "0.3rem" }}
                          mt={"2rem"}
                        />
                      </Tippy>
                    </Flex>
                    <Stack
                      direction="row"
                      mt="0.313rem"
                      w="2.75rem"
                      height="1.38rem"
                      flexShrink="0"
                    >
                      <PosSwitchButton
                        id="tax_exempt"
                        switchValue={formikCustomer.values.tax_exempt}
                        onChange={formikCustomer.handleChange}
                      />
                    </Stack>
                    <Flex flexDirection={"row"}>
                      <PosLable
                        fontWeight={500}
                        label={true}
                        name={"Exclude from Reward Calculation"}
                      />
                      <Tippy
                        content="Turn on, to exclude this customer from reward calculations."
                        interactive={true}
                        maxWidth={390}
                        placement="top"
                        animation="fade"
                        inertia={true}
                        moveTransition="transform 0.2s ease-out"
                        theme="tomato"
                      >
                        <Image
                          src={toolTip}
                          alt="Tooltip"
                          ml={{ base: "0.4rem", md: "0.3rem" }}
                          mt={"2rem"}
                        />
                      </Tippy>
                    </Flex>
                    <Stack
                      direction="row"
                      mt="0.313rem"
                      w="2.75rem"
                      height="1.38rem"
                      flexShrink="0"
                    >
                      <PosSwitchButton
                        id="exclude_from_reward_calculation"
                        switchValue={
                          formikCustomer.values.exclude_from_reward_calculation
                        }
                        onChange={formikCustomer.handleChange}
                      />
                    </Stack>
                  </>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
        {/* </Box> */}
      </Box>
    </Flex>
  );
};
export default WithRouter(RewardCardsCreate);
