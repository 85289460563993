import { Box, Flex, Select, Spacer, Stack, Text } from "@chakra-ui/react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit,useLocation } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosFormButton,
  PosInput,
  PosLable,
  PosTostMessage,
  PosDropDown
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import Autocomplete from '@mui/material/Autocomplete';
import { ThemeProvider } from "@mui/material/styles";
import { Mtheme } from "../../../theme/index";
import TextField from '@mui/material/TextField';
import {getPrintersList} from './PosService';

const PosStationDuplicate = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const effectRun = useRef(true);
  const actionRun = useRef(false);
  const [locations, setLocations] = useState([]);
  const [allValue, setSettings] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedPosName, setSelectedPosName] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [tipsArray, setTipsData] = useState(true);
  const stateData = useLocation(); // get param data
  const [paramData, setParamData] = useState(stateData.state); // store in state

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading1 = open && options.length === 0;
  const [autoSelectedPrinter, setAutoSelectedPrinter] = useState(null);
  // const [inputValueAuto, setInputValueAuto] = useState(null);
  const [largePrinterData, hideLargePrinterData] = useState(false);
  const [largePrinterName, setLargePrinterName] = useState(null);
  const [secondReceiptData, hideSecondReceiptData] = useState(false);
  const [secondPrintTypes, setSecondPrintTypes] = useState(['NO']);
  
  const sleep = (duration) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, duration);
    });
  }

  useEffect(() => {
    let active = true;
  
    if (!loading1) {
      return undefined;
    }
  
    (async () => {
      await sleep(450); // For demo purposes.
      if (active) {
        search();
      }
    })();
  
    return () => {
      active = false;
    };
  }, [loading1]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const search = () => {
    const printersAPIKey = allValue.map((fl) => {
      // Find the first matching object where vl.key is "large_printer_api_key" and vl.value is not null or empty
      const apiV = fl.category_data.find((vl) => 
        vl.key === "large_printer_api_key" && vl.value !== null && vl.value !== ''
      );
      
      // If apiV exists, return its value; otherwise, return null
      return apiV ? apiV.value : null;
    }).filter(value => value !== null);
    if(printersAPIKey.length > 0){
      try {
        let data ={
          printer_api_key: printersAPIKey[0]
        }
        getPrintersList(data)
          .then((response) => {
            if (response.data.flag === true) {
              setOptions(response.data.data);
              myContext.handleLoading(false);
            } else {
              setOptions([]);
              setOpen(false);
              let actionData = response;
              error({ actionData });
              myContext.handleLoading(false);
            }
          })
          .catch((err) => {
            setOptions([]);
            setOpen(false);
            let actionData = err;
            error({ actionData });
            myContext.handleLoading(false);
          });
      } catch (error) {
        setOptions([]);
        setOpen(false);
        myContext.handleLoading(false);
      }
    }
    else{
      setOpen(false);
      setAutoSelectedPrinter(null);
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.POS_MODULE,
        alertDescription: 'Large Printer API Key is required.',
      });
    }
};

const autoCompleteSelectedPrinter= (prin) => {
  if(undefined != prin && null != prin && '' != prin){
    setAutoSelectedPrinter(prin);
    // setLargePrinterName(prin.name);
    let printerDetails=[];
    allValue.map((fl) => {
      fl.category_data.map((vl, ind) => {
        if(vl.key == 'large_printer'){
          printerDetails.push({
            id: prin.id,
            name: prin.name,
          });
          vl.value = (printerDetails);
        }
      });
    });
  }
  else{
    setAutoSelectedPrinter(null);
    setLargePrinterName(null);
    setOptions([]);
  }
}

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          // set success and error part as per all screen refer tax module
          myContext.handleLoading(false);
          let locationList = [];
          loaderResponse.data?.data?.location.map((data) => {
            locationList.push(data);
          });
          locationList.map((dt) => {
            dt.pos_station.map((pos) => {
              if (pos.id == loaderResponse.data.data?.pos_station_id) {
                setSelectedLocation(dt);
                setSelectedPosName(pos.name);
              }
            });
          });

          const tipsArray = [];
          Object.keys(loaderResponse.data.data?.settings).map((key) => {
            loaderResponse.data.data?.settings[key].map((f) => {
              f.setting_key = f.key;
              if (f.key == "tip_perc_1" && ("" == f.value || null == f.value)) {
                f.value = "15";
              }
              if (f.key == "tip_perc_2" && ("" == f.value || null == f.value)) {
                f.value = "20";
              }
              if (f.key == "tip_perc_3" && ("" == f.value || null == f.value)) {
                f.value = "25";
              }
              if (
                f.key == "tip_perc_1" ||
                f.key == "tip_perc_2" ||
                f.key == "tip_perc_3"
              ) {
                tipsArray.push({
                  tip: f.value,
                });
              }
              if(f.key == "print_on_large_printer" && ("" == f.value || null == f.value || 'no' == f.value.toLowerCase())){
                hideLargePrinterData(true);
              }
              if(f.key == "large_printer" && ("" != f.value && null != f.value && 'null' != f.value)){
                if(JSON.parse(f.value).length>0){
                  setLargePrinterName(JSON.parse(f.value)[0].name);
                }
                else if(f.key == "large_printer"){
                  setLargePrinterName(null);
                }
              }
              if(f.key == "print_second_receipt") {
                if("" == f.value || null == f.value || f.value.length <= 0 || f.value.includes('NO') || f.value.includes('YES')) {
                  setSecondPrintTypes(['NO']);
                  hideSecondReceiptData(true);
                }
                else{
                  setSecondPrintTypes(f.value);
                }
              }
            });
          });

          setTipsData(tipsArray);

          const arraySettings = Object.keys(
            loaderResponse.data.data?.settings
          ).map((key) => ({
            category: key,
            category_data: loaderResponse.data.data?.settings[key],
          }));
          setLocations(locationList);
          setSettings(arraySettings);
        }
      }
      // return () => {
        effectRun.current = false;
      // };
    }
    if (actionRun.current === true) {
      if (
        undefined != actionResponse &&
        null != actionResponse &&
        {} != actionResponse
      ) {
        myContext.handleLoading(false);
        setButtonDisable(false);
        if (
          undefined != actionResponse?.error &&
          null != actionResponse?.error &&
          actionResponse?.error
        ) {
          let actionData = actionResponse;
          error({ actionData });
        } else if (
          actionResponse.data[Constants.FLAGE] !== null &&
          actionResponse.data[Constants.FLAGE] === true
        ) {
          props.navigate(Constants.POS_LIST,{
            state: paramData,
          });
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.POS_MODULE,
            alertDescription: actionResponse.data.message,
          });
        } else {
          let actionData = actionResponse;
          error({ actionData });
          myContext.handleLoading(false);
        }
      }
      // return () => {
        actionRun.current = false;
      // };
    }
  }, [loaderResponse, actionResponse]);

  const validateField = (value, key, field) => {
    if (field.required == 1 && !value && key == field.key) {
      return `${field.setting_display_name} is required`;
    }
    if (
      field.input_type == 0 &&
      value &&
      value.length > 191 &&
      key == field.key
    ) {
      return `${field.setting_display_name} cannot exceed 191 characters`;
    }
    // if (
    //   field.input_type == 0 &&
    //   value &&
    //   (key == "kitchen_printer_ip" || key == "printer_ip")
    // ) {
    //   const re = new RegExp(Constants.IP_REGEX);
    //   const testResult = re.test(value.trim().toLowerCase());
    //   if (!testResult) {
    //     return `Please enter a valid ${field.setting_display_name}.`;
    //   }
    // }
    if (
      field.input_type == 1 &&
      value &&
      (key == "tip_perc_1" || key == "tip_perc_2" || key == "tip_perc_3")
    ) {
      let flag = true;
      let error = "";
      if ("tip_perc_1" in validationErrors) {
        validationErrors["tip_perc_1"] = "";
      }
      if ("tip_perc_2" in validationErrors) {
        validationErrors["tip_perc_2"] = "";
      }
      if ("tip_perc_3" in validationErrors) {
        validationErrors["tip_perc_3"] = "";
      }
      // Function to check if any tip value exists more than one times
      const isValueRepeatedMoreThanTwice = (data) => {
        const valueCounts = {};
        data.forEach((item) => {
          const { tip } = item;
          if (valueCounts[tip]) {
            valueCounts[tip]++;
          } else {
            valueCounts[tip] = 1;
          }
        });

        if (Object.values(valueCounts).some((count) => count > 1)) {
          const findKeyWithValueMoreThanTwo = (obj) => {
            for (const key in obj) {
              if (Object.hasOwnProperty.call(obj, key)) {
                if (obj[key] >= 2) {
                  return key;
                }
              }
            }
            return null;
          };
          const keyWithValueMoreThanTwo =
            findKeyWithValueMoreThanTwo(valueCounts);
          error =
            keyWithValueMoreThanTwo +
            ` Percentage Tip is already selected. Please select different Tip Percentage.`;
          return true;
        }
      };

      const isRepeatedMoreThanTwice = isValueRepeatedMoreThanTwice(tipsArray);
      const failed = allValue.map((fl) => {
        let data = fl.category_data.map((vl) => {
          if (
            (vl.key == "tip_perc_2" || vl.key == "tip_perc_3") &&
            key == "tip_perc_1" &&
            value == vl.value &&
            flag
          ) {
            flag = false;
            error =
              value +
              ` Percentage Tip is already selected. Please select different Tip Percentage.`;
            return true;
          }
          if (
            (vl.key == "tip_perc_1" || vl.key == "tip_perc_3") &&
            key == "tip_perc_2" &&
            value == vl.value &&
            flag
          ) {
            flag = false;
            error =
              value +
              ` Percentage Tip is already selected. Please select different Tip Percentage.`;
            return true;
          }
          if (
            (vl.key == "tip_perc_1" || vl.key == "tip_perc_2") &&
            key == "tip_perc_3" &&
            value == vl.value &&
            flag
          ) {
            flag = false;
            error =
              value +
              ` Percentage Tip is already selected. Please select different Tip Percentage.`;
            return true;
          }
        });
        return data;
      });
      return error;
    }
    return "";
  };

  const handleSelectedLocation = (value) => {
    setSelectedLocation(value);
    formik.setFieldValue("location_id",value);
  };
  const handleNameChange = (value) => {
    setSelectedPosName(value);
  };

  const handleSettingChange = (i, key, value) => {
    if(key == 'print_on_large_printer'){
      setOptions([]);
      setOpen(false);
      if(value.toLowerCase() == 'yes'){
        hideLargePrinterData(false);
        setLargePrinterName(null);
      }
      else{
        hideLargePrinterData(true);
        setLargePrinterName(null);
      }
      setAutoSelectedPrinter(null);
    }
    const allValueUpdated = [...allValue];
    const tipsArrayUpdated = [];
    allValue.map((fl) => {
      fl.category_data.map((vl, ind) => {
        if (i == ind && key == vl.key) {
          vl.value = value;
        }
        if (
          vl.key == "tip_perc_1" ||
          vl.key == "tip_perc_2" ||
          vl.key == "tip_perc_3"
        ) {
          tipsArrayUpdated.push({
            tip: vl.value,
          });
        }
        if(key == 'print_on_large_printer' && value.toLowerCase() == 'no'){
          if(vl.key == 'large_printer_api_key'){
            vl.value = null;
          }
          if(vl.key == 'large_printer'){
            vl.value = null;
          }
        }
      });
    });
    setSettings(allValueUpdated);
    setTipsData(tipsArrayUpdated);
  };

  const handleSettingBlur = (key, field) => {
    const error = validateField(field.value, key, field);
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [key]: error,
    }));
  };

  const formik = useFormik({
    initialValues: {
      name:
        undefined != selectedPosName && null != selectedPosName
          ? selectedPosName
          : "",
      location_id:
        selectedLocation && null != selectedLocation ? selectedLocation : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string()
        .required(Constants.POS_NAME_REQUIRED)
        .max(191, Constants.POS_NAME_LESS_OR_EQUAL_191_CHARACTER),
      // location_id: Yup.string()
      //   .required(Constants.POS_LOCATION_REQUIRED),
    }),
    onSubmit: async (values) => {
      if(largePrinterData == false){
        if((null == largePrinterName || '' == largePrinterName) && (null == autoSelectedPrinter || '' == autoSelectedPrinter)){
          addToast({
            alertStatus: Constants.TOAST_TYPE_WARNING,
            alertTitle: Constants.POS_MODULE,
            alertDescription: 'Large Printer Details are required.',
          });
        }
        else{
          myContext.handleLoading(true);
          const payload = {
            ...values,
            location_id: selectedLocation.id,
            allValue: JSON.stringify(allValue),
            secondPrintTypes: JSON.stringify(secondPrintTypes),
          };
          setButtonDisable(true);
          actionRun.current = true;
          submit(payload, {
            method: Constants.POST,
            path: Constants.POS_SETTING_DUPLICATE_PERMISSION,
          });
        }
      }
      else{
      myContext.handleLoading(true);
      const payload = {
        ...values,
        location_id: selectedLocation.id,
        allValue: JSON.stringify(allValue),
        secondPrintTypes: JSON.stringify(secondPrintTypes),
      };
      setButtonDisable(true);
      actionRun.current = true;
      submit(payload, {
        method: Constants.POST,
        path: Constants.POS_SETTING_DUPLICATE_PERMISSION,
      });
    }
    },
  });
  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(
        selectedPosName <= 0 ||
        selectedLocation == null ||
        selectedLocation == "" ||
        Object.values(validationErrors).some((error) => !!error) ||
        buttonDisable
      )
    ) {
      formik.handleSubmit();
    }
  };

  const handleOptionSelect = (e) => {
    if(e.value.length > 0){
      const selectedTypes = e.value;
      const lastElement = selectedTypes[selectedTypes.length - 1];    
      if (lastElement == 'NO') {
        setSecondPrintTypes(["NO"]);
        hideSecondReceiptData(true);
      } else {
        const filteredItems = selectedTypes.filter((item) => item != 'NO');
        setSecondPrintTypes(filteredItems);
        hideSecondReceiptData(false);
      }
    }
    else{
      hideSecondReceiptData(true);
      setSecondPrintTypes(["NO"]);
    }
  };

  return (
    <Flex bg={Colors.loginAuthBackground}>
      <Box flex="1" onKeyDown={handleKeyDown}>
      <Box
        bg={Colors.loginAuthBackground}
        position="sticky"
        top="0"
        zIndex="sticky"
        pb={3}
        >
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={"flex-start"}
          pt={{ base: "1.5rem", md: "3.06rem" }}
          pb={{ base: "1.5rem", md: "0" }}
          /* pl={"1.88rem"} */
          pl={{ base: "1rem", lg: "1.88rem" }}
          pr={{ base: "0.5rem", md: "1.88rem" }}
          gap={2}
        >
          <PosBreadCrumb
            handleClick={(i) => {
              props.navigate(Constants.POS_LIST,{
                state: stateData.state,
              });
            }}
            breadCrumNames={["POS Stations", "Duplicate POS Station"]}
            breadCrumTitle={"Duplicate POS Station"}
          />
          <Spacer />
          <Stack direction={"row"} alignSelf={"flex-end"}>
            <PosFormButton
              onClick={() => {
                props.navigate(Constants.POS_LIST,{
                  state: stateData.state,
                });
              }}
              buttonText={"Cancel"}
              CancelButton={true}
              isDisabled={buttonDisable}
            />
            <PosFormButton
              isDisabled={
                formik.values.name.length <= 0 ||
                formik.errors.name ||
                selectedLocation == null ||
                selectedLocation == "" ||
                Object.values(validationErrors).some((error) => !!error) ||
                buttonDisable
                  ? true
                  : false
              }
              buttonsubmit={"Submit"}
              SubmitButton={true}
              onClick={formik.handleSubmit}
            />
          </Stack>
        </Flex>
        </Box>
        <Box
          mt={{ base: 4, lg: 12 }}
          ml={{ base: 4, lg: 14 }}
          mr={{ base: 4, lg: 14 }}
          borderRadius="0.63rem"
          bg={Colors.posPageDataBackground}
          boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
          p="8"
        >
          <Text
            fontSize="1.5rem"
            color={Colors.taxShow}
            style={{
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "-0.27px",
            }}
          >
            Basic Details
          </Text>
          <Stack spacing={1} mt="0.31rem">
            <PosLable requiredLabel={true} fontWeight={"350"} label={true} name={"POS Name"} mt="0.31rem" />
            <PosInput
              posInputGroup={true}
              id={"name"}
              name={`name`}
              inputType={"text"}
              placeholder={"Enter POS Station Name"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.name}
              handleBlur={formik.handleBlur}
              inputError={formik.touched.name && formik.errors.name}
            >
              <DriveFileRenameOutlineIcon style={{ fill: "#5881FE" }} />
            </PosInput>
            <Text color={Colors.errorColor}>
              {formik.touched.name && formik.errors.name ? (
                <span>{formik.errors.name}</span>
              ) : null}
            </Text>
          </Stack>
          <Stack spacing={1}>
            <Box className="card flex justify-content-center">
              <PosLable requiredLabel={true} fontWeight={"350"} name={"Location"} label={true}></PosLable>
              <PosDropDown
                id="location_id" 
                options={locations}
                value={selectedLocation}
                onChange={(e) => {
                  handleSelectedLocation(e.target.value);
                }}                
                  onBlur={(e) => {
                  formik.setFieldTouched("location_id", true);
                  formik.handleBlur(e);
                }}
                optionLabel="name"
                placeholder="Select Location"
                width={"100%"}
                className="w-full "
                maxSelectedLabels={2}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.location_id && formik.errors.location_id ? (
                  <span>{formik.errors.location_id}</span>
                ) : null}
              </Text>
              {/* {locations.length > 0 ? (
                <Select
                  value={formik.values.location_id}
                  color="#010048"
                  id="location_id"
                  onChange={(e) => {
                    handleSelectedLocation(e.target.value);
                  }}
                >
                  <option selected disabled value="">
                    --Select a Location--
                  </option>
                  {locations.map((data) => (
                    <option value={data.id}>{data.name}</option>
                  ))}
                </Select>
              ) : (
                <Select id="state">
                  <option selected hidden disabled value={"N.A."}>
                    {"N.A."}
                  </option>
                </Select>
              )} */}
            </Box>
          </Stack>
        </Box>

        {allValue.map((data,ind) => (
          <Box
            key={ind} // Ensure each `data` object has a unique `id` property
            mt={{ base: 2, lg: 4 }}
            ml={{ base: 4, lg: 14 }}
            mr={{ base: 4, lg: 14 }}
            borderRadius="0.63rem"
            bg={Colors.posPageDataBackground}
            boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
            p="8"
          >
            <Text
              fontSize="1.5rem"
              color={Colors.taxShow}
              style={{
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "-0.27px",
              }}
            >
              {data.category}
            </Text>
            {data.category_data.map((fl,i) => (
              <React.Fragment key={i}>
                {fl.input_type === 0 ? (
                  <Stack key={i} spacing={1} mt="0.31rem">
                    {fl.key == 'large_printer_api_key' && (largePrinterData == "false" || largePrinterData == false) ? (
                    <PosLable
                      label={true}
                      name={fl.setting_display_name}
                      mt="0.31rem"
                      fontWeight={"350"}
                    />):null}
                    {fl.key != 'large_printer_api_key' && (
                    <PosLable
                      label={true}
                      name={fl.setting_display_name}
                      mt="0.31rem"
                      fontWeight={"350"}
                    />)}
                    {fl.key == 'large_printer_api_key' && (largePrinterData == "false" || largePrinterData == false) ? (
                    <PosInput
                      id={fl.key}
                      placeholder={fl.setting_display_name}
                      handleInputChange={(e) => {
                        handleSettingChange(i, fl.key, e.target.value);
                      }}
                      handleBlur={() => handleSettingBlur(fl.key, fl)}
                      inputValue={fl.value}
                      posInput={true}
                      inputType={"text"}
                      disabled={(fl.key=='large_printer_api_key' && (largePrinterData == "true" || largePrinterData == true)) ? true : false}
                      // inputError={formik.touched.name && formik.errors.name}
                    />):null}
                    {fl.key != 'large_printer_api_key' && (
                    <PosInput
                      id={fl.key}
                      placeholder={fl.setting_display_name}
                      handleInputChange={(e) => {
                        handleSettingChange(i, fl.key, e.target.value);
                      }}
                      handleBlur={() => handleSettingBlur(fl.key, fl)}
                      inputValue={fl.value}
                      posInput={true}
                      inputType={"text"}
                      disabled={(fl.key=='large_printer_api_key' && (largePrinterData == "true" || largePrinterData == true)) ? true : false}
                    />
                  )}
                    <Text color={Colors.errorColor}>
                      {validationErrors[fl.key]}
                    </Text>
                  </Stack>
                ) : (
                  <Stack key={i} spacing={1}>
                    <Box className="card flex justify-content-center">
                    {fl.key != 'large_printer' && fl.key != 'second_receipt_print_type' && (
                      <PosLable
                        name={fl.setting_display_name}
                        label={true}
                        fontWeight={"350"}
                      ></PosLable>
                      )}
                      {fl.key == 'second_receipt_print_type' && (secondReceiptData == "false" || secondReceiptData == false) ? (
                        <PosLable
                          name={fl.setting_display_name}
                          label={true}
                          fontWeight={"350"}
                        ></PosLable>
                      ):null}
                      {fl.options.length > 0 ? (
                        fl.key == 'large_printer' && (largePrinterData == "false" || largePrinterData == false) ? (
                          <ThemeProvider theme={Mtheme}>
                            <Flex>
                            <PosLable
                              name={(null != largePrinterName && '' != largePrinterName) ? fl.setting_display_name+ ' (You have currently selected \''+ largePrinterName + '\' printer. You can select another printer option from below dropdown)':fl.setting_display_name}
                              label={true}
                              fontWeight={"350"}
                            ></PosLable>
                            </Flex>
                            <Autocomplete
                              size="small"
                              filterOptions={(x) => x}
                              id="autoselect"
                              disabled={(largePrinterData == "true" || largePrinterData == true) ? true : false}
                              sx={{ width: "100%", marginTop:"1rem" }}
                              open={open}
                              onOpen={() => {
                                setOpen(true);
                              }}
                              onClose={() => {
                                setOpen(false);
                              }}
                              value={autoSelectedPrinter}
                              onChange={(event, newValue) => {
                                autoCompleteSelectedPrinter(newValue);
                              }}
                              getOptionLabel={(option) => option?.name + (option?.state ? ' ('+option?.state+')' : "(N.A.)") || ""}
                              options={options}
                              loading={loading1}
                              renderInput={(params) => <TextField {...params} label="Select a printer" />}
                            />
                            {fl.key=='large_printer' && largePrinterData &&
                            (<Text
                              mt={"0.30rem"}
                              color={Colors.taxCreateExemptEbt}
                              style={{
                                flexShrink: "0",
                                fontSize: "12px",
                                fontStyle: "normal",
                                fontWeight: "300",
                                lineHeight: "normal",
                                letterSpacing: "-0.18px",
                              }}
                            >
                              To use this functionality make sure 'Print on Large Printer' is set to 'Yes'
                            </Text>)}
                          </ThemeProvider>
                        ): fl.key != 'large_printer' && fl.key != 'second_receipt_print_type' && fl.key != 'print_second_receipt' ? (
                          <Select
                          id={fl.key}
                          color="#010048"
                          value={fl.value}
                          onChange={(e) => {
                            handleSettingChange(i, fl.key, e.target.value);
                          }}
                          onClick={() => {
                            handleSettingBlur(fl.key, fl);
                          }}
                          disabled={fl.key == 'payment_pax_autobatch' && (undefined != fl.value && null != fl.value && fl.value.toLowerCase()=='yes')}
                        >
                          <option hidden disabled value="">
                            Select an Option
                          </option>
                          {fl.options.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Select>
                        ) : (fl.key == 'print_second_receipt') ? (
                          <PosDropDown
                            options={fl.options}
                            resetFilterOnHide={true}
                            value={secondPrintTypes}
                            onChange={handleOptionSelect}
                            multiSelect={true}
                            optionLabel="label"
                            placeholder="Select Second Reciept Payment Types"
                            width={"100%"}
                            height={"2.5rem"}
                            className="w-full md:w-20rem"
                            maxSelectedLabels={4}
                          />
                        ):(fl.key == 'second_receipt_print_type' && (secondReceiptData == "false" || secondReceiptData == false) ? (
                          <Select
                          id={fl.key}
                          color="#010048"
                          value={fl.value}
                          onChange={(e) => {
                            handleSettingChange(i, fl.key, e.target.value);
                          }}
                          onClick={() => {
                            handleSettingBlur(fl.key, fl);
                          }}
                        >
                          <option hidden disabled value="">
                            Select an Option
                          </option>
                          {fl.options.map((data, i) => (
                            <option key={i} value={data.value}>
                              {data.label}
                            </option>
                          ))}
                        </Select>
                        ): null)
                      ) : (
                        <Select value={""} id={fl.key}>
                          <option hidden disabled value="">
                            {"N.A."}
                          </option>
                        </Select>
                      )}
                    </Box>
                    <Text color={Colors.errorColor}>
                      {validationErrors[fl.key]}
                    </Text>
                  </Stack>
                )}
              </React.Fragment>
            ))}
          </Box>
        ))}
      </Box>
    </Flex>
  );
};

export default WithRouter(PosStationDuplicate);
