import {
  Box,
  Flex,
  Skeleton,
  Spacer,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLoaderData, useLocation, useSubmit } from "react-router-dom";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosFormButton,
  PosListHeader,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
  getCampDbCoupRedeemDataApi,
  getCampDbEmailSentDataApi,
  getCampDbSmsSentDataApi,
  getCampDbTotalRevenueDataApi,
  getCampDbUnsubscribedEmailSmsDataApi,
} from "../campaign/CampaignService";

const CampaignDashboard = (props) => {
  const isMobile = useBreakpointValue({ base: true, sm: false });

  const effectRun = useRef(true);

  const submit = useSubmit();
  const { error } = PosErrorHook();
  const loaderResponse = useLoaderData();
  const myContext = useContext(AppContext);

  // Set All Chart Loading.
  const [lineChartLoading, setLineChartLoading] = useState(false);

  const [campaignId, setCampaignId] = useState("");
  const [couponRedeemed, setCouponRedeemed] = useState("");
  const [totalRevenue, setTotalRevenue] = useState("");
  const [totalSmsSent, setTotalSmsSent] = useState("");
  const [totalEmailSent, setTotalEmailSent] = useState("");
  const [emailUnsubscribed, setEmailUnsubscribed] = useState("");
  const [smsUnsubscribed, setSmsUnsubscribed] = useState("");
  const [couponUsageDetails, setCouponUsageDetails] = useState([]);
  const [totalUsersCountSMS, setTotalUsersCountSMS] = useState("");
  const [totalUsersCountEmail, setTotalUsersCountEmail] = useState("");
  const stateData = useLocation();

  // On Loading all locations, last created order location and tenant order available check.
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          setCampaignId(loaderResponse?.data?.data?.campaign?.id);
          getCouponRedeemData(loaderResponse?.data?.data?.campaign?.id);
          getCampDbTotalRevenueData(loaderResponse?.data?.data?.campaign?.id);
          getCampDbUnsubscribedEmailSmsData(
            loaderResponse?.data?.data?.campaign?.id
          );
          getCampDbEmailSentData(loaderResponse?.data?.data?.campaign?.id);
          getCampDbSmsSentData(loaderResponse?.data?.data?.campaign?.id);
          myContext.handleLoading(false);
        } else {
          let actionData = loaderResponse;
          error({ actionData });
        }
      }
      // return () => {
      effectRun.current = false;
      // };
    }
  });

  const getCouponRedeemData = (id) => {
    setLineChartLoading(true);
    try {
      getCampDbCoupRedeemDataApi(id)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setCouponRedeemed(response.data?.data?.coupon_time_redeemed);
            setCouponUsageDetails(response.data?.data?.coupon_usage_details);
            myContext.handleLoading(false);
            setLineChartLoading(false);
          } else {
            setLineChartLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLineChartLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLineChartLoading(false);
      myContext.handleLoading(false);
    }
  };

  const getCampDbTotalRevenueData = (id) => {
    setLineChartLoading(true);
    try {
      getCampDbTotalRevenueDataApi(id)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setTotalRevenue(response.data?.data?.total_revenue);
            myContext.handleLoading(false);
            setLineChartLoading(false);
          } else {
            setLineChartLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLineChartLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLineChartLoading(false);
      myContext.handleLoading(false);
    }
  };

  const getCampDbUnsubscribedEmailSmsData = (id) => {
    setLineChartLoading(true);
    try {
      getCampDbUnsubscribedEmailSmsDataApi(id)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setSmsUnsubscribed(response.data?.data?.sms_unsubscribed);
            setEmailUnsubscribed(response.data?.data?.email_unsubscribed);
            myContext.handleLoading(false);
            setLineChartLoading(false);
          } else {
            setLineChartLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLineChartLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLineChartLoading(false);
      myContext.handleLoading(false);
    }
  };

  const getCampDbEmailSentData = (id) => {
    setLineChartLoading(true);
    try {
      getCampDbEmailSentDataApi(id)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setTotalEmailSent(response.data?.data?.total_email_sent);
            setTotalUsersCountEmail(response.data?.data?.total_users_count_email);
            myContext.handleLoading(false);
            setLineChartLoading(false);
          } else {
            setLineChartLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLineChartLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLineChartLoading(false);
      myContext.handleLoading(false);
    }
  };

  const getCampDbSmsSentData = (id) => {
    setLineChartLoading(true);
    try {
      getCampDbSmsSentDataApi(id)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setTotalSmsSent(response.data?.data?.total_sms_sent);
            setTotalUsersCountSMS(response.data?.data?.total_users_count_sms);
            myContext.handleLoading(false);
            setLineChartLoading(false);
          } else {
            setLineChartLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLineChartLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLineChartLoading(false);
      myContext.handleLoading(false);
    }
  };

  const customize = {
    legend: { hidden: true },
  };

  const formatNumber = (number) => {
    return number.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
      >
        <PosListHeader header={"View a Campaign Dashboard"} discription={""} />
        <Spacer />
        <Stack direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              props.navigate(Constants.MARKETING_CAMPAIGN_PATH, {
                state: stateData.state,
              });
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
        </Stack>
      </Flex>
      <Flex
        gap={"0.63rem"}
        flexDirection={!isMobile ? "row" : "column"}
        mt={"2rem"}
        // ml={!isMobile ? "3.06rem" : "1rem"}
        // mr={!isMobile ? "3.06rem" : "1rem"}
        // mb={!isMobile ? "3.06rem" : "1rem"}
      >
        {stateData.state.type == "Coupon" ? (
          <>
            <Box
              flex={1}
              flexShrink={"0"}
              bg={Colors.posPageDataBackground}
              mb={2.5}
              boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
            >
              <VStack
                align={"stretch"}
                pl={{ base: "2rem", md: "2rem" }}
                pt={{ base: "2.30rem", md: "2.30rem" }}
                pr={{ base: "2rem", md: "2rem" }}
                pb={{ base: "2.30rem", md: "2.30rem" }}
                gap={"0rem"}
              >
                <Box>
                  <Text
                    color={Colors.taxInfo}
                    fontSize={"1.125rem"}
                    fontWeight={"300"}
                  >
                    Total Coupons Redeemed
                  </Text>
                </Box>
                {lineChartLoading ? (
                  <Box color={"#00126833"} paddingTop={2}>
                    <Stack>
                      <Skeleton height="5px" width="150" />
                      <Skeleton height="5px" width="150" />
                      <Skeleton height="5px" width="150" />
                      <Skeleton height="5px" width="150" />
                    </Stack>
                  </Box>
                ) : (
                  <>
                    <Text
                      paddingTop={"0.31rem"}
                      color={Colors.posTextInfo}
                      fontSize={"1.5rem"}
                      fontWeight={"600"}
                    >
                      {couponRedeemed}
                    </Text>
                  </>
                )}
              </VStack>
            </Box>
            <Box
              flex={1}
              flexShrink={"0"}
              bg={Colors.posPageDataBackground}
              mb={2.5}
              boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
            >
              <VStack
                align={"stretch"}
                pl={{ base: "2rem", md: "2rem" }}
                pt={{ base: "2.30rem", md: "2.30rem" }}
                pr={{ base: "2rem", md: "2rem" }}
                pb={{ base: "2.30rem", md: "2.30rem" }}
                gap={"0rem"}
              >
                <Box>
                  <Text
                    color={Colors.taxInfo}
                    fontSize={"1.125rem"}
                    fontWeight={"300"}
                  >
                    Total Revenue Generated
                  </Text>
                </Box>
                {lineChartLoading || totalRevenue === "" ? (
                  <Box color={"#00126833"} paddingTop={2}>
                    <Stack>
                      <Skeleton height="5px" width="150" />
                      <Skeleton height="5px" width="150" />
                      <Skeleton height="5px" width="150" />
                      <Skeleton height="5px" width="150" />
                    </Stack>
                  </Box>
                ) : (
                  <>
                    <Text
                      paddingTop={"0.31rem"}
                      color={Colors.posTextInfo}
                      fontSize={"1.5rem"}
                      fontWeight={"600"}
                    >
                      ${totalRevenue == 0 ? "0.00" : formatNumber(totalRevenue)}
                    </Text>
                  </>
                )}
              </VStack>
            </Box>
            <Box
              flex={1}
              flexShrink={"0"}
              bg={Colors.posPageDataBackground}
              mb={2.5}
              boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
            >
              <VStack
                align={"stretch"}
                pl={{ base: "2rem", md: "2rem" }}
                pt={{ base: "2.30rem", md: "2.30rem" }}
                pr={{ base: "2rem", md: "2rem" }}
                pb={{ base: "2.30rem", md: "2.30rem" }}
                gap={"0rem"}
              >
                <Box>
                  <Text
                    color={Colors.taxInfo}
                    fontSize={"1.125rem"}
                    fontWeight={"300"}
                  >
                    Total SMS Sent
                  </Text>
                </Box>
                {lineChartLoading || totalEmailSent === "" ? (
                  <Box color={"#00126833"} paddingTop={2}>
                    <Stack>
                      <Skeleton height="5px" width="150" />
                      <Skeleton height="5px" width="150" />
                      <Skeleton height="5px" width="150" />
                      <Skeleton height="5px" width="150" />
                    </Stack>
                  </Box>
                ) : (
                  <>
                    <Text
                      paddingTop={"0.31rem"}
                      color={Colors.posTextInfo}
                      fontSize={"1.5rem"}
                      fontWeight={"600"}
                    >
                      {totalSmsSent} out of {totalUsersCountSMS}
                    </Text>
                  </>
                )}
              </VStack>
            </Box>
          </>
        ) : (
          <>
            <Box
              flex={1}
              flexShrink={"0"}
              bg={Colors.posPageDataBackground}
              mb={2.5}
              boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
            >
              <VStack
                align={"stretch"}
                pl={{ base: "2rem", md: "2rem" }}
                pt={{ base: "2.30rem", md: "2.30rem" }}
                pr={{ base: "2rem", md: "2rem" }}
                pb={{ base: "2.30rem", md: "2.30rem" }}
                gap={"0rem"}
              >
                <Box>
                  <Text
                    color={Colors.taxInfo}
                    fontSize={"1.125rem"}
                    fontWeight={"300"}
                  >
                    Total SMS Sent
                  </Text>
                </Box>
                {lineChartLoading || totalSmsSent === "" ? (
                  <Box color={"#00126833"} paddingTop={2}>
                    <Stack>
                      <Skeleton height="5px" width="150" />
                      <Skeleton height="5px" width="150" />
                      <Skeleton height="5px" width="150" />
                      <Skeleton height="5px" width="150" />
                    </Stack>
                  </Box>
                ) : (
                  <>
                    <Text
                      paddingTop={"0.31rem"}
                      color={Colors.posTextInfo}
                      fontSize={"1.5rem"}
                      fontWeight={"600"}
                    >
                      {totalSmsSent} out of {totalUsersCountSMS}
                    </Text>
                  </>
                )}
              </VStack>
            </Box>
            <Box
              flex={1}
              flexShrink={"0"}
              bg={Colors.posPageDataBackground}
              mb={2.5}
              boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
            >
              <VStack
                align={"stretch"}
                pl={{ base: "2rem", md: "2rem" }}
                pt={{ base: "2.30rem", md: "2.30rem" }}
                pr={{ base: "2rem", md: "2rem" }}
                pb={{ base: "2.30rem", md: "2.30rem" }}
                gap={"0rem"}
              >
                <Box>
                  <Text
                    color={Colors.taxInfo}
                    fontSize={"1.125rem"}
                    fontWeight={"300"}
                  >
                    Total Email Sent
                  </Text>
                </Box>
                {lineChartLoading || totalEmailSent === "" ? (
                  <Box color={"#00126833"} paddingTop={2}>
                    <Stack>
                      <Skeleton height="5px" width="150" />
                      <Skeleton height="5px" width="150" />
                      <Skeleton height="5px" width="150" />
                      <Skeleton height="5px" width="150" />
                    </Stack>
                  </Box>
                ) : (
                  <>
                    <Text
                      paddingTop={"0.31rem"}
                      color={Colors.posTextInfo}
                      fontSize={"1.5rem"}
                      fontWeight={"600"}
                    >
                      {totalEmailSent} out of {totalUsersCountEmail}
                    </Text>
                  </>
                )}
              </VStack>
            </Box>
          </>
        )}
      </Flex>
      <Flex gap={"0.63rem"} flexDirection={!isMobile ? "row" : "column"}>
        {stateData.state.type == "Coupon" && (
          <Box
            flex={1}
            flexShrink={"0"}
            bg={Colors.posPageDataBackground}
            mb={2.5}
            boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
          >
            <VStack
              align={"stretch"}
              pl={{ base: "2rem", md: "2rem" }}
              pt={{ base: "2.30rem", md: "2.30rem" }}
              pr={{ base: "2rem", md: "2rem" }}
              pb={{ base: "2.30rem", md: "2.30rem" }}
              gap={"0rem"}
            >
              <Box>
                <Text
                  color={Colors.taxInfo}
                  fontSize={"1.125rem"}
                  fontWeight={"300"}
                >
                  Total Email Sent
                </Text>
              </Box>
              {lineChartLoading || totalEmailSent === "" ? (
                <Box color={"#00126833"} paddingTop={2}>
                  <Stack>
                    <Skeleton height="5px" width="150" />
                    <Skeleton height="5px" width="150" />
                    <Skeleton height="5px" width="150" />
                    <Skeleton height="5px" width="150" />
                  </Stack>
                </Box>
              ) : (
                <>
                  <Text
                    paddingTop={"0.31rem"}
                    color={Colors.posTextInfo}
                    fontSize={"1.5rem"}
                    fontWeight={"600"}
                  >
                    {totalEmailSent} out of {totalUsersCountEmail}
                  </Text>
                </>
              )}
            </VStack>
          </Box>
        )}
        <Box
          flex={1}
          flexShrink={"0"}
          bg={Colors.posPageDataBackground}
          mb={2.5}
          boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
        >
          <VStack
            align={"stretch"}
            pl={{ base: "2rem", md: "2rem" }}
            pt={{ base: "2.30rem", md: "2.30rem" }}
            pr={{ base: "2rem", md: "2rem" }}
            pb={{ base: "2.30rem", md: "2.30rem" }}
            gap={"0rem"}
          >
            <Box>
              <Text
                color={Colors.taxInfo}
                fontSize={"1.125rem"}
                fontWeight={"300"}
              >
                Total Unsubscribed Email
              </Text>
            </Box>
            {lineChartLoading || emailUnsubscribed === "" ? (
              <Box color={"#00126833"} paddingTop={2}>
                <Stack>
                  <Skeleton height="5px" width="150" />
                  <Skeleton height="5px" width="150" />
                  <Skeleton height="5px" width="150" />
                  <Skeleton height="5px" width="150" />
                </Stack>
              </Box>
            ) : (
              <>
                <Text
                  paddingTop={"0.31rem"}
                  color={Colors.posTextInfo}
                  fontSize={"1.5rem"}
                  fontWeight={"600"}
                >
                  {emailUnsubscribed}
                </Text>
              </>
            )}
          </VStack>
        </Box>
        <Box
          flex={1}
          flexShrink={"0"}
          bg={Colors.posPageDataBackground}
          mb={2.5}
          boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
        >
          <VStack
            align={"stretch"}
            pl={{ base: "2rem", md: "2rem" }}
            pt={{ base: "2.30rem", md: "2.30rem" }}
            pr={{ base: "2rem", md: "2rem" }}
            pb={{ base: "2.30rem", md: "2.30rem" }}
            gap={"0rem"}
          >
            <Box>
              <Text
                color={Colors.taxInfo}
                fontSize={"1.125rem"}
                fontWeight={"300"}
              >
                Total Unsubscribed SMS
              </Text>
            </Box>
            {lineChartLoading || smsUnsubscribed === "" ? (
              <Box color={"#00126833"} paddingTop={2}>
                <Stack>
                  <Skeleton height="5px" width="150" />
                  <Skeleton height="5px" width="150" />
                  <Skeleton height="5px" width="150" />
                  <Skeleton height="5px" width="150" />
                </Stack>
              </Box>
            ) : (
              <>
                <Text
                  paddingTop={"0.31rem"}
                  color={Colors.posTextInfo}
                  fontSize={"1.5rem"}
                  fontWeight={"600"}
                >
                  {smsUnsubscribed}
                </Text>
              </>
            )}
          </VStack>
        </Box>
      </Flex>
      {couponUsageDetails.length > 0 && (
        <Flex gap={"0.63rem"} flexDirection={!isMobile ? "row" : "column"}>
          <Box
            flex={1}
            flexShrink={"0"}
            bg={Colors.posPageDataBackground}
            mb={2.5}
            boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
          >
            <VStack
              align={"stretch"}
              pl={{ base: "2.63rem", md: "2.63rem" }}
              pt={{ base: "2.5rem", md: "2.5rem" }}
              pr={{ base: "2.63rem", md: "2.63rem" }}
              pb={{ base: "2.84rem", md: "2.84rem" }}
              gap={"0rem"}
            >
              <Box>
                <Flex
                  direction={{ base: "column", md: "row" }}
                  alignItems={{ base: "flex-start", md: "center" }}
                  justify="space-between"
                >
                  <Box>
                    <Text
                      fontSize={"1.125rem"}
                      color={Colors.posTextInfo}
                      fontWeight={"500"}
                    >
                      Coupon Usage Details
                    </Text>
                    <Text
                      fontSize={"0.9375rem"}
                      color={Colors.posTextInfo}
                      fontWeight={"300"}
                    >
                      Review and track all Coupons
                    </Text>
                  </Box>
                </Flex>
                {lineChartLoading ? (
                  <Box color={"#00126833"} margin={30}>
                    <Stack>
                      <Skeleton height="10px" width="150" />
                      <Skeleton height="10px" width="200" />
                      <Skeleton height="10px" width="120" />
                      <Skeleton height="10px" width="150" />
                      <Skeleton height="10px" width="180" />
                      <Skeleton height="10px" width="200" />
                      <Skeleton height="10px" width="150" />
                      <Skeleton height="10px" width="150" />
                      <Skeleton height="10px" width="200" />
                      <Skeleton height="10px" width="280" />
                      <Skeleton height="10px" width="200" />
                      <Skeleton height="10px" width="150" />
                      <Skeleton height="10px" width="120" />
                      <Skeleton height="10px" width="180" />
                      <Skeleton height="10px" width="200" />
                      <Skeleton height="10px" width="150" />
                      <Skeleton height="10px" width="150" />
                    </Stack>
                  </Box>
                ) : (
                  <>
                    {couponUsageDetails.length > 0 && (
                      <DataTable
                        style={{ marginTop: "2rem", width: "100%" }}
                        value={couponUsageDetails}
                      >
                        <Column
                          field="id"
                          header="Id"
                          headerStyle={{
                            backgroundColor: "#5881fe1a",
                            fontWeight: "500",
                            color: "#38128A",
                            fontSize: "0.93rem",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "-0.01rem",
                          }}
                          style={{
                            color: "#010048",
                            fontSize: "0.93rem",
                            fontStyle: "normal",
                            fontWeight: "300",
                            lineHeight: "normal",
                            letterSpacing: "-0.01rem",
                            width: "15%",
                            textTransform: "capitalize",
                          }}
                        />
                        <Column
                          field="name"
                          header="Name"
                          headerStyle={{
                            backgroundColor: "#5881fe1a",
                            fontWeight: "500",
                            color: "#38128A",
                            fontSize: "0.93rem",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "-0.01rem",
                          }}
                          style={{
                            color: "#010048",
                            fontSize: "0.93rem",
                            fontStyle: "normal",
                            fontWeight: "300",
                            lineHeight: "normal",
                            letterSpacing: "-0.01rem",
                            width: "15%",
                            textTransform: "capitalize",
                          }}
                        />
                        <Column
                          field="coupon_type"
                          header="Coupon Type"
                          headerStyle={{
                            backgroundColor: "#5881fe1a",
                            fontWeight: "500",
                            color: "#38128A",
                            fontSize: "0.93rem",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "-0.01rem",
                          }}
                          // bodyStyle={{
                          //   padding: "1rem 1.9rem",
                          // }}
                          style={{
                            color: "#010048",
                            fontSize: "0.93rem",
                            fontStyle: "normal",
                            fontWeight: "300",
                            lineHeight: "normal",
                            letterSpacing: "-0.01rem",
                            width: "15%",
                            textTransform: "capitalize",
                          }}
                          body={(rowData) =>
                            rowData.coupon_type == null ||
                            "" == rowData.coupon_type ||
                            false == rowData.coupon_type
                              ? "N.A."
                              : rowData.coupon_type == "ord"
                              ? "Order"
                              : "Product"
                          }
                        />
                        <Column
                          field="discount_type"
                          header="Discount Type"
                          headerStyle={{
                            backgroundColor: "#5881fe1a",
                            fontWeight: "500",
                            color: "#38128A",
                            fontSize: "0.93rem",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "-0.01rem",
                          }}
                          style={{
                            color: "#010048",
                            fontSize: "0.93rem",
                            fontStyle: "normal",
                            fontWeight: "300",
                            lineHeight: "normal",
                            letterSpacing: "-0.01rem",
                            width: "15%",
                            textTransform: "capitalize",
                          }}
                          body={(rowData) =>
                            rowData.discount_type == null ||
                            "" == rowData.discount_type ||
                            false == rowData.discount_type
                              ? "N.A."
                              : rowData.discount_type == "amou"
                              ? "Amount"
                              : "Percentage"
                          }
                        />
                        <Column
                          field="discount_value"
                          header="Discount Value"
                          headerStyle={{
                            backgroundColor: "#5881fe1a",
                            fontWeight: "500",
                            color: "#38128A",
                            fontSize: "0.93rem",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "-0.01rem",
                          }}
                          style={{
                            color: "#010048",
                            fontSize: "0.93rem",
                            fontStyle: "normal",
                            fontWeight: "300",
                            lineHeight: "normal",
                            letterSpacing: "-0.01rem",
                            width: "15%",
                            textTransform: "capitalize",
                          }}
                          body={(rowData) =>
                            rowData.discount_value == null ||
                            "" == rowData.discount_value ||
                            false == rowData.discount_value
                              ? "N.A."
                              : rowData.discount_value
                          }
                        />
                        <Column
                          field="coupon_time_used"
                          header="Time Used"
                          headerStyle={{
                            backgroundColor: "#5881fe1a",
                            fontWeight: "500",
                            color: "#38128A",
                            fontSize: "0.93rem",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "-0.01rem",
                          }}
                          // bodyStyle={{
                          //   padding: "1rem 1.9rem",
                          // }}
                          style={{
                            color: "#010048",
                            fontSize: "0.93rem",
                            fontStyle: "normal",
                            fontWeight: "300",
                            lineHeight: "normal",
                            letterSpacing: "-0.01rem",
                            width: "15%",
                            textTransform: "capitalize",
                          }}
                          body={(rowData) =>
                            rowData.coupon_time_used == null ||
                            "" == rowData.coupon_time_used ||
                            false == rowData.coupon_time_used
                              ? "0"
                              : rowData.coupon_time_used
                          }
                        />
                      </DataTable>
                    )}
                  </>
                )}
              </Box>
            </VStack>
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default WithRouter(CampaignDashboard);
