import {
  Box,
  ButtonGroup,
  Flex,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery
} from "@chakra-ui/react";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosListHeader,
  PosNoDataFound,
  PosProgress,
  PosTostMessage,
  SingleOrderView
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import {
  commonDateFormate,
  endOfTodaysDay,
  reportDateFormate,
  startOfTodaysDay,
  twofix
} from "../../../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";

const SalesByCashier = (props) => {
  // Hooks
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const [tableData, setTableData] = useState([]);
  const [locations, setLocations] = useState([]);
  const [employes, setEmployees] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [isExtraLarge] = useMediaQuery("(min-width: 1710px)");
  const [isLarge] = useMediaQuery("(max-width: 1435px)");
  const [isExtraSmall] = useMediaQuery("(max-width: 480px)");

  // States
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [orderId, setOrderId] = useState(0);
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const [showTable, setShowTable] = useState(true);
  const [applyClicked, setApplyClicked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: "asc",
    },
  ]);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
  });

  //Refs
  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const effectRun = useRef(true);
  const sortRef = useRef(sortModel);

  // Responsive Breakpoints
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 1.5 };

  // Variables
  let counter = 1;
  const renderedEmployeeNames = [];

  const columnNames = [
    { field: "sr_no", headerName: "#", sortable: false, width: 90 },
    { field: "id", 
      headerName: "Order ID	", 
      sortable: false, 
      width: 110 ,
      renderCell: (params) => {
        const rowData = params.row;
        const handleView = () => {
          myContext.handleLoading(true);
          handleDisplayOrderData(rowData.id);
        };
        return (
          <Text
            onClick={handleView}
            cursor="pointer"
            color={Colors.posNavbarLink}
            fontSize="1rem"
            letterSpacing="-1.5%"
            textDecoration="underline"
            fontWeight={600}
          >
            {rowData.id}
          </Text>
        );
      },
      },
    {
      field: "local_id",
      headerName: "Local Id/Order No.",
      sortable: false,
      ...actionColumnFlexDouble,
    },
    {
      field: "created_at",
      headerName: "Order Date",
      sortable: false,
      ...actionColumnFlexDouble,
    },
    {
      field: "sales",
      headerName: "Sales($)",
      sortable: false,
      ...actionColumn,
      // align: 'right',
      // renderCell: (params) => (
      //   <div style={{ textAlign: 'right', width: '100%' }}>
      //     {params.value}
      //   </div>
      // ),
    }
  ];

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          setLoading(true);
          if(undefined !== loaderResponse?.data?.data?.location &&
            null !== loaderResponse?.data?.data?.location && loaderResponse?.data?.data?.location?.length === 1){
              setSelectedLocation(loaderResponse?.data?.data?.location[0])
              formik.setFieldValue("selectedLocation", loaderResponse?.data?.data?.location[0].name);
            }
          setLocations(loaderResponse?.data?.data?.location);
          setEmployees(loaderResponse?.data?.data?.employee);
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    getSalesEmployeeAmount();
  }, [paginationModel, sortModel]);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data?.message ===
          "Sales by Cashier Report successfully generated."
        ) {
          const url = Constants.REACT_APP_API_URL + actionResponse.data.data;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(Constants.DOWNLOAD, "export_sales_by_cashier_report.xlsx");
          document.body.appendChild(link);
          window.stop();
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REPORT_MODULE,
            alertDescription: actionResponse.data?.message,
          });
          myContext.handleLoading(false);
        } else {
          setTableData(actionResponse.data.data.data);
          setTotalCount(actionResponse.data.data.data.length);
          setLoading(false);
          setButtonDisable(false);
        }
      } else {
        let actionData = actionResponse?.data;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
      }
    }
  }, [actionResponse]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        null !== calendarRef.current &&
        calendarRef.current &&
        fromDateFocus
      ) {
        calendarRef.current.hide();
        setFromDateFocus(false);
      }
      if (
        null !== toDateCalendarRef.current &&
        toDateCalendarRef.current &&
        toDateFocus
      ) {
        toDateCalendarRef.current.hide();
        setToDateFocus(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [fromDateFocus, toDateFocus]);

  const formik = useFormik({
    initialValues: {
      selectedLocation: "",
      selectedEmployee: [],
      fromDate: "",
      toDate: "",
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      selectedLocation: Yup.string().required(Constants.LOCATION_IS_REQUIRED),
      fromDate: Yup.string().required(Constants.FORM_DATE_IS_REQUIRED),
      toDate: Yup.string().required(Constants.TO_DATE_IS_REQUIRED),
    }),
    onSubmit: async (values) => {},
  });

  const handleDisplayOrderData = useCallback(
    (id) => {
      setOrderId(id);
      onDrawerOpen();
    },
    [orderId]
  );

  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();

  const onOrderDrawerClose = () => {
    onDrawerClose();
    setOrderId("");
  };

  const createTable = (employee) => {
    const employeeData = modifiedData.filter(
      (item) => item.name === employee.name
    );
    let counter = 1;
    const totalSales = employeeData.reduce(
      (total, row) => total + Number(row.sales),
      0
    );
    const totalRow = {
      sr_no: "Total($)",
      id: "-",
      local_id:"-",
      created_at: "-",
      sales: totalSales.toFixed(2),
    };
    return (
      <Box>
        <Box
          mb={{ base: 4, md: 4 }}
          borderRadius="0.63rem"
          bg={Colors.posPageDataBackground}
          p="2"
          boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
        >
          <PosListHeader
            header={`Employee Name : ${employeeData?.[0]?.name}`}
          />
          <Box
            mt={8}
            h={employeeData.length - 1 > 7 ? "31rem" : "auto"}
            overflowY="auto"
          >
            <PosDataGridTable
              columns={columnNames}
              rows={employeeData.map((data) => ({
                ...data,
                sr_no: counter++,
              }))}
              totalcount={employeeData.length}
              hideFooter={true}
              columnVisibilityModel={olColumnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setOlColumnVisibilityModel(newModel)
              }
              autoHeight={employeeData.length - 1 > 7 ? false : true}
              paginationModel={paginationModel}
              paginationMode="server"
              onPaginationModelChange={setPaginationModel}
              onSortModelChange={setSortModel}
              slots={{
                toolbar: GridToolbar,
              }}
              fileName={Constants.SALES_BY_CASHIER_REPORT_CSV}
            />
          </Box>
          <Box key="total">
            <PosDataGridTable
              columns={columnNames.map((column) => ({
                ...column,
                renderCell: (params) => (
                  <div
                    style={params.row === totalRow ? { fontWeight: 500 } : null}
                  >
                    {params.value}
                  </div>
                ),
              }))}
              rows={[totalRow]}
              columnHeaderHeight={0}
              totalcount={1}
              hideFooter={true}
              columnVisibilityModel={olColumnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setOlColumnVisibilityModel(newModel)
              }
              paginationModel={paginationModel}
              paginationMode="server"
              onPaginationModelChange={setPaginationModel}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  const handleExportReport = () => {
    if (
      formik.values.selectedLocation.length > 0 &&
      null != formik.values.selectedLocation &&
      "" != formik.values.selectedLocation
    ) {
      if (
        null != formik.values.fromDate &&
        "" != formik.values.fromDate){
        if(null != formik.values.toDate &&
        "" != formik.values.toDate
      ) {
    myContext.handleLoading(true);
      let data = {
        exportReport: true,
        type: "xls",
        location: JSON.stringify({
          label: selectedLocation.name,
          value: {
            id: selectedLocation.id,
          },
        }),
        zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        query: JSON.stringify({
          limit: paginationModel.pageSize,
          page: paginationModel.page + 1,
          order:
            sortModel.length > 0
              ? sortModel[0].field
              : sortRef.current[0].field,
          order_type:
            sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        }),
      };
      if (selectedEmployee) {
        data.employee = JSON.stringify(
          selectedEmployee.map((emp) => ({
            id: emp.id,
            name: emp.name,
          }))
        );
      }
      if (formik.values.fromDate && formik.values.toDate) {
        data.dateFrom = reportDateFormate(formik.values.fromDate);
        data.dateTo = reportDateFormate(formik.values.toDate);
      }
      submit(data, {
        method: Constants.POST,
        path: Constants.SALES_BY_CASHIER_ROUTE,
      });
    // }
  } else {
    clearTableData(Constants.TO_DATE_IS_REQUIRED);
  }
} else {
  clearTableData(Constants.FORM_DATE_IS_REQUIRED);
} } else {
  clearTableData(Constants.LOCATION_IS_REQUIRED);
}
  };

  const clearTableData = (message) => {
    setTableData([]);
    setTotalCount(0);
    setLoading(false);
    setButtonDisable(false);
    myContext.handleLoading(false);
    addToast({
      alertStatus: Constants.TOAST_TYPE_WARNING,
      alertTitle: Constants.REPORT_MODULE,
      alertDescription: message,
    });
  };

  const getSalesEmployeeAmount = () => {
    if (formik.values.selectedLocation.length > 0) {
      setButtonDisable(true);
      setLoading(true);
      setShowTable(false);
      setApplyClicked(true);
      let data = {
        getreport: true,
        location: JSON.stringify({
          label: selectedLocation.name,
          value: {
            id: selectedLocation.id,
          },
        }),
        zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        query: JSON.stringify({
          limit: paginationModel.pageSize,
          page: paginationModel.page + 1,
          order:
            sortModel.length > 0
              ? sortModel[0].field
              : sortRef.current[0].field,
          order_type:
            sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        }),
      };
      if (selectedEmployee) {
        data.employee = JSON.stringify(
          selectedEmployee.map((emp) => ({
            id: emp.id,
            name: emp.name,
          }))
        );
      }
      if (formik.values.fromDate && formik.values.toDate) {
        data.dateFrom = reportDateFormate(formik.values.fromDate);
        data.dateTo = reportDateFormate(formik.values.toDate);
      }
      submit(data, {
        method: Constants.POST,
        path: Constants.SALES_BY_CASHIER_ROUTE,
      });
    }
  };

  const handleLocationOptionSelect = (e) => {
    if (undefined !== e.value) {
      setSelectedLocation(e.value);
      formik.setFieldValue("selectedLocation", e.value.name);
    }
  };

  const handleEmployeeOptionSelect = (e) => {
    setSelectedEmployee(e.value);
    formik.setFieldValue("selectedEmployee", e.value.name);
  };

  const handleResetAllData = () => {
    setSortModel([
      {
        field: "id",
        sort: "desc",
      },
    ]);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setTableData([]);
    setShowTable(true);
    setTotalCount(0);
    setSelectedLocation([]);
    setSelectedEmployee([]);
    setFromDateView(startOfTodaysDay());
    setToDateView(endOfTodaysDay());
    formik.resetForm();
    setApplyClicked(false);
  };

  const hadleFromDateSelect = (e) => {
    if (
      null !== formik.values.toDate &&
      null != formik.values.toDate &&
      "" !== formik.values.toDate
    ) {
      if (moment(formik.values.toDate).isBefore(e.value)) {
        formik.setFieldValue("fromDate", e.value);
        formik.setFieldValue("toDate", e.value);
        setFromDateView(e.value);
        setToDateView(e.value);
      } else {
        formik.setFieldValue("fromDate", e.value);
        setFromDateView(e.value);
      }
    } else {
      formik.setFieldValue("fromDate", e.value);
      setFromDateView(e.value);
    }
  };

  const modifiedData = tableData
  .map((data) => {
    return Object.entries(data.order).map(([orderId, orderDetails]) => {
      return {
        name: data.name,
        id: orderDetails?.id,
        local_id: orderDetails?.local_id,
        created_at: commonDateFormate(orderDetails?.created_at),
        sales: orderDetails?.amount > 0 ? twofix(orderDetails?.amount) : "0.00",
      };
    });
  })
  .flat();

  const renderReportLayout = () => {
    return (
      <>
        <Stack
          className="card flex justify-content-center"
          flexDirection={isLarge ? "column" : "row"}
          spacing={4}
          width={isLarge ? "100% !important" : "auto"}
          justifyContent={"space-between"}
        >
          <Stack
            width={"100% !important"}
            flexDirection={isExtraSmall ? "column" : "row"}
            justifyContent={"space-between"}
            spacing={4}
          >
            <Box width={"100% !important"}>
              <PosDropDown
                options={locations}
                value={selectedLocation}
                onChange={handleLocationOptionSelect}
                onBlur={(e) => {
                  formik.setFieldTouched("selectedLocation");
                  formik.handleBlur(e);
                }}
                width={isExtraLarge ? "17rem" : "100%"}
                resetFilterOnHide={true}
                optionLabel="name"
                placeholder="Select Location*"
                height={"2.5rem"}
                className="w-full md:w-20rem"
              />
              <Text color={Colors.errorColor}>
                {formik.touched.selectedLocation &&
                formik.errors.selectedLocation ? (
                  <span>{formik.errors.selectedLocation}</span>
                ) : null}
              </Text>
            </Box>
            <Box width={"100% !important"}>
              <PosDropDown
                options={employes}
                value={selectedEmployee}
                onChange={handleEmployeeOptionSelect}
                onBlur={(e) => {
                  formik.setFieldTouched("selectedEmployee");
                  formik.handleBlur(e);
                }}
                width={isExtraLarge ? "17rem" : "100%"}
                resetFilterOnHide={true}
                multiSelect={true}
                optionLabel="name"
                placeholder="Select Emplyoees"
                height={"2.5rem"}
                className="w-full md:w-20rem"
                maxSelectedLabels={2}
              />
            </Box>
          </Stack>
          <Stack
            width={"100% !important"}
            flexDirection={isExtraSmall ? "column" : "row"}
            justifyContent={"space-between"}
            spacing={4}
          >
            <Box width={"100% !important"}>
              <PosDatePicker
                id={"fromDate"}
                ref={calendarRef}
                value={formik.values.fromDate}
                label={"From Date*"}
                readOnlyInput={true}
                onFocus={() => {
                  if (
                    null !== toDateCalendarRef.current &&
                    toDateCalendarRef.current
                  ) {
                    toDateCalendarRef.current.hide();
                  }
                  setToDateFocus(false);
                  setTimeout(() => {
                    setFromDateFocus(true);
                  }, 1000);
                  formik.setFieldTouched("fromDate", true);
                }}
                width={isExtraLarge ? "17rem" : "100%"}
                handleSelectData={hadleFromDateSelect}
                yearRange={"2000:2030"}
                maxDate={dateTimeValue}
                viewDate={fromDateView}
                showButtonBar={true}
                showTime={true}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.fromDate && formik.errors.fromDate ? (
                  <span>{formik.errors.fromDate}</span>
                ) : null}
              </Text>
            </Box>
            <Box width={"100% !important"}>
              <PosDatePicker
                id={"toDate"}
                ref={toDateCalendarRef}
                value={formik.values.toDate}
                label={"To Date*"}
                readOnlyInput={true}
                onFocus={() => {
                  if (null !== calendarRef.current && calendarRef.current) {
                    calendarRef.current.hide();
                  }
                  setFromDateFocus(false);
                  setTimeout(() => {
                    setToDateFocus(true);
                  }, 1000);
                  formik.setFieldTouched("toDate", true);
                }}
                width={isExtraLarge ? "17rem" : "100%"}
                handleSelectData={(e) => {
                  formik.setFieldValue("toDate", e.value);
                  setToDateView(e.value);
                }}
                yearRange={"2000:2030"}
                minDate={
                  null !== formik.values.fromDate &&
                  "" !== formik.values.fromDate
                    ? formik.values.fromDate
                    : dateTimeValue
                }
                maxDate={dateTimeValue}
                viewDate={toDateView}
                showButtonBar={true}
                showTime={true}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.toDate && formik.errors.toDate ? (
                  <span>{formik.errors.toDate}</span>
                ) : null}
              </Text>
            </Box>
          </Stack>
        </Stack>
      </>
    );
  };

  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <SingleOrderView
        visible={isDrawerOpen}
        onHide={onOrderDrawerClose}
        position="right"
        showCloseIcon={true}
        orderId={orderId}
        breadCrumNames={[]}
        handleClick={(i) => {
        }}
      />
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Report", "Sales By Cashier"]}
          breadCrumTitle={"Sales By Cashier"}
        />
        <Spacer />
        <ButtonGroup gap="2" direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              props.navigate(Constants.REPORT_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
          {totalCount > 0 && (
            <PosIconButton
              name={Constants.EXPORT_BUTTON}
              onClick={handleExportReport}
              exportIcon={true}
              width={"7.5rem"}
            />
          )}
        </ButtonGroup>
      </Flex>
      <Box
        borderRadius="0.63rem"
        bg="white"
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Flex
          direction={isLarge ? "column" : "row"}
          justifyContent={{ base: "center", sm: "space-between" }}
        >
          <Stack
            gap={4}
            flex={1}
            flexShrink={"0"}
            direction={"column"}
            m={4}
            alignItems={"flex-start"}
          >
            {renderReportLayout()}
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={isLarge ? "flex-start" : "flex-end"}
            alignItems={"center"}
            pl={isLarge ? 4 : 0}
          >
            <PosFormButton
              isDisabled={applyClicked ? false : true}
              onClick={handleResetAllData}
              buttonText={"Reset"}
              ghostButton={true}
            />
            <PosFormButton
              buttonsubmit={"Apply"}
              SubmitButton={true}
              isDisabled={
                formik.values.selectedLocation?.length === 0 ||
                formik.values.fromDate === "" ||
                formik.values.toDate === "" ||
                formik.errors.selectedLocation ||
                formik.errors.fromDate ||
                formik.errors.toDate ||
                buttonDisable
                  ? true
                  : false
              }
              onClick={getSalesEmployeeAmount}
            />
          </Stack>
        </Flex>
        <Box p={4}>
          <Flex direction="column" gap={4}>
            {showTable ? null : loading ? (
              <PosProgress />
            ) : totalCount > 0 ? (
              modifiedData.map((employee, index) => {
                if (!renderedEmployeeNames.includes(employee.name)) {
                  renderedEmployeeNames.push(employee.name);
                  return (
                    <React.Fragment
                      key={employee.id || `${employee.name}-${index}`}
                    >
                      {createTable(employee)}
                    </React.Fragment>
                  );
                }
                return null;
              })
            ) : (
              <PosNoDataFound
                title={Constants.SALES_BY_CASHIER_NO_DATA}
              />
            )}
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default WithRouter(SalesByCashier);
