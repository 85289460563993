import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import { GridToolbar } from "@mui/x-data-grid";
import Tippy from "@tippyjs/react";
import { Form, Formik, useFormik } from "formik";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useParams,
  useSubmit,
} from "react-router-dom";
import "tippy.js/dist/tippy.css";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import warnignIcon from "../../../assets/images/Ellipse21.svg";
import toolTip from "../../../assets/images/tool_tip_hover_icon.svg";
import {
  AppContext,
  PosBreadCrumb,
  PosCountryStateDropDown,
  PosDataGridTable,
  PosFormButton,
  PosInput,
  PosLable,
  PosNoDataFound,
  PosProgress,
  PosRadio,
  PosSearch,
  PosSwitchButton,
  PosTab,
  PosTable,
  PosTostMessage,
  SingleOrderView,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { commonDateFormate, twofix } from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import {
  editCustomerCredit,
  getAllCountriesState,
  getOrderData,
  getStateListForSelectedCountry,
} from "./RewardCardService";

const RewardCardsEdit = (props) => {
  const myContext = useContext(AppContext);
  const [buttonDisable, setButtonDisable] = useState(false);
  const stateData = useLocation(); // get param data
  const [paramData, setParamData] = useState(stateData.state);
  const { id } = useParams();
  const submit = useSubmit();
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const effectRun = useRef(true);
  const actionRun = useRef(false);
  const [custField, setCustField] = useState([]);

  const [validationErrors, setValidationErrors] = useState({});
  const handleInputChange = (index, newValue) => {
    const updatedCustField = [...custField];
    updatedCustField[index].value = newValue;
    setCustField(updatedCustField);
    setValidationErrors((prevErrors) => {
      return {
        ...prevErrors,
        [index]: "",
      };
    });
  };
  const [loading, setLoading] = useState(true);
  const [rewardsData, setRewardsData] = useState([]);
  const [isBoxVisible, setIsBoxVisible] = useState(true);
  const [isSecondTabVisible, setIsSecondTabVisible] = useState(false);
  const [orderId, setOrderId] = useState(""); // set order display id
  const [orderData, setOrderData] = useState([]);
  const [totalordercount, setOrderCount] = useState(0);
  const [showTable, setShowTable] = useState(true);
  const [searchQuery, setSearchQuery] = useState();
  const [curruntTab, setCurruntTab] = useState(0);
  const tabNames = isSecondTabVisible
    ? ["Basic Details", "Credit Account", "Point Details", "Order Details"]
    : ["Basic Details", "Credit Account"];
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    // total_cash_discount: false,
  });
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  const [customerData, setCustomerData] = useState("");
  const [countries, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [stateAvailable, setStatesAvailability] = useState(1);
  const [curruntBalence, setCurruntBalence] = useState(0);
  const [customerDataBalance, setCustomerDataBalance] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [stateError, setStateError] = useState("");
  const [previousSelectedCountry, setPreviousSelectedCountry] = useState("");
  const [countryError, setCountryError] = useState("");
  const [isScreenSize480] = useMediaQuery("(max-width: 3rem)");
  const CustomerCreditSchema = Yup.object().shape({
    amount: Yup.number()
      .required(Constants.CUSTOMER_PLEASE_ENTER_A_AMOUNT)
      .max(999999.99, Constants.CUSTOMER_AMOUNT_MAX_VALUE)
      .min(0.01, Constants.CUSTOMER_CREDIT_LIMIT_MIN_ERROR),
    external_reference: Yup.string().max(
      191,
      Constants.CUSTOMER_EXTERNAL_REFERENCE_LENGTH_GREATE_THEN_191
    ),
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [CustomerBalanceData, SetcustomerBalanceData] = useState([]);
  const [totalcount, setTotalCount] = useState(0);
  const [userBalance, setUserBalance] = useState(0);
  const [showCreditBalance, setShowCreditBalance] = useState(false);
  const [priceBooks, setPriceBooks] = useState([]);
  const [selectedPriceBooks, setSelectedPriceBooks] = useState([]);
  const [selectedPriceBooksProducts, setSelectedPriceBookProducts] = useState(
    []
  );

  const [selectedPriceBookDetails, setSelectedPriceBookDetails] = useState({});

  const {
    isOpen: isCoupProdViewModelOpen,
    onOpen: onCoupProdViewModelOpen,
    onClose: onCoupProdViewModelClose,
  } = useDisclosure();
  const [selectedPriceProdViewDetails, setSelectedPriceBookProductDetails] =
    useState([]);

  const priceBookDetailColumns = [
    {
      columnNames: "Price Book Id",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Price Book Name",
      sortName: "",
      width: "",
    },
    // {
    //   columnNames: "Product Details",
    //   sortName: "",
    //   width: "",
    // },
    {
      columnNames: "Delete",
      sortName: "",
      width: "",
    },
  ];

  const priceBookProductsDetailColumns = [
    {
      columnNames: "Product Id",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Name",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Barcode",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Sale Price($)",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Price Book Amount($)",
      sortName: "",
      width: "",
    },
  ];

  const formatDate = (date) => {
    const mm = (date.getMonth() + 1).toString().padStart(2, "0");
    const dd = date.getDate().toString().padStart(2, "0");
    const yyyy = date.getFullYear().toString();
    return `${mm}/${dd}/${yyyy}`;
  };
  const validateField = (value, field) => {
    if (
      field.field_required === "yes" &&
      field.field_display === "yes" &&
      !value
    ) {
      return `${field.field_name} is required`;
    }
    if (field.field_type === "text" && value && value.length > 191) {
      return `${field.field_name} cannot exceed 191 characters`;
    }
    if (field === "date" && (value === "" || value === null)) {
      return `${field} is required`;
    }
    if (field.field_type === "number" && value) {
      const numericValue = Number(value);
      if (isNaN(numericValue)) {
        return `${field.field_name} must be a number`;
      } else if (numericValue > 999999) {
        return `${field.field_name} cannot exceed 999999`;
      } else if (!Number.isInteger(numericValue)) {
        return ` Please enter a valid ${field.field_name}`;
      }
    }
    return "";
  };

  const handleBlur = (index, value, field) => {
    const error = validateField(value, field);
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [index]: error,
    }));
  };
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          if (
            undefined != loaderResponse.data?.data?.customerData &&
            null != loaderResponse.data?.data?.customerData &&
            "" != loaderResponse.data?.data?.customerData
          ) {
            setCustomerData(loaderResponse?.data?.data?.customerData);
            setShowCreditBalance(true);
            SetcustomerBalanceData(
              loaderResponse?.data?.data?.customerData?.available_credit
            );
            const arrayCountries = Object.keys(
              loaderResponse?.data?.data?.customerData?.countries
            ).map((key) => ({
              label: loaderResponse?.data?.data?.customerData?.countries[key],
              value: key,
            }));
            setCountry(arrayCountries);

            if (
              loaderResponse?.data?.data?.customerData?.country &&
              null != loaderResponse?.data?.data?.customerData?.country
            ) {
              setSelectedCountry(
                loaderResponse?.data?.data?.customerData?.country
              );
              handleSelectedCountry(
                loaderResponse?.data?.data?.customerData?.country
              );
            }

            if (
              loaderResponse?.data?.data?.customerData?.state &&
              null != loaderResponse?.data?.data?.customerData?.state &&
              "N.A" != loaderResponse?.data?.data?.customerData?.state
            ) {
              setStatesAvailability(0);
              setSelectedState(loaderResponse?.data?.data?.customerData?.state);
            }
          }
          if (
            undefined != loaderResponse.data?.data?.priceBooks &&
            null != loaderResponse.data?.data?.priceBooks &&
            "" != loaderResponse.data?.data?.priceBooks
          ) {
            setPriceBooks(loaderResponse.data?.data?.priceBooks);
          }
          if (
            undefined != loaderResponse.data?.data?.attachedPriceBook &&
            null != loaderResponse.data?.data?.attachedPriceBook &&
            "" != loaderResponse.data?.data?.attachedPriceBook
          ) {
            setSelectedPriceBooks([
              loaderResponse.data?.data?.attachedPriceBook,
            ]);
            setSelectedPriceBookProducts(
              loaderResponse.data?.data?.attachedPriceBook.products
            );
          }
          const formattedCustomFields =
            loaderResponse?.data?.data?.custom_fields.map((field) => ({
              ...field,
              value:
                field.field_type === "date" &&
                field.value !== undefined &&
                field.value !== "" &&
                field.value !== null
                  ? new Date(field.value)
                  : field.value || "",
            }));
          setCustField(formattedCustomFields);
          setRewardsData(loaderResponse.data?.data?.allRewardsData);
          const data = loaderResponse.data?.data?.allRewardsData;
          if (data && data.length > 0) {
            setRewardsData(data);
            setIsBoxVisible(true);
            setIsSecondTabVisible(true);
          } else {
            setIsBoxVisible(false);
            setIsSecondTabVisible(false);
          }
          myContext.handleLoading(false);
        } else {
          let actionData = loaderResponse;
          error({ actionData });
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (actionRun.current === true) {
      if (
        undefined != actionResponse &&
        null != actionResponse &&
        {} != actionResponse
      ) {
        setButtonDisable(false);
        if (
          undefined != actionResponse?.error &&
          null != actionResponse?.error &&
          actionResponse?.error
        ) {
          let actionData = actionResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          actionResponse.data[Constants.FLAGE] !== null &&
          actionResponse.data[Constants.FLAGE] === true
        ) {
          props.navigate(Constants.REWARD_CARDS_LIST, {
            state: paramData,
          });
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REWARD_CARD_MODULE,
            alertDescription: actionResponse.data.message,
          });
          myContext.handleLoading(false);
        } else {
          let actionData = actionResponse;
          error({ actionData });
        }
      }
      // return () => {
      // actionRun.current = false;
      // };
    }
  }, [loaderResponse, actionResponse]);
  const handleSelectedState = (value) => {
    setSelectedState(value);
    if (value == "N.A") {
      setStatesAvailability(1);
    } else {
      setStatesAvailability(0);
    }
  };

  const handleSelectedCountry = (value) => {
    try {
      setSelectedCountry(value);
      setStates([]);
      setSelectedState(null);
      setStateError("");
      formikCustomer.setFieldValue("selectedState", null);

      myContext.handleLoading(true);
      getStateListForSelectedCountry(value)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            const statesData = response.data.data.states;
            const arrayStates = Object.keys(statesData).map((key) => ({
              label: statesData[key],
              value: key,
            }));
            setStates(arrayStates);
            const customerState =
              loaderResponse?.data?.data?.customerData?.state;
            if (
              customerState &&
              customerState !== "N.A" &&
              customerState !== null &&
              statesData[customerState]
            ) {
              if (
                loaderResponse?.data?.data?.customerData?.country !==
                previousSelectedCountry
              ) {
                setSelectedState(customerState);
              } else if (
                loaderResponse?.data?.data?.customerData?.country ===
                previousSelectedCountry
              ) {
                setSelectedState("");
              }
              setPreviousSelectedCountry(
                loaderResponse?.data?.data?.customerData?.country
              );
              formikCustomer.setFieldValue("selectedState", customerState);
              setStatesAvailability(0);
            } else {
              // setSelectedState("N.A");
              // formik.setFieldValue("selectedState", "N.A");
              setStatesAvailability(1);
            }
          } else {
            setStates([]);
            setSelectedState("N.A");
            formikCustomer.setFieldValue("selectedState", "N.A");
            setStatesAvailability(1);
          }
          myContext.handleLoading(false);
        })
        .catch((err) => {
          setStates([]);
          setSelectedState("N.A");
          formikCustomer.setFieldValue("selectedState", "N.A");
          setStatesAvailability(1);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setStates([]);
      setSelectedState("N.A");
      formikCustomer.setFieldValue("selectedState", "N.A");
      setStatesAvailability(1);
      let actionData = error;
      error({ actionData });
      myContext.handleLoading(false);
    }
  };

  const formikCustomer = useFormik({
    initialValues: {
      name:
        undefined !== loaderResponse?.data?.data?.customerData?.name &&
        null != loaderResponse?.data?.data?.customerData?.name
          ? loaderResponse?.data?.data?.customerData?.name
          : "",
      // phone:
      //   undefined !== loaderResponse?.data?.data?.customerData?.phone &&
      //   null != loaderResponse?.data?.data?.customerData?.phone
      //     ? loaderResponse?.data?.data?.customerData?.phone
      //     : "",
      // email:
      //   undefined !== loaderResponse?.data?.data?.customerData?.email &&
      //   null != loaderResponse?.data?.data?.customerData?.email
      //     ? loaderResponse?.data?.data?.customerData?.email
      //     : "",
      address1:
        undefined !== loaderResponse?.data?.data?.customerData?.address1 &&
        null != loaderResponse?.data?.data?.customerData?.address1
          ? loaderResponse?.data?.data?.customerData?.address1
          : "",
      address2:
        undefined !== loaderResponse?.data?.data?.customerData?.address2 &&
        null != loaderResponse?.data?.data?.customerData?.address2
          ? loaderResponse?.data?.data?.customerData?.address2
          : "",
      city:
        undefined !== loaderResponse?.data?.data?.customerData?.city &&
        null != loaderResponse?.data?.data?.customerData?.city
          ? loaderResponse?.data?.data?.customerData?.city
          : "",
      notes:
        undefined !== loaderResponse?.data?.data?.customerData?.notes &&
        null != loaderResponse?.data?.data?.customerData?.notes
          ? loaderResponse?.data?.data?.customerData?.notes
          : "",
      credit_limit:
        undefined !== loaderResponse?.data?.data?.customerData?.credit_limit &&
        null != loaderResponse?.data?.data?.customerData?.credit_limit
          ? loaderResponse?.data?.data?.customerData?.credit_limit
          : "",
      tax_exempt:
        undefined !== loaderResponse?.data?.data?.customerData?.tax_exempt &&
        null != loaderResponse?.data?.data?.customerData?.tax_exempt
          ? loaderResponse?.data?.data?.customerData?.tax_exempt
          : false,
      exclude_from_reward_calculation:
        undefined !==
          loaderResponse?.data?.data?.customerData?.is_exclude_reward &&
        null != loaderResponse?.data?.data?.customerData?.is_exclude_reward
          ? loaderResponse?.data?.data?.customerData?.is_exclude_reward
          : false,
      country:
        undefined !== loaderResponse?.data?.data?.customerData?.country &&
        null != loaderResponse?.data?.data?.customerData?.country
          ? loaderResponse?.data?.data?.customerData?.country
          : "",
      state:
        undefined !== loaderResponse?.data?.data?.customerData?.state &&
        null != loaderResponse?.data?.data?.customerData?.state
          ? loaderResponse?.data?.data?.customerData?.state
          : "",
      zipcode:
        undefined !== loaderResponse?.data?.data?.customerData?.zipcode &&
        null != loaderResponse?.data?.data?.customerData?.zipcode
          ? loaderResponse?.data?.data?.customerData?.zipcode
          : "",
      selectedState:
        undefined !== loaderResponse?.data?.data?.customerData?.state &&
        null != loaderResponse?.data?.data?.customerData?.state
          ? loaderResponse?.data?.data?.customerData?.state
          : "",
      selectedCountry:
        undefined !== loaderResponse?.data?.data?.customerData?.country &&
        null != loaderResponse?.data?.data?.customerData?.country
          ? loaderResponse?.data?.data?.customerData?.country
          : "",
      amount: "",
      external_reference:""
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required(Constants.USER_BUSINESS_NAME_REQUIRED)
        .max(191, Constants.USER_BUSINESS_NAME_LENGTH_GREATER_THAN_191),
      address1: Yup.string()
        .trim()
        .required(Constants.CUSTOMER_ADDRESS_NAME_REQUIRED)
        .max(5000, Constants.CUSTOMER_ADDRESS_LENGTH_GREATE_THEN_5000),
      address2: Yup.string().max(
        5000,
        Constants.CUSTOMER_ADDRESS_LENGTH_GREATE_THEN_5000
      ),
      city: Yup.string()
        .trim()
        .required(Constants.CUSTOMER_PLEASE_ENTER_A_CITY)
        .max(191, Constants.CUSTOMER_CITY_LENGTH_GREATE_THEN_191),
      // phone: Yup.string()
      //   .required(Constants.CUSTOMER_PLEASE_ENTER_A_PHONE_NUMBER)
      //   .max(
      //     14,
      //     Constants.CUSTOMER_PHONE_NUMBER_MUST_BE_LESS_THAN_15_DIGITS_LONG
      //   )
      //   .min(
      //     3,
      //     Constants.CUSTOMER_PHONE_NUMBER_MUST_BE_GREATER_THAN_2_DIGITS_LONG
      //   )
      //   .matches(/^\d*$/, Constants.THIS_IS_NOT_A_VALID_PHONE_NUMBER),
      // email: Yup.string()
      //   .required(Constants.USER_EMAIL_REQUIRED)
      //   .email(Constants.USER_EMAIL_NOT_VALID)
      //   .matches(Constants.EMAIL_REGEX, Constants.VENDOR_EMAIL_NOT_VALID)
      //   .max(191, Constants.CUSTOMER_EMAIL_LENGTH_GREATE_THEN_191),
      notes: Yup.string().max(
        191,
        Constants.CUSTOMER_NOTES_LENGTH_GREATE_THEN_191
      ),
      credit_limit: Yup.number()
        .typeError(Constants.CUSTOMER_PLEASE_ENTER_A_VALID_CREDIT_LIMIT)
        .max(999999.99, Constants.CUSTOMER_CREDIT_LIMIT_MAX_ERROR)
        .min(0.01, Constants.CUSTOMER_CREDIT_LIMIT_MIN_ERROR),
      // country: Yup.string().required(
      //   Constants.CUSTOMER_PLEASE_SELECT_A_COUNTRY
      // ),
      // state: Yup.string().required(Constants.CUSTOMER_PLEASE_SELECT_A_STATE),
      selectedCountry: Yup.string().required(
        Constants.CUSTOMER_PLEASE_SELECT_A_COUNTRY
      ),
      selectedState: Yup.string().required(
        Constants.CUSTOMER_PLEASE_SELECT_A_STATE
      ),
      zipcode: Yup.number()
        .required(Constants.LOCATION_ZIP_REQUIRED)
        .integer(Constants.LOCATION_ZIP_VALID)
        .typeError(Constants.LOCATION_ZIP_VALID)
        .min(9, Constants.LOCATION_ZIP_MUST_BE_2_DIGITS)
        .max(9999999999, Constants.LOCATION_ZIP_MUST_BE_10_DIGITS),
      tax_exempt: Yup.string().required(
        Constants.CUSTOMER_PLEASE_SELECT_A_TAX_EXEMPT_OPTION
      ),
      exclude_from_reward_calculation: Yup.string().required(
        Constants.CUSTOMER_PLEASE_SELECT_A_REWARD_CALCULATION_OPTION
      ),
      amount: Yup.number()
        // .required(Constants.CUSTOMER_PLEASE_ENTER_A_AMOUNT)
        .typeError("Please enter a valid amount")
        .max(999999.99, Constants.CUSTOMER_AMOUNT_MAX_VALUE)
        .min(0.01, Constants.CUSTOMER_CREDIT_LIMIT_MIN_ERROR),
      external_reference: Yup.string().max(
        191,
        Constants.CUSTOMER_EXTERNAL_REFERENCE_LENGTH_GREATE_THEN_191
      ),
    }),
    onSubmit: async (values) => {
      myContext.handleLoading(true);
      const payload = {
        ...values,
        tax_exempt: values.tax_exempt ? 1 : 0,
        exclude_from_reward_calculation: values.exclude_from_reward_calculation
          ? 1
          : 0,
        country: selectedCountry,
        state: selectedState || "N.A",
        stateNotAvailable: stateAvailable,
      };
      setButtonDisable(true);
      actionRun.current = true;
      submit(payload, {
        method: Constants.POST,
        path: Constants.CUSTOMER_CREATE_API_ROUTE,
      });
    },
  });
  const initialValues = {
    id:
      undefined !== loaderResponse?.data?.data?.customerData?.id &&
      null != loaderResponse?.data?.data?.customerData?.id
        ? loaderResponse?.data?.data?.customerData?.id
        : "",
    amount: "",
    external_reference: "",
  };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const addCredit = async (values) => {
    myContext.handleLoading(true);
    let data = {
      id: loaderResponse?.data?.data?.customerData?.id,
      amount: values.amount,
      external_reference: values.external_reference,
      type: Constants.ADDCREDIT,
    };
    setLoading(true);
    try {
      editCustomerCredit(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            SetcustomerBalanceData(response.data.data.available_credit);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            SetcustomerBalanceData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          SetcustomerBalanceData([]);
          setTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      SetcustomerBalanceData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  const handleSubmit = async (values, resetForm) => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      try {
        await addCredit(values);
        onClose();
        resetForm();
        setLoading(true);
      } finally {
        setIsSubmitting(false);
      }
    }
  };
  const closepop = (resetForm) => {
    onClose();
    setCurruntBalence(null);
    resetForm();
    formik.setFieldValue("amount", "");
    formik.setFieldValue("external_reference", "");
  };

  const handleaddPage = () => {
    myContext.handleLoading(true);
    onOpen();
    formik.setFieldValue("amount", "");
    formik.setFieldValue("external_reference", "");
    myContext.handleLoading(false);
    formik.setFieldTouched("amount", false);
    formik.setFieldTouched("external_reference", false);
  };
  const getCountryStates = async () => {
    try {
      getAllCountriesState()
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            const arrayCountries = Object.keys(
              response.data.data.countries
            ).map((key) => ({
              label: response.data.data.countries[key],
              value: key,
            }));
            setCountry(arrayCountries);
            myContext.handleLoading(false);
          } else {
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      myContext.handleLoading(false);
    }
  };
  const onSelectPriceB = (event) => {
    // const distinctCheck = selectedPriceBooks.find(element => element['id'] == event.value.id);
    if (event.value) {
      let data = Object.assign({}, event.value);
      setSelectedPriceBooks([event.value]);
      setSelectedPriceBookDetails(data);
      setSelectedPriceBookProducts(data.products);
    } else {
      onDeletepriceBook(selectedPriceBooks[0].id);
    }
  };
  const priceBookProductViewColumns = [
    {
      columnNames: "Id",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Name",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Barcode",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Sale Price($)",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Price Book Sale Price($)",
      sortName: "",
      width: "",
    },
  ];

  const selectedpriceBookTemplate = (option, props) => {
    if (option) {
      return (
        <Box>
          <Stack direction={"row"} spacing="0.38rem">
            <Box>
              <Stack direction={"column"} spacing="0.44rem">
                <Box>
                  <Heading
                    color={Colors.posTextInfo}
                    fontSize={"0.94rem"}
                    fontStyle={"normal"}
                    fontWeight={"500"}
                    lineHeight={"normal"}
                    letterSpacing={"-0.01rem"}
                  >
                    {option.name}
                  </Heading>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Box>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const priceBookOptionTemplate = (option) => {
    return (
      <Box>
        <Stack direction={"row"} spacing="0.38rem">
          <Box>
            <Stack direction={"column"} spacing="0.44rem">
              <Box>
                <Heading
                  color={Colors.posTextInfo}
                  fontSize={"0.94rem"}
                  fontStyle={"normal"}
                  fontWeight={"500"}
                  lineHeight={"normal"}
                  letterSpacing={"-0.01rem"}
                >
                  {option.name}
                </Heading>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Box>
    );
  };

  const viewProductDetails = (productsDetails) => {
    var uniqueProducts = productsDetails.reduce((uniqueProdArray, element) => {
      if (!uniqueProdArray.find((v) => v["barcode"] === element["barcode"])) {
        const productPayload = {
          id: element.products.id,
          name: element.products.name,
          barcode: element.products.barcode,
          sale_price: element.products.sale_price,
          price_book_sale_price: element.price_book_sale_price,
        };
        uniqueProdArray.push(productPayload);
      }
      return uniqueProdArray;
    }, []);
    setSelectedPriceBookProductDetails(uniqueProducts);
    onCoupProdViewModelOpen();
  };
  const onDeletepriceBook = (priceBookId) => {
    const updatedSelectedpriceBooksArray = selectedPriceBooks.filter(
      (priceBook) => priceBook.id !== priceBookId
    );
    setSelectedPriceBooks(updatedSelectedpriceBooksArray);
    setSelectedPriceBookProducts([]);
  };
  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure(); // order display open and close
  const sortRef = useRef(sortModel);
  const handleSearchList = () => {
    if (curruntTab == 3) {
      fetchOrderData();
    }
  };
  const handleTabChange = (index) => {
    setCurruntTab(index);
    if (index == 2) {
      setSearchQuery("");
      setPaginationModel({
        page: 0,
        pageSize: 25,
      });
      effectRun.current = true;
      setLoading(true);
    } else if (index == 3) {
      setSearchQuery("");
      setPaginationModel({
        page: 0,
        pageSize: 25,
      });
      // fetchOrderData();
      effectRun.current = true;
      setLoading(true);
    }
  };
  useEffect(() => {
    if (curruntTab == 3) {
      fetchOrderData();
    }
  }, [paginationModel, sortModel]);
  const fetchOrderData = async () => {
    let data = {
      id: id,
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter2: searchQuery,
    };
    setLoading(true);
    setShowTable(false);
    try {
      getOrderData(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setOrderData(response.data.data.data);
            setOrderCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setOrderData([]);
            setOrderCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setOrderData([]);
          setOrderCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setOrderData([]);
      setOrderCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  const fetchOrderDataAfterSearchClear = async () => {
    let data = {
      id: id,
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter2: "",
    };
    setLoading(true);
    setShowTable(false);
    try {
      getOrderData(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setOrderData(response.data.data.data);
            setOrderCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setOrderData([]);
            setOrderCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setOrderData([]);
          setOrderCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setOrderData([]);
      setOrderCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  const columnNames = [
    {
      columnNames: "Sr. No",
      sortName: "",
      width: "10%",
    },
    {
      columnNames: "Reward Point Calculation Type",
      sortName: "",
      width: "16.66%",
    },
    {
      columnNames: "Reward Name	",
      sortName: "",
      width: "16.66%",
    },
    {
      columnNames: "Required Reward Points	",
      sortName: "",
      width: "16.66%",
    },
    {
      columnNames: "Current Reward Points	",
      sortName: "",
      width: "16.66%",
    },
    {
      columnNames: "Eligible",
      sortName: "",
      width: "16.66%",
    },
  ];

  let counterAssigned = 1;
  const rewardRawData = rewardsData.map((rewards) => ({
    sr_no: counterAssigned++,
    reward_point_calculation_type: rewards.reward_point_calculation_type,
    name: rewards.name,
    required_points: rewards.required_points,
    current_points: rewards.current_points,
    eligible: rewards.eligible,
  }));
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  const orderColumnNames = [
    { field: "id", headerName: "Order Id ", sortable: true, width: 120 },
    {
      field: "local_id",
      headerName: "Local Id/Order No",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "balance",
      headerName: "Total",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "paid",
      headerName: "Payment Status",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "created_at",
      headerName: "Order Date",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "view_order",
      headerName: "View Order",
      sortable: false,
      disableExport: true,
      resizable: false,
      filterable: false,
      width: 150,
      renderCell: (params) => {
        const rowData = params.row;
        const handleView = () => {
          // click on order view button
          myContext.handleLoading(true);
          handleDisplayOrderData(rowData.id);
          // handleViewPage(rowData.id);
        };
        return (
          <IconButton
            aria-label="view"
            onClick={handleView}
            sx={{ color: Colors.posNavbarLink }}
          >
            <VisibilityIcon />
          </IconButton>
        );
      },
    },
  ];
  const handleDisplayOrderData = useCallback(
    (id) => {
      setOrderId(id);
      onDrawerOpen();
    },
    [orderId]
  ); // order id set then open drawer
  const modifiedOrderData = orderData.map((data) => ({
    id: data.id,
    local_id: data.local_id,
    balance: data.balance,
    paid: data.paid === 1 ? "Paid" : "Unpaid",
    created_at: commonDateFormate(data.created_at),
  }));
  const handleClear = () => {
    effectRun.current = true;
    setSearchQuery("");
    fetchOrderDataAfterSearchClear();
  };
  const onOrderDrawerClose = () => {
    setOrderId("");
    onDrawerClose();
  };
  const formik = useFormik({
    initialValues: {
      first_name:
        undefined != loaderResponse?.data?.data?.first_name &&
        null != loaderResponse?.data?.data?.first_name
          ? loaderResponse?.data?.data?.first_name
          : "",
      last_name:
        undefined != loaderResponse?.data?.data?.last_name &&
        null != loaderResponse?.data?.data?.last_name
          ? loaderResponse?.data?.data?.last_name
          : "",
      email:
        undefined != loaderResponse?.data?.data?.email &&
        null != loaderResponse?.data?.data?.email
          ? loaderResponse?.data?.data?.email
          : "",
      phone: loaderResponse?.data?.data?.phone,
      notes:
        undefined != loaderResponse?.data?.data?.notes &&
        null != loaderResponse?.data?.data?.notes
          ? loaderResponse?.data?.data?.notes
          : "",
      emailSubType: loaderResponse?.data?.data?.is_unsubscribe,
      smsSubType: loaderResponse?.data?.data?.is_sms_unsubscribe,
      existEmail:
        undefined != loaderResponse?.data?.data?.email &&
        null != loaderResponse?.data?.data?.email
          ? loaderResponse?.data?.data?.email
          : "",
      reward_customer:
        undefined != loaderResponse?.data?.data?.customerData &&
        null != loaderResponse?.data?.data?.customerData &&
        "" != loaderResponse?.data?.data?.customerData &&
        !loaderResponse.data?.data?.customerData?.deleted_at
          ? true
          : false,
    },
    validationSchema: Yup.object({
      // first_name: Yup.string()
      //   .trim()
      //   .when("reward_customer", {
      //     is: true,
      //     then: (schema) =>
      //       schema
      //         .trim()
      //         .required(Constants.CUSTOMER_NAME_REQUIRED)
      //         .max(191, Constants.CUSTOMER_NAME_LENGTH_GREATER_THAN_191),
      //     otherwise: (schema) => schema.nullable(),
      //   }),
      first_name: Yup.string()
        .nullable()
        .max(191, Constants.REWARD_CARD_FIRST_NAME_LENGTH_GREATE_THEN_255),
      last_name: Yup.string()
        .nullable()
        .max(191, Constants.REWARD_CARD_LAST_NAME_LENGTH_GREATE_THEN_255),
      email: Yup.string().when("reward_customer", {
        is: true,
        then: (schema) =>
          schema
            .trim()
            .required(Constants.USER_EMAIL_REQUIRED)
            .email(Constants.USER_EMAIL_NOT_VALID)
            .matches(Constants.EMAIL_REGEX, Constants.USER_EMAIL_NOT_VALID)
            .max(254, Constants.USER_EMAIL_LESS_THEN_OR_EQUAL_255_CHARACTER),
        otherwise: (schema) => schema.nullable(),
      }),
      phone: Yup.number()
        .required(Constants.PHONE_IS_REQUIRED)
        .typeError(Constants.PHONE_NOT_VALID)
        .integer(Constants.PHONE_NOT_VALID)
        .min(100, Constants.PHONE_MIN_VALUE)
        .max(9999999999, Constants.PHONE_MAX_VALUE),
      notes: Yup.string()
        .max(191, Constants.REWARD_CARD_NOTES_LENGTH_GREATE_THEN_255)
        .nullable(),
      emailSubType: Yup.string().required(Constants.EMAIL_SUB_TYPE_REQUIRED),
      smsSubType: Yup.string().required(Constants.SMS_SUB_TYPE_REQUIRED),
    }),
    onSubmit: (values) => {
      setButtonDisable(true);
      let path = Constants.REWARD_CARDS_LIST + id;
      actionRun.current = true;
      myContext.handleLoading(true);
      custField.forEach((field) => {
        if (field.field_type === "date" && field.value) {
          field.value = formatDate(new Date(field.value));
        }
      });
      values.custom_fields = JSON.stringify(custField);
      if (undefined != values.reward_customer && values.reward_customer) {
        formikCustomer.values.tax_exempt = formikCustomer.values.tax_exempt
          ? 1
          : 0;
        formikCustomer.values.exclude_from_reward_calculation = formikCustomer
          .values.exclude_from_reward_calculation
          ? 1
          : 0;
        formikCustomer.values.country = selectedCountry;
        formikCustomer.values.state = selectedState || "N.A";
        formikCustomer.values.stateNotAvailable = stateAvailable;
      }
      const payload = {
        ...values,
        price_book:
          undefined != selectedPriceBooks && selectedPriceBooks.length > 0
            ? selectedPriceBooks[0].id
            : "",
        reward_customer: values.reward_customer ? 1 : 0,
        customer_data:
          undefined != values.reward_customer && values.reward_customer
            ? JSON.stringify(formikCustomer.values)
            : [],
      };
      submit(payload, {
        method: Constants.PUT,
        path: path,
      });
    },
  });
  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(
        formik.values.first_name.length <= 0 ||
        formik.errors.first_name ||
        formik.errors.last_name ||
        formik.errors.email ||
        formik.errors.phone ||
        formik.errors.notes ||
        buttonDisable
      )
    ) {
      formik.handleSubmit();
    }
  };
  // const finalSelectedpriceBooks = selectedPriceBooks.map((priceBook) => ({
  //   id: priceBook.id,
  //   name: priceBook.name,
  //   productDetailsIcon: (
  //     <IconButton
  //       sx={{ color: Colors.posNavbarLink }}
  //       onClick={() => viewProductDetails(priceBook.products)}
  //     >
  //       <VisibilityIcon />
  //     </IconButton>
  //   ),
  // }));

  const finalSelectedpriceBooksProducts = selectedPriceBooksProducts.map(
    (priceBook) => ({
      id: priceBook.products.id,
      name: priceBook.products.name,
      barcode: priceBook.products.barcode,
      sale_price: priceBook.products.sale_price,
      price_book_sale_price: priceBook.price_book_sale_price,
    })
  );
  return (
    <Flex bg={Colors.loginAuthBackground}>
      <Box flex="1" w={isMobile ? "100vw" : "calc(100vw - 263.2px)"}>
        <SingleOrderView
          visible={isDrawerOpen}
          onHide={onOrderDrawerClose}
          position="right"
          showCloseIcon={true}
          orderId={orderId}
          breadCrumNames={[]}
          handleClick={(i) => {
            // if (i == 0) {
            //   props.navigate(Constants.REPORT_PATH);
            // }
          }}
        />
        {/* <Box flex="1"> */}
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={"flex-start"}
          pt={{ base: "1.5rem", md: "3.06rem" }}
          pb={{ base: "1.5rem", md: "0" }}
          pl={{ base: "1rem", lg: "1.88rem" }}
          pr={{ base: "0.5rem", md: "1.88rem" }}
          gap={2}
        >
          <PosBreadCrumb
            handleClick={(i) => {
              props.navigate(Constants.REWARD_CARDS_LIST, {
                state: stateData.state,
              });
            }}
            breadCrumNames={["Reward Customers", "Edit Reward Customer"]}
            breadCrumTitle={"Edit Reward Customer"}
          />
          <Spacer />
          <Stack direction={"row"} alignSelf={"flex-end"}>
            <PosFormButton
              onClick={() => {
                props.navigate(Constants.REWARD_CARDS_LIST, {
                  state: stateData.state,
                });
              }}
              isDisabled={buttonDisable}
              buttonText={"Cancel"}
              CancelButton={true}
            />
            <PosFormButton
              isDisabled={
                (formik.values.reward_customer == true &&
                  (formikCustomer.values.name.length <= 0 ||
                    formikCustomer.errors.name ||
                    formikCustomer.errors.address1 ||
                    formikCustomer.errors.city ||
                    formikCustomer.errors.credit_limit ||
                    formikCustomer.errors.country ||
                    formikCustomer.errors.state ||
                    !formikCustomer.values.selectedState ||
                    stateError.length > 0 ||
                    !formikCustomer.isValid)) ||
                formik.errors.emailSubType ||
                formik.errors.first_name ||
                formik.errors.last_name ||
                formik.errors.notes ||
                formik.errors.email ||
                formik.errors.smsSubType ||
                formik.errors.phone ||
                Object.values(validationErrors).some((error) => !!error) ||
                buttonDisable
                  ? true
                  : false
              }
              buttonsubmit={"Submit"}
              SubmitButton={true}
              onClick={formik.handleSubmit}
            />
          </Stack>
        </Flex>
        {/* Tab Change View and Edit Merge */}
        <Box
          mt={{ base: 4, lg: 12 }}
          ml={{ base: 4, lg: 14 }}
          mr={{ base: 4, lg: 14 }}
          borderRadius="0.63rem"
          bg={Colors.posPageDataBackground}
          boxShadow={"0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow}
          p="8"
        >
          <Tabs onChange={(index) => handleTabChange(index)}>
            <PosTab tabNames={tabNames} />
            <TabPanels>
              <TabPanel>
                <Flex
                  spacing={4}
                  gap={{ base: 0, md: 14, lg: 20 }}
                  direction={{ base: "column", md: "row" }}
                >
                  <Box flex={1}>
                    <Stack spacing={1}>
                      <PosLable
                        fontWeight={500}
                        label={true}
                        requiredLabel={false}
                        name={"First Name"}
                      />
                      <PosInput
                        id="first_name"
                        placeholder={"Jane"}
                        handleInputChange={formik.handleChange}
                        inputValue={formik.values.first_name}
                        handleBlur={(e) => {
                          formik.setFieldValue(
                            "first_name",
                            formik.values.first_name.trim()
                          );
                          formik.handleBlur(e);
                        }}
                        onKeyDown={handleKeyDown}
                        posInputGroup={true}
                        inputType={"text"}
                        inputError={
                          formik.touched.first_name && formik.errors.first_name
                        }
                      >
                        <DriveFileRenameOutlineIcon
                          style={{ fill: "#5881FE" }}
                        />
                      </PosInput>
                      <Text color={Colors.errorColor}>
                        {formik.touched.first_name &&
                        formik.errors.first_name ? (
                          <span>{formik.errors.first_name}</span>
                        ) : null}
                      </Text>
                    </Stack>
                  </Box>
                  <Box flex={1}>
                    <Stack spacing={1}>
                      <PosLable
                        fontWeight={500}
                        label={true}
                        name={"Last Name"}
                      />
                      <PosInput
                        id="last_name"
                        placeholder={"Doe"}
                        handleInputChange={formik.handleChange}
                        inputValue={formik.values.last_name}
                        handleBlur={(e) => {
                          formik.setFieldValue(
                            "last_name",
                            formik.values.last_name.trim()
                          );
                          formik.handleBlur(e);
                        }}
                        onKeyDown={handleKeyDown}
                        posInputGroup={true}
                        inputType={"text"}
                        inputError={
                          formik.touched.last_name && formik.errors.last_name
                        }
                      >
                        <DriveFileRenameOutlineIcon
                          style={{ fill: "#5881FE" }}
                        />
                      </PosInput>
                      <Text color={Colors.errorColor}>
                        {formik.touched.last_name && formik.errors.last_name ? (
                          <span>{formik.errors.last_name}</span>
                        ) : null}
                      </Text>
                    </Stack>
                  </Box>
                </Flex>
                <Flex
                  spacing={4}
                  gap={{ base: 0, md: 14, lg: 20 }}
                  direction={{ base: "column", md: "row" }}
                >
                  <Box flex="1">
                    <Stack spacing={1}>
                      <PosLable
                        fontWeight={500}
                        label={true}
                        requiredLabel={formik.values.reward_customer}
                        name={"Email Address"}
                      />
                      <PosInput
                        id="email"
                        placeholder={"Janedoe@example.com"}
                        handleInputChange={formik.handleChange}
                        inputValue={formik.values.email}
                        handleBlur={(e) => {
                          formik.setFieldValue(
                            "email",
                            formik.values.email.trim()
                          );
                          formik.handleBlur(e);
                        }}
                        onKeyDown={handleKeyDown}
                        posInputGroup={true}
                        inputType={"email"}
                        inputError={formik.touched.email && formik.errors.email}
                      >
                        <EmailIcon color="#5881FE" />
                      </PosInput>
                      <Text color={Colors.errorColor}>
                        {formik.touched.email && formik.errors.email ? (
                          <span>{formik.errors.email}</span>
                        ) : null}
                      </Text>
                    </Stack>
                  </Box>
                  <Box flex={1}>
                    <Stack spacing={1}>
                      <PosLable
                        fontWeight={500}
                        requiredLabel={true}
                        name={"Phone Number"}
                      />
                      <PosInput
                        id="phone"
                        placeholder={"5555551234"}
                        handleInputChange={formik.handleChange}
                        inputValue={formik.values.phone}
                        handleBlur={(e) => {
                          const phoneValue = formik.values.phone
                            ? String(formik.values.phone).trim()
                            : "";
                          formik.setFieldValue("phone", phoneValue);
                          formik.handleBlur(e);
                        }}
                        onKeyDown={handleKeyDown}
                        posInputGroup={true}
                        inputType={"number"}
                        inputError={formik.touched.phone && formik.errors.phone}
                      >
                        <PhoneIcon color="#5881FE" />
                      </PosInput>
                      <Text color={Colors.errorColor}>
                        {formik.touched.phone && formik.errors.phone ? (
                          <span>{formik.errors.phone}</span>
                        ) : null}
                      </Text>
                    </Stack>
                  </Box>
                </Flex>
                <Flex
                  spacing={4}
                  gap={{ base: 0, md: 14, lg: 20 }}
                  direction={{ base: "column", md: "row" }}
                >
                  <Box flex="1">
                    <Stack spacing={1}>
                      <PosLable
                        fontWeight={500}
                        requiredLabel={true}
                        name={"Email Subscription"}
                      />
                      <PosRadio
                        name={"emailSubType"}
                        firstValue={0}
                        secoundValue={1}
                        // defaultValue={formik.values.emailSubType}
                        value={formik.values.emailSubType}
                        firstLable={Constants.SMS_SUBSCRIBTION_TYPE_YES}
                        secoundLable={Constants.SMS_SUBSCRIBTION_TYPE_NO}
                        handleSelectType={(e) => {
                          formik.values.emailSubType = e.target.value;
                          formik.setFieldValue("emailSubType", e.target.value);
                        }}
                      />
                      <Text color={Colors.errorColor}>
                        {formik.touched.emailSubType &&
                        formik.errors.emailSubType ? (
                          <span>{formik.errors.emailSubType}</span>
                        ) : null}
                      </Text>
                    </Stack>
                  </Box>
                  <Box flex={1}>
                    <Stack spacing={1}>
                      <PosLable
                        fontWeight={500}
                        requiredLabel={true}
                        name={"SMS Subscription"}
                      />
                      <PosRadio
                        name={"smsSubType"}
                        firstValue={0}
                        secoundValue={1}
                        // defaultValue={formik.values.smsSubType}
                        value={formik.values.smsSubType}
                        firstLable={Constants.SMS_SUBSCRIBTION_TYPE_YES}
                        secoundLable={Constants.SMS_SUBSCRIBTION_TYPE_NO}
                        handleSelectType={(e) => {
                          formik.values.smsSubType = e.target.value;
                          formik.setFieldValue("smsSubType", e.target.value);
                        }}
                      />
                      <Text color={Colors.errorColor}>
                        {formik.touched.smsSubType &&
                        formik.errors.smsSubType ? (
                          <span>{formik.errors.smsSubType}</span>
                        ) : null}
                      </Text>
                    </Stack>
                  </Box>
                </Flex>
                <Stack spacing={1}>
                  <PosLable fontWeight={500} label={true} name={"Notes"} />
                  <PosInput
                    id="notes"
                    placeholder={""}
                    handleInputChange={formik.handleChange}
                    inputValue={formik.values.notes}
                    handleBlur={(e) => {
                      formik.setFieldValue("notes", formik.values.notes.trim());
                      formik.handleBlur(e);
                    }}
                    onKeyDown={handleKeyDown}
                    posInput={true}
                    inputType={"text"}
                    inputError={formik.touched.notes && formik.errors.notes}
                  />
                  <Text color={Colors.errorColor}>
                    {formik.touched.notes && formik.errors.notes ? (
                      <span>{formik.errors.notes}</span>
                    ) : null}
                  </Text>
                </Stack>
                <Stack spacing={1}>
                  <PosLable
                    label={true}
                    name={"Please select a Price Book"}
                    fontWeight={"500"}
                  />
                  <Dropdown
                    // disabled={selectedPriceBooks.length > 0}
                    onChange={(e) => onSelectPriceB(e)}
                    options={priceBooks}
                    optionLabel="name"
                    placeholder="-- Select a Price Book --"
                    filter
                    value={selectedPriceBooks[0]}
                    // valueTemplate={selectedpriceBookTemplate}
                    // itemTemplate={priceBookOptionTemplate}
                    className="w-full md:w-14rem"
                    style={{ width: "100%" }}
                    resetFilterOnHide={true}
                    showClear={true}
                  />
                  {/* {selectedPriceBooks.length > 0 && (
                    <Box>
                      <Text
                        mt={"2rem"}
                        mb={"0.5rem"}
                        fontSize="1.13rem"
                        color={Colors.posTextInfo}
                        style={{
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "normal",
                          letterSpacing: "-0.01rem",
                        }}
                      >
                        Price Book Details
                      </Text>
                      <PosTable
                        columnNames={priceBookDetailColumns}
                        rawdata={finalSelectedpriceBooks}
                        totalcount={selectedPriceBooks.length}
                        footerHide={true}
                        singleDeleteButton={true}
                        goSingleDelete={(id) => onDeletepriceBook(id)}
                        sortColumnName={null}
                        page={null}
                        rowsPerPage={null}
                        order={null}
                      />
                    </Box>
                  )} */}
                  {selectedPriceBooksProducts.length > 0 && (
                    <Box>
                      <Text
                        mt={"1.3rem"}
                        mb={"2.3rem"}
                        fontSize="1.13rem"
                        color={Colors.posTextInfo}
                        style={{
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "normal",
                          letterSpacing: "-0.01rem",
                        }}
                      >
                        Price Book Product Details
                      </Text>
                      <Box maxHeight={"31.25rem"} overflow={"auto"}>
                        <PosTable
                          columnNames={priceBookProductsDetailColumns}
                          rawdata={finalSelectedpriceBooksProducts}
                          totalcount={selectedPriceBooksProducts.length}
                          footerHide={true}
                          noAction={true}
                          // singleDeleteButton={true}
                          // goSingleDelete={(id) => onDeletepriceBook(id)}
                          sortColumnName={null}
                          page={null}
                          rowsPerPage={null}
                          order={null}
                        />
                      </Box>
                    </Box>
                  )}
                  {/* <Modal
                    isOpen={isCoupProdViewModelOpen}
                    onClose={onCoupProdViewModelClose}
                    size={"3xl"}
                    isCentered
                    closeOnOverlayClick={false}
                    scrollBehavior={"inside"}
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>Product Details</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <PosTable
                          columnNames={priceBookProductViewColumns}
                          rawdata={selectedPriceProdViewDetails}
                          totalcount={selectedPriceProdViewDetails.length}
                          footerHide={true}
                          noAction={true}
                          sortColumnName={null}
                          page={null}
                          rowsPerPage={null}
                          order={null}
                        />
                      </ModalBody>
                    </ModalContent>
                  </Modal> */}
                </Stack>
                {custField &&
                  custField.map((project, index) => (
                    <Stack key={index}>
                      <PosLable
                        fontWeight={500}
                        requiredLabel={
                          project.field_required === "yes" &&
                          project.field_display === "yes"
                        }
                        label={true}
                        name={project.field_name}
                      />
                      {project.field_type === "text" && (
                        <PosInput
                          posInput={true}
                          type="text"
                          placeholder={"Enter " + project.field_name}
                          inputValue={project.value}
                          inputError={validationErrors[index]}
                          handleBlur={() =>
                            handleBlur(index, custField[index].value, project)
                          }
                          handleInputChange={(e) =>
                            handleInputChange(index, e.target.value)
                          }
                        ></PosInput>
                      )}
                      {project.field_type === "number" && (
                        <PosInput
                          posNumberInput={true}
                          type="number"
                          handleBlur={() =>
                            handleBlur(index, custField[index].value, project)
                          }
                          inputError={validationErrors[index]}
                          placeholder={"Enter " + project.field_name}
                          inputValue={project.value}
                          handleInputChange={(e) =>
                            handleInputChange(index, e.target.value)
                          }
                        ></PosInput>
                      )}
                      {project.field_type === "date" && (
                        <Calendar
                          iconPos="left"
                          inputStyle={{
                            paddingLeft: "1rem",
                            background: "#f6f8ff",
                            color: Colors.primeposTextInfo,
                            fontSize: "0.94rem",
                            fontWeight: 300,
                          }}
                          style={{
                            height: "2.50rem",
                            borderRadius: "0.625rem",
                            backgroundColor: Colors.primeCampCardBgColor,
                          }}
                          value={project.value}
                          placeholder={"Select " + project.field_name}
                          onChange={(e) => {
                            handleInputChange(index, e.value, "date");
                          }}
                          onBlur={() =>
                            handleBlur(index, project.value, project)
                          }
                          showIcon
                          showButtonBar
                          yearRange={"2000:2030"}
                          readOnlyInput
                        />
                      )}
                      <Text color={Colors.errorColor}>
                        {validationErrors[index]}
                      </Text>
                    </Stack>
                  ))}
                {/* </Box> */}
              </TabPanel>
              <TabPanel>
                <Stack flexDirection={"row"} spacing={1}>
                  <PosLable
                    fontWeight={500}
                    label={true}
                    name={
                      "Do you want to treat this reward customer as a credit account customer?"
                    }
                  />
                  <PosSwitchButton
                    mt={"2rem"}
                    ml={"1rem"}
                    id="reward_customer"
                    switchValue={formik.values.reward_customer}
                    onChange={(e) => {
                      formik.handleChange(e);
                      getCountryStates();
                      formikCustomer.resetForm();
                    }}
                  />
                </Stack>
                {formik.values.reward_customer && (
                  <>
                    {/* <Flex
                      p={{ base: "0" }}
                      gap={{ base: "0", md: "9" }}
                      direction={{ base: "column", md: "row" }}
                    >
                      <Box flex="1">
                        <Stack spacing={2} >
                          <PosLable
                            name={"Name"}
                            requiredLabel={true}
                            fontSize="0.94rem"
                            fontStyle="normal"
                            fontWeight={500}
                            lineHeight="normal"
                            letterSpacing="-0.02rem"
                          />
                          <PosInput
                            id="name"
                            mt={"1rem"}
                            placeholder={"Name"}
                            color={Colors.posCommon}
                            handleInputChange={formikCustomer.handleChange}
                            inputValue={formikCustomer.values.name}
                            handleBlur={formikCustomer.handleBlur}
                            // onKeyDown={handleKeyDown}
                            posInputGroup={true}
                            inputType={"text"}
                            inputError={
                              formikCustomer.touched.name &&
                              formikCustomer.errors.name
                            }
                          >
                            <DriveFileRenameOutlineIcon
                              sx={{
                                height: "1.21rem",
                                width: "1.21rem",
                                background: Colors.posInputGroupBackground,
                                borderRadius: "0.31rem",
                                color: Colors.posNavbarLink,
                              }}
                            />
                          </PosInput>
                          <Text color={Colors.posCancleButtonRed}>
                            {formikCustomer.touched.name &&
                            formikCustomer.errors.name ? (
                              <span>{formikCustomer.errors.name}</span>
                            ) : null}
                          </Text>
                        </Stack>
                      </Box>
                      <Box flex="1">
                        <Stack spacing={2} >
                          <PosLable
                            name={"Phone Number"}
                            requiredLabel={true}
                            fontSize="0.94rem"
                            fontStyle="normal"
                            fontWeight={500}
                            lineHeight="normal"
                            letterSpacing="-0.02rem"
                          />
                          <PosInput
                            id="phone"
                            mt={"1rem"}
                            placeholder={"phone"}
                            color={Colors.posCommon}
                            handleInputChange={formikCustomer.handleChange}
                            inputValue={formikCustomer.values.phone}
                            handleBlur={formikCustomer.handleBlur}
                            posInputGroup={true}
                            inputType={"text"}
                            inputError={
                              formikCustomer.touched.phone &&
                              formikCustomer.errors.phone
                            }
                          >
                            <Img
                              src={Phone}
                              alt="Phone"
                              sx={{
                                height: "1.21rem",
                                width: "1.21rem",
                                background: Colors.posInputGroupBackground,
                                borderRadius: "0.31rem",
                                color: Colors.posNavbarLink,
                              }}
                            />
                          </PosInput>
                          <Text color={Colors.posCancleButtonRed}>
                            {formikCustomer.touched.phone &&
                            formikCustomer.errors.phone ? (
                              <span>{formikCustomer.errors.phone}</span>
                            ) : null}
                          </Text>
                        </Stack>
                      </Box>
                    </Flex> */}
                    {/* <Stack spacing={2} >
                      <PosLable
                        name={"Email"}
                        requiredLabel={true}
                        fontStyle="normal"
                        fontSize="0.94rem"
                        fontWeight={500}
                        lineHeight="normal"
                        letterSpacing="-0.02rem"
                      />
                      <PosInput
                        id="email"
                        mt={"1rem"}
                        placeholder={"email"}
                        color={Colors.posCommon}
                        handleInputChange={formikCustomer.handleChange}
                        inputValue={formikCustomer.values.email}
                        handleBlur={formikCustomer.handleBlur}
                        posInputGroup={true}
                        inputType={"text"}
                        inputError={
                          formikCustomer.touched.email &&
                          formikCustomer.errors.email
                        }
                      >
                        <DriveFileRenameOutlineIcon
                          sx={{
                            height: "1.21rem",
                            width: "1.21rem",
                            background: Colors.posInputGroupBackground,
                            borderRadius: "0.31rem",
                            color: Colors.posNavbarLink,
                          }}
                        />
                      </PosInput>
                      <Text color={Colors.posCancleButtonRed}>
                        {formikCustomer.touched.email &&
                        formikCustomer.errors.email ? (
                          <span>{formikCustomer.errors.email}</span>
                        ) : null}
                      </Text>
                    </Stack> */}
                    {/* {((null == loaderResponse?.data?.data?.first_name || '' == loaderResponse?.data?.data?.first_name)) 
                    &&  */}

                    <Flex
                      spacing={4}
                      gap={{ base: 0, md: 14, lg: 20 }}
                      direction={{ base: "column", md: "row" }}
                    >
                      <Box flex={1}>
                        <Stack spacing={1}>
                          <PosLable
                            fontWeight={500}
                            label={true}
                            requiredLabel={formik.values.reward_customer}
                            name={"Business Name"}
                          />
                          <PosInput
                            id="name"
                            placeholder={"Enter a Business Name"}
                            handleInputChange={formikCustomer.handleChange}
                            inputValue={formikCustomer.values.name}
                            handleBlur={(e) => {
                              formikCustomer.setFieldValue(
                                "name",
                                formikCustomer.values.name.trim()
                              );
                              formikCustomer.handleBlur(e);
                            }}
                            onKeyDown={handleKeyDown}
                            posInputGroup={true}
                            inputType={"text"}
                            inputError={
                              formikCustomer.touched.name &&
                              formikCustomer.errors.name
                            }
                          >
                            <DriveFileRenameOutlineIcon
                              style={{ fill: "#5881FE" }}
                            />
                          </PosInput>
                          <Text color={Colors.errorColor}>
                            {formikCustomer.touched.name &&
                            formikCustomer.errors.name ? (
                              <span>{formikCustomer.errors.name}</span>
                            ) : null}
                          </Text>
                        </Stack>
                      </Box>
                      {/* <Box flex={1}>
                        <Stack spacing={1}>
                          <PosLable
                            fontWeight={500}
                            label={true}
                            name={"Last Name"}
                          />
                          <PosInput
                            id="last_name"
                            placeholder={"Doe"}
                            handleInputChange={formik.handleChange}
                            inputValue={formik.values.last_name}
                            handleBlur={(e) => {
                              formik.setFieldValue(
                                "last_name",
                                formik.values.last_name.trim()
                              );
                              formik.handleBlur(e);
                            }}
                            onKeyDown={handleKeyDown}
                            posInputGroup={true}
                            inputType={"text"}
                            inputError={
                              formik.touched.last_name && formik.errors.last_name
                            }
                          >
                            <DriveFileRenameOutlineIcon
                              style={{ fill: "#5881FE" }}
                            />
                          </PosInput>
                          <Text color={Colors.errorColor}>
                            {formik.touched.last_name && formik.errors.last_name ? (
                              <span>{formik.errors.last_name}</span>
                            ) : null}
                          </Text>
                        </Stack>
                      </Box> */}
                    </Flex>
                    {/* )} */}
                    {(null == loaderResponse?.data?.data?.email ||
                      "" == loaderResponse?.data?.data?.email) && (
                      <Stack spacing={1}>
                        <PosLable
                          fontWeight={500}
                          label={true}
                          requiredLabel={formik.values.reward_customer}
                          name={"Email Address"}
                        />
                        <PosInput
                          id="email"
                          placeholder={"Janedoe@example.com"}
                          handleInputChange={formik.handleChange}
                          inputValue={formik.values.email}
                          handleBlur={(e) => {
                            formik.setFieldValue(
                              "email",
                              formik.values.email.trim()
                            );
                            formik.handleBlur(e);
                          }}
                          onKeyDown={handleKeyDown}
                          posInputGroup={true}
                          inputType={"email"}
                          inputError={
                            formik.touched.email && formik.errors.email
                          }
                        >
                          <EmailIcon color="#5881FE" />
                        </PosInput>
                        <Text color={Colors.errorColor}>
                          {formik.touched.email && formik.errors.email ? (
                            <span>{formik.errors.email}</span>
                          ) : null}
                        </Text>
                      </Stack>
                    )}
                    <Stack spacing={2}>
                      <PosLable
                        name={"Address Line 1"}
                        requiredLabel={true}
                        fontStyle="normal"
                        fontSize="0.94rem"
                        fontWeight={500}
                        lineHeight="normal"
                        letterSpacing="-0.02rem"
                      />
                      <PosInput
                        id="address1"
                        mt={"1rem"}
                        placeholder={"Enter Address Line 1"}
                        color={Colors.posCommon}
                        handleInputChange={formikCustomer.handleChange}
                        inputValue={formikCustomer.values.address1}
                        handleBlur={formikCustomer.handleBlur}
                        // onKeyDown={handleKeyDown}
                        posInputGroup={true}
                        inputType={"text"}
                        inputError={
                          formikCustomer.touched.address1 &&
                          formikCustomer.errors.address1
                        }
                      >
                        <DriveFileRenameOutlineIcon
                          sx={{
                            height: "1.21rem",
                            width: "1.21rem",
                            background: Colors.posInputGroupBackground,
                            borderRadius: "0.31rem",
                            color: Colors.posNavbarLink,
                          }}
                        />
                      </PosInput>
                      <Text color={Colors.posCancleButtonRed}>
                        {formikCustomer.touched.address1 &&
                        formikCustomer.errors.address1 ? (
                          <span>{formikCustomer.errors.address1}</span>
                        ) : null}
                      </Text>
                    </Stack>
                    <Stack spacing={2}>
                      <PosLable
                        name={"Address Line 2"}
                        label={true}
                        fontStyle="normal"
                        fontSize="0.94rem"
                        fontWeight={500}
                        lineHeight="normal"
                        letterSpacing="-0.02rem"
                      />
                      <PosInput
                        id="address2"
                        mt={"1rem"}
                        placeholder={"Enter Address Line 2"}
                        color={Colors.posCommon}
                        handleInputChange={formikCustomer.handleChange}
                        inputValue={formikCustomer.values.address2}
                        handleBlur={formikCustomer.handleBlur}
                        // onKeyDown={handleKeyDown}
                        posInputGroup={true}
                        inputType={"text"}
                        inputError={
                          formikCustomer.touched.address2 &&
                          formikCustomer.errors.address2
                        }
                      >
                        <DriveFileRenameOutlineIcon
                          sx={{
                            height: "1.21rem",
                            width: "1.21rem",
                            background: Colors.posInputGroupBackground,
                            borderRadius: "0.31rem",
                            color: Colors.posNavbarLink,
                          }}
                        />
                      </PosInput>
                      <Text color={Colors.posCancleButtonRed}>
                        {formikCustomer.touched.address2 &&
                        formikCustomer.errors.address2 ? (
                          <span>{formikCustomer.errors.address2}</span>
                        ) : null}
                      </Text>
                    </Stack>
                    <Flex
                      p={{ base: "0" }}
                      gap={{ base: "0", md: "9" }}
                      direction={{ base: "column", md: "row" }}
                    >
                      <Box flex="1">
                        <PosLable
                          name={"City"}
                          requiredLabel={true}
                          fontStyle="normal"
                          fontSize="0.94rem"
                          fontWeight={500}
                          lineHeight="normal"
                          letterSpacing="-0.02rem"
                        />
                        <PosInput
                          id="city"
                          mt={"1rem"}
                          placeholder={"--Select City--"}
                          color={Colors.posCommon}
                          handleInputChange={formikCustomer.handleChange}
                          inputValue={formikCustomer.values.city}
                          handleBlur={formikCustomer.handleBlur}
                          // onKeyDown={handleKeyDown}
                          posInputGroup={true}
                          inputType={"text"}
                          inputError={
                            formikCustomer.touched.city &&
                            formikCustomer.errors.city
                          }
                        >
                          <DriveFileRenameOutlineIcon
                            sx={{
                              height: "1.21rem",
                              width: "1.21rem",
                              background: Colors.posInputGroupBackground,
                              borderRadius: "0.31rem",
                              color: Colors.posNavbarLink,
                            }}
                          />
                        </PosInput>
                        <Text color={Colors.posCancleButtonRed}>
                          {formikCustomer.touched.city &&
                          formikCustomer.errors.city ? (
                            <span>{formikCustomer.errors.city}</span>
                          ) : null}
                        </Text>
                        </Box>
                        <Box flex="1">
                        <PosLable
                          name={"Zipcode"}
                          label={true}
                          requiredLabel={true}
                          fontWeight={500}
                        ></PosLable>
                        <PosInput
                          posInputGroup={true}
                          id={"zipcode"}
                          posNumberInput={true}
                          inputType={"number"}
                          placeholder={"Enter Zipcode"}
                          inputValue={formikCustomer.values.zipcode || ""}
                          handleBlur={formikCustomer.handleBlur}
                          handleInputChange={formikCustomer.handleChange}
                          inputError={formikCustomer.touched.zipcode && formikCustomer.errors.zipcode}
                        >
                          <DriveFileRenameOutlineIcon style={{ fill: "#5881FE" }} />
                        </PosInput>
                        <Text color={Colors.errorColor}>
                          {formikCustomer.touched.zipcode && formikCustomer.errors.zipcode ? (
                            <span>{formikCustomer.errors.zipcode}</span>
                          ) : null}
                        </Text>
                      </Box>
                    </Flex>
                    <Flex
                      p={{ base: "0" }}
                      gap={{ base: "0", md: "9" }}
                      direction={{ base: "column", md: "row" }}
                    >
                      <Box flex="1">
                        <PosLable
                          name={"Country"}
                          requiredLabel={true}
                          fontStyle="normal"
                          fontSize="0.94rem"
                          fontWeight={500}
                          lineHeight="normal"
                          letterSpacing="-0.02rem"
                        />
                        <PosCountryStateDropDown
                          options={countries}
                          value={formikCustomer.values.selectedCountry}
                          countriesSelect={true}
                          onChange={(e) => {
                            if (
                              undefined != e.target.value &&
                              null != e.target.value &&
                              "" != e.target.value
                            ) {
                              handleSelectedCountry(e.target.value);
                              formikCustomer.setFieldValue(
                                "selectedCountry",
                                e.target.value
                              );
                            }
                          }}
                          onBlur={(e) => {
                            formikCustomer.setFieldTouched(
                              "selectedCountry",
                              true
                            );
                            // if (!e.target.value) {
                            //   setCountryError(Constants.CUSTOMER_PLEASE_SELECT_A_COUNTRY);
                            // } else {
                            //   setCountryError("");
                            // }
                          }}
                          optionLabel="label"
                          placeholder="--Select Country--"
                          width={"100%"}
                          maxWidth={"42rem"}
                          height={"2.5rem"}
                          resetFilterOnHide={true}
                          className="w-full md:w-20rem"
                          lableAvailable={true}
                          disabled={false}
                        />
                        <Text color={Colors.errorColor}>
                          {formikCustomer.touched.selectedCountry &&
                          countryError.length > 0 ? (
                            <span>{countryError}</span>
                          ) : null}
                        </Text>
                      </Box>

                      <Box flex="1">
                        <PosLable
                          name={"State"}
                          requiredLabel={true}
                          fontStyle="normal"
                          fontSize="0.94rem"
                          fontWeight={500}
                          lineHeight="normal"
                          letterSpacing="-0.02rem"
                          pb={"0.31rem"}
                        />
                        {states.length === 0 ? (
                          <Select
                            id="state"
                            value={formikCustomer.values.selectedState || "N.A"}
                            sx={{
                              color: "gray",
                              fontSize: "0.94rem !important",
                              fontWeight: 300,
                              letterSpacing: "-0.01rem !important",
                              backgroundColor: "#5881fe0d",
                              borderRadius: "0.625rem",
                              height: "2.5rem",
                              border: "1px solid #b7b7b71f",
                              "&[disabled]": {
                                backgroundColor: "#5881fe0d !important",
                                opacity: 1,
                              },
                            }}
                            // onChange={(e) => {
                            //   handleSelectedState(e.target.value);
                            //   formikCustomer.setFieldValue("selectedState", e.target.value);
                            // }}
                            isDisabled
                            placeholder={
                              states.length > 0 ? "N.A" : "--Select State--"
                            }
                          >
                            <option value="N.A">N.A</option>
                          </Select>
                        ) : (
                          <PosCountryStateDropDown
                            options={states}
                            value={selectedState}
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              if (
                                undefined != e.value &&
                                null != e.value &&
                                "" != e.value
                              ) {
                                handleSelectedState(selectedValue);
                                formikCustomer.setFieldValue(
                                  "selectedState",
                                  selectedValue
                                );
                                formikCustomer.setFieldTouched(
                                  "selectedState",
                                  true
                                );
                                if (!selectedValue) {
                                  formikCustomer.setFieldError(
                                    "selectedState",
                                    Constants.CUSTOMER_PLEASE_SELECT_A_STATE
                                  );
                                } else {
                                  formikCustomer.setFieldError(
                                    "selectedState",
                                    ""
                                  );
                                }
                              }
                            }}
                            onBlur={() => {
                              formikCustomer.setFieldTouched(
                                "selectedState",
                                true
                              );
                            }}
                            optionLabel="label"
                            placeholder="--Select State--"
                            width={"100%"}
                            maxWidth={"42rem"}
                            height={"2.5rem"}
                            resetFilterOnHide={true}
                            className="w-full md:w-20rem"
                            lableAvailable={true}
                            disabled={false}
                          />
                        )}
                        {states.length > 0 && (
                          <Text color={Colors.errorColor}>
                            {formikCustomer.touched.selectedState &&
                            formikCustomer.errors.selectedState ? (
                              <span>{formikCustomer.errors.selectedState}</span>
                            ) : null}
                          </Text>
                        )}
                      </Box>
                    </Flex>
                    <Box
                      direction={{ base: "column", md: "row" }}
                      alignItems={{ base: "center" }}
                      gap={2}
                    >
                      <Text
                        mt={"2rem"}
                        fontSize="1.13rem"
                        color={Colors.posTextInfo}
                        style={{
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "normal",
                          letterSpacing: "-0.01rem",
                        }}
                      >
                        Other Details
                      </Text>
                      {showCreditBalance ? (
                        <>
                          <Flex
                            gap="9"
                            direction={{ base: "column", md: "row" }}
                            alignItems={{ base: "center", md: "end" }}
                            pr={{ base: 4, md: "1.75rem" }}
                          >
                            <Box
                              flex="1"
                              borderRadius="0.63rem"
                              bg={Colors.posPageDataBackground}
                              width="100%"
                            >
                              <PosLable
                                label={true}
                                name={"Credit Balance"}
                                mt="0.31rem"
                                fontStyle="normal"
                                fontSize="1.13rem"
                                fontWeight={500}
                                lineHeight="normal"
                                letterSpacing="-0.02rem"
                              />
                              <Flex
                                p={2.5}
                                borderRadius={"0.63rem"}
                                border={"1px solid #b7b7b71f"}
                                bg={Colors.posviewbgcolor}
                                alignItems={"center"}
                              >
                                <DriveFileRenameOutlineRoundedIcon
                                  sx={{
                                    background: Colors.posInputGroupBackground,
                                    borderRadius: "0.31rem",
                                    color: Colors.posNavbarLink,
                                  }}
                                />
                                <Text
                                  ml={2.5}
                                  color={Colors.posViewTextColor}
                                  fontWeight={300}
                                >
                                  Balance
                                </Text>
                                <Spacer />
                                <Text color={Colors.taxInfo}>
                                  {CustomerBalanceData !== null &&
                                  CustomerBalanceData !== undefined &&
                                  CustomerBalanceData.length !== 0 &&
                                  CustomerBalanceData !== ""
                                    ? `$${twofix(CustomerBalanceData)}`
                                    : "N.A"}
                                </Text>
                              </Flex>
                            </Box>
                            <Box
                              flex="1"
                              borderRadius="0.63rem"
                              bg={Colors.posPageDataBackground}
                              alignItems={"center"}
                              pb={{ base: "2rem", md: "0" }}
                            >
                              <PosFormButton
                                buttonsubmit={"Add Credit"}
                                bg={Colors.posNavbarLink}
                                width={
                                  !isScreenSize480 ? "11.81rem" : "2.38rem"
                                }
                                style={{
                                  display: "flex",
                                  padding: "0.63rem",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "0.63rem",
                                  borderRadius: "0.31rem",
                                  border: "1px solid #E3E3E3",
                                  boxShadow:
                                    "0px 0.25rem 0.25rem 0px #e0e0e01a",
                                }}
                                onClick={() => handleaddPage()}
                              />
                            </Box>
                          </Flex>
                          <Formik
                            initialValues={initialValues}
                            validationSchema={CustomerCreditSchema}
                            onSubmit={(values, { setSubmitting }) => {
                              handleSubmit(values);
                              setSubmitting(false);
                            }}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              setFieldValue,
                              resetForm,
                            }) => (
                              <Form>
                                <Modal
                                  isOpen={isOpen}
                                  onClose={() => {
                                    // formikCustomer.setFieldValue("amount", "");
                                    setUserBalance(0);
                                    onClose();
                                  }}
                                  isCentered
                                  size="2xl"
                                  width="35.63rem"
                                  closeOnOverlayClick={false}
                                >
                                  <ModalOverlay />
                                  <ModalContent>
                                    <ModalHeader
                                      mt={{ base: "0", md: "1.56rem" }}
                                      style={{
                                        display: "inline-flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      Add Customer Credit
                                    </ModalHeader>
                                    <Divider />
                                    <ModalBody
                                      pb={{ base: 4, md: "1.38rem" }}
                                      pl={{ base: 4, md: "1.06rem" }}
                                      pr={{ base: 4, md: "1.75rem" }}
                                    >
                                      <Stack mt="0.87rem">
                                        <Flex
                                          bg={Colors.modifierTable}
                                          style={{
                                            display: "flex",
                                            padding: "0.63rem",
                                            alignItems: "center",
                                            gap: "0.63rem",
                                            flexShrink: 0,
                                            borderRadius: "0.63rem",
                                          }}
                                        >
                                          <Image
                                            src={warnignIcon}
                                            alt="Warning Icon"
                                            boxSize="0.81rem"
                                            mr="0.5rem"
                                          />
                                          <Text>
                                            Your Available Credit Balance is:
                                            <Text as="span" fontWeight="bold">
                                              {CustomerBalanceData !== null &&
                                              CustomerBalanceData !==
                                                undefined &&
                                              CustomerBalanceData.length !==
                                                0 &&
                                              CustomerBalanceData !== ""
                                                ? ` $${twofix(
                                                    CustomerBalanceData
                                                  )}`
                                                : ""}
                                            </Text>
                                          </Text>
                                        </Flex>
                                        <Text mt="2rem">* Amount</Text>
                                        <PosInput
                                          id="amount"
                                          placeholder="Enter an amount"
                                          handleInputChange={(e) => {
                                            let newBalance = 0;
                                            if (
                                              undefined !== e.target.value &&
                                              null !== e.target.value &&
                                              "" !== e.target.value
                                            ) {
                                              newBalance =
                                                parseFloat(e.target.value) +
                                                parseFloat(CustomerBalanceData);
                                              setUserBalance(newBalance);
                                              handleChange(e);
                                            } else {
                                              handleChange(e);
                                              setUserBalance(
                                                parseFloat(
                                                  customerDataBalance.balance
                                                )
                                              );
                                            }
                                          }}
                                          inputValue={values.amount}
                                          handleBlur={(e) => {
                                            handleBlur(e);
                                            setFieldValue(
                                              "amount",
                                              twofix(values.amount) || ""
                                            );
                                          }}
                                          onKeyDown={(e) => {
                                            if (
                                              e.key === "-" ||
                                              e.key === "ArrowUp" ||
                                              e.key === "ArrowDown"
                                            ) {
                                              e.preventDefault();
                                            }
                                          }}
                                          inputType="number"
                                          posNumberInput
                                        />
                                        <Text color={Colors.posCancleButtonRed}>
                                          {touched.amount && errors.amount ? (
                                            <span>{errors.amount}</span>
                                          ) : null}
                                        </Text>
                                        {/* {setAmount(values.amount)} */}
                                      </Stack>
                                      <Stack pt="0.88rem">
                                        <Text>Reference/Reason</Text>
                                        <PosInput
                                          id="external_reference"
                                          placeholder="Enter a reason"
                                          color={Colors.posTextInfo}
                                          handleInputChange={handleChange}
                                          inputValue={values.external_reference}
                                          handleBlur={handleBlur}
                                          posInput
                                          inputType="text"
                                        />
                                        <Text color={Colors.posCancleButtonRed}>
                                          {touched.external_reference &&
                                          errors.external_reference ? (
                                            <span>
                                              {errors.external_reference}
                                            </span>
                                          ) : null}
                                        </Text>
                                        {values.amount > 0 && (
                                          <Text style={{ color: "#008000" }}>
                                            After this transaction, the new
                                            Credit Balance for this customer
                                            will be $
                                            <Text as="span" fontWeight="bold">
                                              {twofix(userBalance)}
                                            </Text>
                                            .
                                          </Text>
                                        )}
                                        {/* {setExternal_reference(values.external_reference)} */}
                                      </Stack>
                                    </ModalBody>
                                    <ModalFooter
                                      flexDirection="column"
                                      alignItems="end"
                                      style={{
                                        paddingInlineEnd:
                                          "var(--chakra-space-4",
                                      }}
                                    >
                                      <Flex mb="1.69rem" gap="0.5rem">
                                        <PosFormButton
                                          buttonText="Cancel"
                                          CancelButton
                                          onClick={() => closepop(resetForm)}
                                          isDisabled={
                                            buttonDisable || isSubmitting
                                          }
                                        />
                                        <PosFormButton
                                          isDisabled={
                                            !values.amount ||
                                            values.amount?.length <= 0 ||
                                            errors.amount ||
                                            errors.external_reference ||
                                            buttonDisable ||
                                            isSubmitting
                                          }
                                          buttonsubmit="Submit"
                                          SubmitButton
                                          onClick={() =>
                                            handleSubmit(values, resetForm)
                                          }
                                        />
                                      </Flex>
                                    </ModalFooter>
                                  </ModalContent>
                                </Modal>
                              </Form>
                            )}
                          </Formik>
                        </>
                      ) : (
                        <>
                          <Stack spacing={3}>
                            <PosLable
                              fontWeight={500}
                              name={"Intial Amount"}
                              label={true}
                            />
                            <PosInput
                              id={"amount"}
                              placeholder={"Enter Initial Amount"}
                              handleInputChange={formikCustomer.handleChange}
                              inputValue={formikCustomer.values.amount}
                              // handleBlur={formikCustomer.handleBlur}
                              // onKeyDown={handleKeyDown}
                              handleBlur={(e) => {
                                formikCustomer.handleBlur(e);
                                formikCustomer.setFieldValue(
                                  "amount",
                                  twofix(formikCustomer.values.amount) || ""
                                );
                              }}
                              onKeyDown={(e) => {
                                if (
                                  e.key === "-" ||
                                  e.key === "ArrowUp" ||
                                  e.key === "ArrowDown"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              inputType={"number"}
                              posNumberInput={true}
                            />
                            <Text color={Colors.posCancleButtonRed}>
                              {formikCustomer.touched.amount &&
                              formikCustomer.errors.amount ? (
                                <span>{formikCustomer.errors.amount}</span>
                              ) : null}
                            </Text>
                          </Stack>
                          <Stack spacing={2}>
                            <PosLable
                              name={"Reference/Reason"}
                              label={true}
                              fontStyle="normal"
                              fontSize="0.94rem"
                              fontWeight={500}
                              lineHeight="normal"
                              letterSpacing="-0.02rem"
                            />
                            <PosInput
                              id="external_reference"
                              // mt={"1rem"}
                              placeholder={"Enter Reference/Reason"}
                              color={Colors.posCommon}
                              handleInputChange={formikCustomer.handleChange}
                              inputValue={formikCustomer.values.external_reference}
                              handleBlur={formikCustomer.handleBlur}
                              // onKeyDown={handleKeyDown}
                              posInputGroup={true}
                              inputType={"text"}
                              inputError={
                                formikCustomer.touched.external_reference &&
                                formikCustomer.errors.external_reference
                              }
                            >
                              <DriveFileRenameOutlineIcon
                                sx={{
                                  height: "1.21rem",
                                  width: "1.21rem",
                                  background: Colors.posInputGroupBackground,
                                  borderRadius: "0.31rem",
                                  color: Colors.posNavbarLink,
                                }}
                              />
                            </PosInput>
                            <Text color={Colors.posCancleButtonRed}>
                              {formikCustomer.touched.external_reference &&
                              formikCustomer.errors.external_reference ? (
                                <span>{formikCustomer.errors.external_reference}</span>
                              ) : null}
                            </Text>
                          </Stack>
                        </>
                      )}
                      <Stack spacing={3} mt="0.13rem">
                        <PosLable
                          label={true}
                          name={"Notes"}
                          mt="0.31rem"
                          fontStyle="normal"
                          fontSize="0.94rem"
                          fontWeight={500}
                          lineHeight="normal"
                          letterSpacing="-0.02rem"
                        />
                        <Textarea
                          id="notes"
                          placeholder=""
                          borderRadius={"0.63rem"}
                          border={Colors.posTextAreaBorder}
                          background={Colors.posInputBackground}
                          onChange={formikCustomer.handleChange}
                          value={formikCustomer.values.notes}
                          onBlur={formikCustomer.handleBlur}
                          // onKeyDown={handleKeyDown}
                          inputerror={
                            formikCustomer.touched.notes &&
                            formikCustomer.errors.notes
                          }
                        />
                        <Text color={Colors.errorColor}>
                          {formikCustomer.touched.notes &&
                          formikCustomer.errors.notes ? (
                            <span>{formikCustomer.errors.notes}</span>
                          ) : null}
                        </Text>
                      </Stack>
                      {null != formikCustomer.values.notes &&
                      "" != formikCustomer.values.notes &&
                      191 - formikCustomer.values.notes.length > 0 ? (
                        <Text
                          pt={"0.13rem"}
                          color={Colors.textareaColor}
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={300}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                        >
                          {191 - formikCustomer.values.notes.length} characters
                          left for notes
                        </Text>
                      ) : (
                        ""
                      )}
                      <Stack spacing={3}>
                        <PosLable
                          fontWeight={500}
                          name={"Credit Limit"}
                          label={true}
                        />
                        <PosInput
                          id={"credit_limit"}
                          placeholder={"Credit Limit"}
                          handleInputChange={formikCustomer.handleChange}
                          inputValue={formikCustomer.values.credit_limit}
                          // handleBlur={formikCustomer.handleBlur}
                          // onKeyDown={handleKeyDown}
                          handleBlur={(e) => {
                            formikCustomer.handleBlur(e);
                            formikCustomer.setFieldValue(
                              "credit_limit",
                              twofix(formikCustomer.values.credit_limit) || ""
                            );
                          }}
                          onKeyDown={(e) => {
                            if (
                              e.key === "-" ||
                              e.key === "ArrowUp" ||
                              e.key === "ArrowDown"
                            ) {
                              e.preventDefault();
                            }
                          }}
                          inputType={"number"}
                          posNumberInput={true}
                        />
                        <Text color={Colors.posCancleButtonRed}>
                          {formikCustomer.touched.credit_limit &&
                          formikCustomer.errors.credit_limit ? (
                            <span>{formikCustomer.errors.credit_limit}</span>
                          ) : null}
                        </Text>
                      </Stack>
                      <Flex flexDirection={"row"}>
                        <PosLable
                          fontWeight={500}
                          name={"Tax Exempt"}
                          label={true}
                        />
                        <Tippy
                          content="Turn on, to exempt this customer on tax payments."
                          interactive={true}
                          maxWidth={390}
                          placement="top"
                          animation="fade"
                          inertia={true}
                          moveTransition="transform 0.2s ease-out"
                          theme="tomato"
                        >
                          <Image
                            src={toolTip}
                            alt="Tooltip"
                            ml={{ base: "0.4rem", md: "0.3rem" }}
                            mt={"2rem"}
                          />
                        </Tippy>
                      </Flex>
                      <Stack
                        direction="row"
                        mt="0.313rem"
                        w="2.75rem"
                        height="1.38rem"
                        flexShrink="0"
                      >
                        <PosSwitchButton
                          id="tax_exempt"
                          switchValue={formikCustomer.values.tax_exempt}
                          onChange={formikCustomer.handleChange}
                        />
                      </Stack>
                      <Flex flexDirection={"row"}>
                        <PosLable
                          fontWeight={500}
                          name={"Exclude from Reward Calculation"}
                          label={true}
                        />
                        <Tippy
                          content="Turn on, to exclude this customer from reward calculations."
                          interactive={true}
                          maxWidth={390}
                          placement="top"
                          animation="fade"
                          inertia={true}
                          moveTransition="transform 0.2s ease-out"
                          theme="tomato"
                        >
                          <Image
                            src={toolTip}
                            alt="Tooltip"
                            ml={{ base: "0.4rem", md: "0.3rem" }}
                            mt={"2rem"}
                          />
                        </Tippy>
                      </Flex>
                      <Stack
                        direction="row"
                        mt="0.313rem"
                        w="2.75rem"
                        height="1.38rem"
                        flexShrink="0"
                      >
                        <PosSwitchButton
                          id="exclude_from_reward_calculation"
                          switchValue={
                            formikCustomer.values
                              .exclude_from_reward_calculation
                          }
                          onChange={formikCustomer.handleChange}
                        />
                      </Stack>
                    </Box>
                  </>
                )}
              </TabPanel>
              <TabPanel>
                {isBoxVisible && (
                  <>
                    {/* <Text
                  mb={"2rem"}
                  fontSize="1.5rem"
                  w="10.5rem"
                  color={Colors.posTextInfo}
                  style={{
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                    letterSpacing: "-0.27px",
                  }}
                >
                  Point Details
                </Text> */}
                    <Box mt={"2rem"}>
                      <PosTable
                        columnNames={columnNames}
                        rawdata={rewardRawData}
                        footerHide={true}
                        noAction={true}
                      />
                    </Box>
                  </>
                )}
              </TabPanel>
              {isSecondTabVisible && (
                <TabPanel>
                  <Flex
                    direction={{ base: "column", md: "row" }}
                    alignItems={{ base: "stretch", md: "center" }}
                    gap={{ base: 2, md: 0 }}
                    mt={"2rem"}
                    mb={"1rem"}
                  >
                    <PosSearch
                      value={searchQuery || ""}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      onSearch={handleSearchList}
                      handleClear={handleClear}
                    />
                  </Flex>
                  {showTable ? null : loading ? (
                    <PosProgress />
                  ) : totalordercount > 0 ? (
                    <PosDataGridTable
                      columns={orderColumnNames}
                      rows={modifiedOrderData}
                      totalcount={totalordercount}
                      columnVisibilityModel={olColumnVisibilityModel}
                      onColumnVisibilityModelChange={(newModel) =>
                        setOlColumnVisibilityModel(newModel)
                      }
                      paginationModel={paginationModel}
                      paginationMode="server"
                      sortingMode="server"
                      onPaginationModelChange={setPaginationModel}
                      sortModel={sortModel}
                      onSortModelChange={(newSortModel) => {
                        sortRef.current = sortModel;
                        setSortModel(newSortModel);
                      }}
                      slots={{
                        toolbar: GridToolbar,
                      }}
                      fileName={Constants.RC_ORDERS_EXPORT_FILENAME}
                    />
                  ) : (
                    <PosNoDataFound title={Constants.NO_REWARD_CARD_ORDERS} />
                  )}
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
    </Flex>
  );
};
export default WithRouter(RewardCardsEdit);
