import {
  Box,
  ButtonGroup,
  Flex,
  Spacer,
  Stack,
  Text,
  Wrap,
  WrapItem,
  useMediaQuery,
} from "@chakra-ui/react";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosNoDataFound,
  PosProgress,
  PosTostMessage,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import {
  endOfTodaysDay,
  reportDateFormate,
  startOfTodaysDay,
} from "../../../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";

const TotalCategorySales = (props) => {
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const [tableData, setTableData] = useState([]);
  const [totalRow, setTotalRow] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [totalcount, setTotalCount] = useState(0);
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [locations, setLocations] = useState([]);
  const [categoryes, setCategoryes] = useState([]);
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const pageRef = useRef(false);
  const [errors, setErrors] = useState("");
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [isExtraLarge] = useMediaQuery("(min-width: 1710px)");
  const [isLarge] = useMediaQuery("(max-width: 1435px)");
  const [isExtraSmall] = useMediaQuery("(max-width: 480px)");
  const [isMeduim] = useMediaQuery("(max-width: 1266px)");
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 1 };
  const [applyClicked, setApplyClicked] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "cat_name",
      sort: "desc",
    },
  ]);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState(
    {
      free_qty : false
    }
  );
  const columnNames = [
    {
      field: "cat_name",
      headerName: "Category Name",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "qty",
      headerName: "Quantity Sold",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "free_qty",
      headerName: "Free Quantity",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "net_qty_sold",
      headerName: "Net Quantity Sold",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "total_sale_price",
      headerName: "Gross Sale($)",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "sale_percentage",
      headerName: "Percentage(%)",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "cost_of_sale",
      headerName: "Cost of Sale($)",
      sortable: false,
      ...actionColumn,
    },
    // {
    //   field: "avg_order",
    //   headerName: "Avg Order ",
    //   sortable: false,
    //   ...actionColumn,
    // },
    // {
    //   field: "avg_price",
    //   headerName: " AVG Price ($)",
    //   sortable: false,
    //   ...actionColumn,
    // },
  ];

  const totalColumnName = [
    {
      field: "qty",
      headerName: "Total Quantity Sold",
      sortable: false,
      disableColumnMenu: true,
      ...actionColumn,
    },
    {
      field: "free_qty",
      headerName: "Total Free Quantity",
      sortable: false,
      disableColumnMenu: true,
      ...actionColumn,
    },
    {
      field: "net_qty_sold",
      headerName: "Total Net Quantity Sold",
      sortable: false,
      disableColumnMenu: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "total_sale_price",
      headerName: "Total Gross Sale($)",
      sortable: false,
      disableColumnMenu: true,
      ...actionColumn,
    },
    {
      field: "cost_of_sale",
      headerName: "Total Cost of Sale($)",
      sortable: false,
      disableColumnMenu: true,
      ...actionColumn,
    },
    {
      field: "avg_order",
      headerName: "Total Avg Order ",
      sortable: false,
      disableColumnMenu: true,
      ...actionColumn,
    },
    {
      field: "avg_price",
      headerName: "Total AVG Price($)",
      sortable: false,
      disableColumnMenu: true,
      ...actionColumn,
    },
  ];
  const [totalColumnVisibilityModel, setTotalColumnVisibilityModel] = useState({});
  const [loading, setLoading] = useState(true);
  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const effectRun = useRef(true);
  const sortRef = useRef(sortModel);
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          if (
            undefined !== loaderResponse?.data?.data?.location &&
            null !== loaderResponse?.data?.data?.location &&
            loaderResponse?.data?.data?.location?.length === 1
          ) {
            setSelectedLocation(loaderResponse?.data?.data?.location[0]);
            formik.setFieldValue(
              "selectedLoactions",
              loaderResponse?.data?.data?.location[0].name
            );
          }
          setLocations(loaderResponse?.data?.data?.location);
          setCategoryes(loaderResponse?.data?.data?.category);
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
  }, []);
  useEffect(() => {
    if (pageRef.current) {
      getTotalCategorySales();
    }
  }, [paginationModel, sortModel]);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        setTableData([]);
        setTotalRow([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data?.message ===
          "Category Sale Report successfully exported."
        ) {
          const url = Constants.REACT_APP_API_URL + actionResponse.data.data;
          const link = document.createElement("a");
          link.href = url;
          // const generateURL =
          //   Constants.REACT_APP_API_URL + actionResponse.data.data;
          // const url = window.URL.createObjectURL(new Blob([generateURL]));
          // const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            Constants.DOWNLOAD,
            Constants.CATEGORY_SALES_EXCEL_FILENAME
          );
          document.body.appendChild(link);
          window.stop();
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REPORT_MODULE,
            alertDescription: actionResponse.data?.message,
          });
          myContext.handleLoading(false);
        } else {
          setTableData(actionResponse.data.data.data.data);
          setTotalCount(actionResponse.data.data.totalItems);
          // setTotalRow(actionResponse.data.data.data.totalRow);
          const totalRow = actionResponse.data.data?.totalRow || {};
          let data = [
            {
              id: 123,
              qty: totalRow.qty,
              free_qty: totalRow.free_qty,
              net_qty_sold: totalRow.net_qty_sold,
              total_sale_price: totalRow.total_sale_price,
              cost_of_sale: totalRow.cost_of_sale,
              avg_order: totalRow.avg_order,
              avg_price: totalRow.avg_price,
            },
          ];
          setTotalRow(data);
          setLoading(false);
          setButtonDisable(false);
        }
      } else {
        let actionData = actionResponse?.data;
        error({ actionData });
        setTableData([]);
        setTotalRow([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
      }
    }
  }, [actionResponse]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        null !== calendarRef.current &&
        calendarRef.current &&
        fromDateFocus
      ) {
        calendarRef.current.hide();
        setFromDateFocus(false);
      }
      if (
        null !== toDateCalendarRef.current &&
        toDateCalendarRef.current &&
        toDateFocus
      ) {
        toDateCalendarRef.current.hide();
        setToDateFocus(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [fromDateFocus, toDateFocus]);
  const handleExportReport = () => {
    if (formik.values.selectCategory.length > 0) {
      if (
        null != formik.values.selectedLoactions &&
        "" != formik.values.selectedLoactions
      ) {
        if (null != formik.values.fromDate && "" != formik.values.fromDate) {
          if (null != formik.values.toDate && "" != formik.values.toDate) {
            myContext.handleLoading(true);
            let data = {
              getreport: false,
              category: JSON.stringify(formik.values.selectCategory),
              location: JSON.stringify({
                label: selectedLocation.name,
                value: {
                  id: selectedLocation.id,
                },
              }),
              dateFrom: reportDateFormate(formik.values.fromDate),
              dateTo: reportDateFormate(formik.values.toDate),
              zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              type: "xls",
            };
            submit(data, {
              method: Constants.POST,
              path: Constants.TOTAL_CATEGORY_SALE,
            });
          } else {
            clearTableData(Constants.TO_DATE_IS_REQUIRED);
          }
        } else {
          clearTableData(Constants.FORM_DATE_IS_REQUIRED);
        }
      } else {
        clearTableData(Constants.LOCATION_IS_REQUIRED);
      }
    } else {
      clearTableData(Constants.PRODUCT_CATEGORY_REQUIRED);
    }
  };
  const formik = useFormik({
    initialValues: {
      selectedLoactions: "",
      selectCategory:
        undefined !== loaderResponse?.data?.data?.category &&
        null !== loaderResponse?.data?.data?.category
          ? loaderResponse?.data?.data?.category
          : [],
      fromDate: "",
      toDate: "",
    },
    validationSchema: Yup.object({
      selectCategory: Yup.array().min(1, Constants.PRODUCT_CATEGORY_REQUIRED),
      selectedLoactions: Yup.string().required(Constants.LOCATION_IS_REQUIRED),
      fromDate: Yup.string().required(Constants.FORM_DATE_IS_REQUIRED),
      toDate: Yup.string().required(Constants.TO_DATE_IS_REQUIRED),
    }),
    onSubmit: async (values) => {},
  });
  const clearTableData = (message) => {
    // clear table data when remove date and location
    setTableData([]);
    setTotalRow([]);
    setTotalCount(0);
    setLoading(false);
    setButtonDisable(false);
    myContext.handleLoading(false);
    addToast({
      alertStatus: Constants.TOAST_TYPE_WARNING,
      alertTitle: Constants.REPORT_MODULE,
      alertDescription: message,
    });
  };
  const getTotalCategorySales = () => {
    if (formik.values.selectCategory.length > 0) {
      if (
        null != formik.values.selectedLoactions &&
        "" != formik.values.selectedLoactions
      ) {
        if (null != formik.values.fromDate && "" != formik.values.fromDate) {
          if (null != formik.values.toDate && "" != formik.values.toDate) {
            setButtonDisable(true);
            setLoading(true);
            setShowTable(false);
            setApplyClicked(true);
            let data = {
              getreport: true,
              category: JSON.stringify(formik.values.selectCategory),
              location: JSON.stringify({
                label: selectedLocation.name,
                value: {
                  id: selectedLocation.id,
                },
              }),
              dateFrom: reportDateFormate(formik.values.fromDate),
              dateTo: reportDateFormate(formik.values.toDate),
              zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              query: JSON.stringify({
                limit: paginationModel.pageSize,
                page: paginationModel.page + 1,
                order:
                  sortModel.length > 0
                    ? sortModel[0].field
                    : sortRef.current[0].field,
                order_type:
                  sortModel.length > 0
                    ? sortModel[0].sort
                    : sortRef.current[0].sort,
              }),
            };
            submit(data, {
              method: Constants.POST,
              path: Constants.TOTAL_CATEGORY_SALE,
            });
          } else {
            clearTableData(Constants.TO_DATE_IS_REQUIRED);
          }
        } else {
          clearTableData(Constants.FORM_DATE_IS_REQUIRED);
        }
      } else {
        clearTableData(Constants.LOCATION_IS_REQUIRED);
      }
    } else {
      clearTableData(Constants.PRODUCT_CATEGORY_REQUIRED);
    }
  };

  const handleOptionSelect = (e) => {
    if (undefined !== e.value) {
      setSelectedLocation(e.value);
      formik.setFieldValue("selectedLoactions", e.value.name);
    }
  };
  const handleCategorySelect = (e) => {
    formik.setFieldValue("selectCategory", e.value);
  };
  const hadleFromDateSelect = (e) => {
    if (
      null !== formik.values.toDate &&
      null != formik.values.toDate &&
      "" !== formik.values.toDate
    ) {
      if (moment(formik.values.toDate).isBefore(e.value)) {
        formik.setFieldValue("fromDate", e.value);
        formik.setFieldValue("toDate", e.value);
        setFromDateView(e.value);
        setToDateView(e.value);
      } else {
        formik.setFieldValue("fromDate", e.value);
        setFromDateView(e.value);
      }
    } else {
      formik.setFieldValue("fromDate", e.value);
      setFromDateView(e.value);
    }
  };
  const modifiedData = tableData.map((data, i) => ({
    id: i,
    cat_name: data.cat_name,
    qty: data.qty,
    free_qty: data.free_qty,
    net_qty_sold: data.net_qty_sold,
    total_sale_price: data.total_sale_price,
    sale_percentage: data.sale_percentage.toFixed(2),
    cost_of_sale: data.cost_of_sale,
    avg_order: data.avg_order,
    avg_price: data.avg_price,
  }));
  const renderReportLayout = () => {
    return (
      <>
        <Stack
          className="card flex justify-content-center"
          flexDirection={isLarge ? "column" : "row"}
          spacing={4}
          width={isLarge ? "100% !important" : "auto"}
          justifyContent={"space-between"}
        >
          <Stack
            width={"100% !important"}
            flexDirection={isExtraSmall ? "column" : "row"}
            justifyContent={"space-between"}
            spacing={4}
          >
            <Box width={"100% !important"}>
              <PosDropDown
                id="selectedLoactions"
                options={locations}
                resetFilterOnHide={true}
                value={selectedLocation}
                onChange={handleOptionSelect}
                onBlur={(e) => {
                  formik.setFieldTouched("selectedLoactions");
                  formik.handleBlur(e); 
                }}
                // multiSelect={true}
                optionLabel="name"
                placeholder="Select Locations*"
                width={isExtraLarge ? "17rem" : "100%"}
                height={"2.5rem"}
                className="w-full md:w-20rem"
                // maxSelectedLabels={2}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.selectedLoactions &&
                formik.errors.selectedLoactions ? (
                  <span>{formik.errors.selectedLoactions}</span>
                ) : null}
              </Text>
            </Box>
            <Box width={"100% !important"}>
              <PosDropDown
                options={categoryes}
                value={formik.values.selectCategory}
                onChange={handleCategorySelect}
                onBlur={(e) => {
                  formik.setFieldTouched("selectCategory");
                  formik.handleBlur(e);
                }}
                multiSelect={true}
                optionLabel="name"
                placeholder="Select Categories*"
                width={isExtraLarge ? "17rem" : "100%"}
                height={"2.5rem"}
                className="w-full md:w-20rem"
                maxSelectedLabels={2}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.selectCategory &&
                formik.errors.selectCategory ? (
                  <span>{formik.errors.selectCategory}</span>
                ) : null}
              </Text>
            </Box>
          </Stack>
          <Stack
            width={"100% !important"}
            flexDirection={isExtraSmall ? "column" : "row"}
            justifyContent={"space-between"}
            spacing={4}
          >
            <Box width={"100% !important"}>
              <PosDatePicker
                id={"fromDate"}
                ref={calendarRef}
                value={formik.values.fromDate}
                label={"From Date*"}
                width={isExtraLarge ? "17rem" : "100%"}
                readOnlyInput={true}
                onFocus={() => {
                  if (
                    null !== toDateCalendarRef.current &&
                    toDateCalendarRef.current
                  ) {
                    toDateCalendarRef.current.hide();
                  }
                  setToDateFocus(false);
                  setTimeout(() => {
                    setFromDateFocus(true);
                  }, 1000);
                  formik.setFieldTouched("fromDate", true);
                }}
                handleSelectData={hadleFromDateSelect}
                yearRange={"2000:2030"}
                maxDate={dateTimeValue}
                viewDate={fromDateView}
                showButtonBar={true}
                showTime={true}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.fromDate && formik.errors.fromDate ? (
                  <span>{formik.errors.fromDate}</span>
                ) : null}
              </Text>
            </Box>
            <Box width={"100% !important"}>
              <PosDatePicker
                id={"toDate"}
                ref={toDateCalendarRef}
                value={formik.values.toDate}
                label={"To Date*"}
                width={isExtraLarge ? "17rem" : "100%"}
                readOnlyInput={true}
                onFocus={() => {
                  if (null !== calendarRef.current && calendarRef.current) {
                    calendarRef.current.hide();
                  }
                  setFromDateFocus(false);
                  setTimeout(() => {
                    setToDateFocus(true);
                  }, 1000);
                  formik.setFieldTouched("toDate", true);
                }}
                handleSelectData={(e) => {
                  formik.setFieldValue("toDate", e.value);
                  setToDateView(e.value);
                }}
                yearRange={"2000:2030"}
                minDate={
                  null !== formik.values.fromDate &&
                  "" !== formik.values.fromDate
                    ? formik.values.fromDate
                    : dateTimeValue
                }
                maxDate={dateTimeValue}
                viewDate={toDateView}
                showButtonBar={true}
                showTime={true}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.toDate && formik.errors.toDate ? (
                  <span>{formik.errors.toDate}</span>
                ) : null}
              </Text>
            </Box>
          </Stack>
        </Stack>
      </>
    );
  };
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Reports", "Total Daily Category Sales"]}
          breadCrumTitle={"Total Daily Category Sales"}
        />
        <Spacer />
        <ButtonGroup gap="2" direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              // pass param data in tax list page
              props.navigate(Constants.REPORT_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
          {totalcount > 0 && (
            <PosIconButton
              name={Constants.EXPORT_BUTTON}
              onClick={handleExportReport}
              exportIcon={true}
              width={"7.5rem"}
            />
          )}
        </ButtonGroup>
      </Flex>
      <Box
        borderRadius="0.63rem"
        bg="white"
        // p={2}
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Flex
          direction={isLarge ? "column" : "row"}
          justifyContent={{ base: "center", sm: "space-between" }}
        >
          <Stack
            gap={4}
            flex={1}
            flexShrink={"0"}
            direction={"column"}
            m={4}
            alignItems={"flex-start"}
          >
            {renderReportLayout()}
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={isLarge ? "flex-start" : "flex-end"}
            alignItems={"flex-start"}
            pl={isLarge ? 4 : 0}
            pt={isMeduim ? 0 : 4}
          >
            <PosFormButton
              isDisabled={applyClicked ? false : true}
              onClick={() => {
                pageRef.current = false;
                setSortModel([
                  {
                    field: "cat_name",
                    sort: "desc",
                  },
                ]);
                setPaginationModel({
                  page: 0,
                  pageSize: 25,
                });
                setSelectedLocation([]);
                formik.setFieldValue("selectCategory", []);
                setTableData([]);
                setTotalRow([]);
                setShowTable(true);
                setApplyClicked(true);
                setTotalCount(0);
                formik.resetForm();
              }}
              buttonText={"Reset"}
              ghostButton={true}
            />
            <PosFormButton
              buttonsubmit={"Apply"}
              SubmitButton={true}
              isDisabled={
                formik.values.selectCategory?.length === 0 ||
                formik.values.fromDate === "" ||
                formik.values.toDate === "" ||
                formik.errors.selectedLoactions ||
                formik.errors.selectCategory ||
                formik.errors.fromDate ||
                formik.errors.toDate ||
                buttonDisable
              }
              onClick={getTotalCategorySales}
            />
          </Stack>
        </Flex>
        <Box p={4}>
          {showTable ? null : loading ? (
            <PosProgress />
          ) : totalcount > 0 ? (
            <Flex direction={"column"} gap={4}>
            <PosDataGridTable
              columns={columnNames}
              rows={modifiedData}
              totalcount={totalcount}
              // noAction={true}
              columnVisibilityModel={olColumnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setOlColumnVisibilityModel(newModel)
              }
              paginationModel={paginationModel}
              paginationMode="server"
              sortingMode="server"
              onPaginationModelChange={(newPageModel) => {
                pageRef.current = true;
                setPaginationModel(newPageModel);
              }}
              sortModel={sortModel}
              onSortModelChange={(newSortModel) => {
                pageRef.current = true;
                sortRef.current = sortModel;
                setSortModel(newSortModel);
              }}
              fileName={Constants.TOTAL_CATEGORY_SALE_CSV}
              slots={{
                toolbar: GridToolbar,
              }}
            />
            {/* <PosDataGridTable
                columns={totalColumnName}
                columnVisibilityModel={totalColumnVisibilityModel}
                rows={totalRow}
                totalcount={totalcount}
                rowId={"totalCategorySalesData"}
                hideFooter={true}
              /> */}
            </Flex>
          ) : (
            <PosNoDataFound
              title={"There are no Total Daily Category Sales."}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default WithRouter(TotalCategorySales);
