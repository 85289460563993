import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Heading,
  Image,
  Link,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  Spacer,
  Stack,
  Text,
  Textarea,
  Wrap,
  WrapItem,
  useBreakpointValue,
  useDisclosure,
  useMediaQuery,
  useRadio,
  useRadioGroup,
} from "@chakra-ui/react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import { useFormik } from "formik";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { SelectButton } from "primereact/selectbutton";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useSubmit,
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import smsImg from "../../../assets/images/chat_10419161.png";
import emailImg from "../../../assets/images/chat_7246831.png";
import tarCampImg from "../../../assets/images/coupon_87571071.png";
import genCampImg from "../../../assets/images/digital-marketing_73075891.png";
import emailTempBoxImg from "../../../assets/images/ic_baseline-email.svg";
import couponOfferImg from "../../../assets/images/image_1363.png";
import smsEmailImg from "../../../assets/images/messages_14593651.png";
import {
  AppContext,
  PosAlert,
  PosBreadCrumb,
  PosChipsInput,
  PosDatePicker,
  PosDialogAlert,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosInput,
  PosLable,
  PosTable,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { RadioButton } from "primereact/radiobutton";
import errorIcon from "../../../assets/images/Ellipse3.svg";
import warnignIcon from "../../../assets/images/Ellipse2.svg";
import "../../../assets/redactor/email/email";
import "../../../assets/redactor/plugins/ai/ai";
import "../../../assets/redactor/plugins/alignment/alignment";
import "../../../assets/redactor/plugins/blockbackground/blockbackground";
import "../../../assets/redactor/plugins/blockborder/blockborder";
import "../../../assets/redactor/plugins/blockclass/blockclass";
import "../../../assets/redactor/plugins/blockcode/blockcode";
import "../../../assets/redactor/plugins/blockcolor/blockcolor";
import "../../../assets/redactor/plugins/blockfontsize/blockfontsize";
import "../../../assets/redactor/plugins/blockid/blockid";
import "../../../assets/redactor/plugins/blockspacing/blockspacing";
import "../../../assets/redactor/plugins/definedlinks/definedlinks";
import "../../../assets/redactor/plugins/emoji/emoji";
import "../../../assets/redactor/plugins/fontcolor/fontcolor";
import "../../../assets/redactor/plugins/fontfamily/fontfamily";
import "../../../assets/redactor/plugins/fontsize/fontsize";
import "../../../assets/redactor/plugins/fullscreen/fullscreen";
import "../../../assets/redactor/plugins/icons/icons";
import "../../../assets/redactor/plugins/imageresize/imageresize";
import "../../../assets/redactor/plugins/mergetag/mergetag";
import "../../../assets/redactor/plugins/snippets/snippets";
import "../../../assets/redactor/plugins/textdirection/textdirection";
import "../../../assets/redactor/plugins/variable/variable";
import Redactor from "../../../assets/redactor/redactor";
import "../../../assets/redactor/redactor.css";
import "../../../assets/redactor/redactor.min.css";
import { onlyDateFormate } from "../../../helpers/utils/Utils";
import { WithRouter } from "../../../navigators/WithRouter";
import {
  campaignCreate,
  checkSMSAvailability,
  checkEmailAvailability,
  getSmsPlanData,
  sendTestEmail,
  updateSMSPlan,
} from "./CampaignService";

const RadioCard = ({ children, ...props }) => {
  const { getInputProps, getRadioProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius={props.borderRadius}
        boxShadow="md"
        _checked={{
          // bg: 'teal.600',
          borderWidth: "2px",
          color: "white",
          borderColor: "#5881FE",
          borderRadius: props.borderRadius,
        }}
        _disabled={{
          opacity: 0.4,
        }}
        maxW={props.maxWidth}
        flexDirection={"column"}
      >
        {children}
      </Box>
    </Box>
  );
};
const CampaignCreate = (props) => {
  const [isMobile] = useMediaQuery("(max-width: 992px)");
  const [isScreenSize768] = useMediaQuery("(max-width: 768px)");
  const [isScreenSize576] = useMediaQuery("(max-width: 576px)");
  const [isScreenSize480] = useMediaQuery("(max-width: 480px)");
  const { addToast } = PosTostMessage();
  const submit = useSubmit();
  const actionResponse = useActionData();

  const loaderResponse = useLoaderData();
  const effectRun = useRef(true);
  const effectTemplate = useRef(true);
  const { error } = PosErrorHook();
  const myContext = useContext(AppContext);
  const [activePage, setActivePage] = useState(Constants.MARKETING_TYPE_SCREEN); // Assuming you have activeStep state
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [maxEmailSendDate, setMaxEmailSendDate] = useState("");
  const [couponData, setCouponData] = useState([]);
  const [selectedCouponDetails, setSelectedCouponDetails] = useState({});
  const [selectedCouponsArray, setSelectedCouponsArray] = useState([]);
  const [selectedCouponProdViewDetails, setSelectedCouponProdViewDetails] =
    useState([]);
  const [predefinedEmailTemplates, setPredefinedEmailTemplate] = useState([
    {
      name: "Blank Page",
      value: "",
      subject_line: "",
      template_html: "",
      template_re_html: "",
    },
  ]);
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState("");
  const [selectedEmailTemplateHtml, setSelectedEmailTemplateHtml] =
    useState("");
  const [businessLogo, setBusinessLogo] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [selectedEmailAddToSendTestEmail, setSelectedEmailAddToSendTestEmail] =
    useState([]);
  const [validationErrors, setValidationErrors] = useState("");
  const [segmentData, setSegmentData] = useState([]);
  const [smsPlans, setSmsPlans] = useState([]);
  const [perSmsChargeObject, setPerSmsChargeObject] = useState({});
  const [additionalSmsOptions, setAdditionalSmsOptions] = useState([
    { name: "Upgrade the subscription plan", key: "upgrade_plan" },
    { name: "Charge additional SMS as a $xyz / SMS", key: "per_sms_charge" },
  ]);
  const [customerSmsPlanSubscribed, setCustomerSmsPlanSubscribed] = useState(0);
  const [scheduleNextMonth, setScheduleNextMonth] = useState(0);
  const [smsEligibility, setSmsEligibility] = useState(0);
  const [additionalSms, setAdditionalSms] = useState(0);
  const [numberOfCampUser, setNumberOfCampUser] = useState(0);
  const [numberOfCampUserEmail, setNumberOfCampUserEmail] = useState(0);
  const [currentPlanForNextMonth, setCurrentPlanForNextMonth] = useState({});
  const [maxSmsPlanSelection, setMaxSmsPlanSelection] = useState(0);
  const [customerCancelPlanSubscription, setCustomerCancelPlanSubscription] =
    useState(0);
  const [smsPlanListToDisplayInModel, setSmsPlanListToDisplayInModel] =
    useState([]);
  const [selectedPlanIndex, setSelectedPlanIndex] = useState("");
  const [selectedSmsPlan, setSelectedSmsPlan] = useState({});
  const [selectedUpgradePlanOption, setSelectedUpgradePlanOption] =
    useState("");
  const [smsPlanModelLabel, setSmsPlanModelLabel] = useState("");
  const [selectSegmentFlag, setSelectSegmentFlag] = useState(0);
  const [customerCardDtlsExist, setCustomerCardDtlsExist] = useState(1);
  const [newCustomerWithNoPlan, setNewCustomerWithNoPlan] = useState(0);
  const [maxUsageValidationError, setMaxUsageValidationError] = useState("");
  const [couponMaxUsageValue, setCouponMaxUsageValue] = useState("");
  const [subjectLineValue, setSubjectLineValue] = useState("");
  const [subjectLineValidationError, setSubjectLineValidationError] =
    useState("");
  const [smsTextValue, setSmsTextValue] = useState("");
  const [smsTextValidationError, setSmsTextValidationError] = useState("");
  const [smsLeftInCurrentMonthPlanSub, setSmsLeftInCurrentMonthPlanSub] =
    useState(0);
  const [msResponseNotGetFlag, setMsResponseNotGetFlag] = useState(1);
  const [smsNumberExist, setSmsNumberExist] = useState(0);
  const editorRef = useRef("");
  const htmlRef = useRef("");
  const reHtmlRef = useRef("");
  const [serverUrl, setServerUrl] = useState("");
  const [allEmailImages, setAllEmailImages] = useState([]);
  const stateData = useLocation();
  const [paramData, setParamData] = useState(stateData.state); // store in state
  const [buttonDisable, setButtonDisable] = useState(false);
  const [smsDistribution, setSmsDistribution] = useState([]);
  const [smsLastDate, setSmsLastDate] = useState("");
  const [smsLastMinDate, setSmsLastMinDate] = useState("");
  const [smsPerDay, setSmsPerDay] = useState(0);
  const [businessRoute, setBusinessRoute] = useState("");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const modalSize = useBreakpointValue({
    base: "half",
    sm: "2md",
    md: "2lg",
    lg: "2xl",
    xl: "2xl",
  });
  const cancelRef = React.useRef();
  const {
    isOpen: isCoupFormModelOpen,
    onOpen: onCoupFormModelOpen,
    onClose: onCoupFormModelClose,
  } = useDisclosure();

  const {
    isOpen: isCoupProdViewModelOpen,
    onOpen: onCoupProdViewModelOpen,
    onClose: onCoupProdViewModelClose,
  } = useDisclosure();

  const {
    isOpen: isSendTestEmailModelOpen,
    onOpen: onSendTestEmailModelOpen,
    onClose: onSendTestEmailModelClose,
  } = useDisclosure();

  const {
    isOpen: isSmsPlanModelOpen,
    onOpen: onSmsPlanModelOpen,
    onClose: onSmsPlanModelClose,
  } = useDisclosure();

  const {
    isOpen: isSmsNumberNotFoundAlertOpen,
    onOpen: onSmsNumberNotFoundAlertOpen,
    onClose: onSmsNumberNotFoundAlertClose,
  } = useDisclosure();

  const scheduledOptions = [
    { label: "Now", value: "scheduled_now" },
    { label: "Later", value: "scheduled_later" },
  ];
  const [emailRestriction, setEmailRestriction] = useState(0);
  const [smsRestriction, setSMSRestriction] = useState(0);
  const [cardDetailsError, setSMSCardDetailsError] = useState(0);
  const [templateName, setTemplateName] = useState("");

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          if (
            loaderResponse?.response.data?.data?.verify_email_not_found == 1
          ) {
            myContext.handleLoading(false);
            let actionData = loaderResponse;
          } else {
            let actionData = loaderResponse;
            error({ actionData });
            myContext.handleLoading(false);
            setButtonDisable(false);
          }
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          myContext.handleLoading(false);
          setButtonDisable(false);
          setMsResponseNotGetFlag(0);
          if (loaderResponse.data.data.new_customer_with_no_plan == 1) {
            setNewCustomerWithNoPlan(1);
          }
          if (loaderResponse.data.data.customer_card_details.length == 0) {
            setCustomerCardDtlsExist(0);
          }
          setSmsLeftInCurrentMonthPlanSub(
            loaderResponse.data.data.sms_left_in_current_month
          );
          formik.setFieldValue(
            "smsLeftInCurrentMonthPlanSub",
            loaderResponse.data.data.sms_left_in_current_month
          );
          formik.setFieldValue("scheduled_type", scheduledOptions[0].value);
          setCouponData(loaderResponse.data.data.data);
          setSegmentData(loaderResponse.data.data.segmentsData);
          const predefinedTemplateArray = [];
          loaderResponse.data.data.emailTemplateData.forEach((value, key) => {
            const emailTemplatePayload = {
              name: value.name,
              value: value.template_re_html,
              subject_line: value.subject_line,
              template_html: value.template_html,
              template_re_html: value.template_re_html,
            };
            predefinedTemplateArray.push(emailTemplatePayload);
          });
          setServerUrl(loaderResponse.data.data.server_url);
          setPredefinedEmailTemplate(
            predefinedEmailTemplates.concat(predefinedTemplateArray)
          );
          if (
            null != loaderResponse.data.data.business_logo &&
            "" != loaderResponse.data.data.business_logo
          ) {
            setBusinessLogo(loaderResponse.data.data.business_logo);
          }
          if (loaderResponse.data.data.business_name_exist == 1) {
            setBusinessName(loaderResponse.data.data.auth_user.business_name);
          }
          if (
            undefined != loaderResponse.data.data.business_name_route &&
            null != loaderResponse.data.data.business_name_route &&
            "" != loaderResponse.data.data.business_name_route
          ) {
            setBusinessRoute(
              loaderResponse.data.data.business_name_route
                .sys_global_settings_category_id
            );
          }
          if (
            null != loaderResponse.data.data.twilio_phone_number &&
            "" != loaderResponse.data.data.twilio_phone_number
          ) {
            setSmsNumberExist(1);
          }
          setCustomerCancelPlanSubscription(
            loaderResponse.data.data.customer_cancel_plan_subscription
          );
        } else {
          let actionData = loaderResponse;
          error({ actionData });
        }
      }
      // return () => {
      effectRun.current = false;
      // };
    }
  }, [loaderResponse]);

  useEffect(() => {
    if (
      activePage === Constants.EMAIL_DESIGN_SCREEN &&
      effectTemplate.current === true
    ) {
      let designEmailInstance = Redactor("#designEmail", {
        theme: "light",
        plugins: [
          "email",
          "snippets",
          "ai",
          "alignment",
          "blockcode",
          "blockclass",
          "blockid",
          "blockcolor",
          "blockbackground",
          "blockborder",
          "blockfontsize",
          "fontcolor",
          "fontsize",
          "fontfamily",
          "blockspacing",
          "imageresize",
          "definedlinks",
          "icons",
          "fullscreen",
          "mergetag",
          "textdirection",
        ],
        pathbar: true,
        ai: {
          text: {
            url: serverUrl.replace(":3000", ":8000") + "api/v1/redactor/email",
            endpoint: "https://api.openai.com/v1/chat/completions",
            model: "gpt-4o",
            stream: true,
            onError: function (error) {
              console.error("AI Plugin Error:", error);
            },
          },
        },
        image: {
          upload:
            serverUrl.replace(":3000", ":8000") +
            "api/v1/mktg-campaign-image-upload",
          width: true,
        },
        definedlinks: {
          items: [
            { name: "Select...", url: false },
            { name: "Unsubscribe", url: "email_unsubscibe" },
            { name: "View Coupon", url: "view_coupon" },
          ],
        },
      });
      if (undefined != designEmailInstance) {
        var selectedTemplate = selectedEmailTemplate;
        if (null != businessLogo && "" != businessLogo) {
          selectedTemplate = selectedTemplate.replace(
            Constants.PREDEFINED_BUSINESS_LOGO,
            businessLogo
          );
        }
        if (null != businessName && "" != businessName) {
          selectedTemplate = selectedTemplate.replace(
            Constants.PREDEFINED_BUSINESS_NAME,
            businessName
          );
        }
        designEmailInstance.editor.setContent({
          html: selectedTemplate,
        });
        setSelectedEmailTemplateHtml(designEmailInstance.editor.getEmail(true));
        setSelectedEmailTemplate(designEmailInstance.editor.getContent(true));
      }
      designEmailInstance.editor.setFocus("start");
      // designEmailInstance.image.opts.image.upload = serverUrl.replace(":3000", ":8000") + "api/v1/mktg-campaign-image-upload"; //Uploads an image to the editor template.
      editorRef.current = designEmailInstance;
      effectTemplate.current = false;
    }
  }, [selectedEmailTemplate, activePage]);

  const marketingOptions = [
    {
      type: "SMS",
      value: Constants.SMS,
      description: "Send only SMS to your customers",
      imageName: smsImg,
    },
    {
      type: "Email",
      value: Constants.EMAIL,
      description: "Send only Email to your customers",
      imageName: emailImg,
    },
    {
      type: "Email & SMS",
      value: Constants.SMS_EMAIL_BOTH,
      description: "Send both SMS & Email to your customers",
      imageName: smsEmailImg,
    },
  ];

  const campaignOptions = [
    {
      type: "Generic Campaign",
      value: Constants.GENERIC,
      description: "Connect and send messages to your customers",
      imageName: genCampImg,
    },
    {
      type: "Coupon Campaign",
      value: Constants.TARGETED,
      description:
        "Send your customers Product Coupons they can enjoy, use to purchase items from your store",
      imageName: tarCampImg,
    },
  ];

  const audienceType = [
    {
      label: "Select All User",
      value: "select_all",
      description: "All Users will receive this campaign",
    },
    {
      label: "Select Segment",
      value: "select_segment",
      description: "Only eligible user(s) you select",
    },
  ];

  const {
    getRootProps: getMarketingTypeRootProps,
    getRadioProps: getMarketingTypeRadioProps,
  } = useRadioGroup({
    name: "marketing_type",
    defaultValue: "",
    onChange: (value) => {
      formik.setFieldValue("marketing_type", "");
      if (
        (Constants.EMAIL == value || Constants.SMS_EMAIL_BOTH == value) &&
        loaderResponse.data?.data?.verify_email_not_found
      ) {
        setEmailRestriction(1);
        formik.setFieldValue("marketing_type", value);
      } else {
        setEmailRestriction(0);
        formik.setFieldValue("marketing_type", value);
      }
      if (
        ((customerCardDtlsExist == 1 && customerCancelPlanSubscription == 1) ||
          newCustomerWithNoPlan == 1) &&
        (Constants.SMS == value || Constants.SMS_EMAIL_BOTH == value)
      ) {
        formik.setFieldValue("marketing_type", value);
        setSMSRestriction(1);
      } else if (
        customerCardDtlsExist == 0 &&
        customerCancelPlanSubscription == 0 &&
        (Constants.SMS == value || Constants.SMS_EMAIL_BOTH == value)
      ) {
        formik.setFieldValue("marketing_type", value);
        setSMSCardDetailsError(1);
        setSMSRestriction(0);
      } else {
        setSMSRestriction(0);
        setSMSCardDetailsError(0);
        formik.setFieldValue("marketing_type", value);
      }
    },
  });
  const marketingTypeGroup = getMarketingTypeRootProps();

  const {
    getRootProps: getCampaignTypeRootProps,
    getRadioProps: getCampaignTypeRadioProps,
  } = useRadioGroup({
    name: "campaign_type",
    defaultValue: "",
    onChange: (value) => {
      formik.setFieldValue("campaign_type", value);
    },
  });
  // validation of email
  const openSendEmailModal = () => {
    var app = Redactor("#designEmail");
    let empty = app.editor.isEmpty();
    var temp_html = app.editor.getEmail(true);
    var temp_rehtml = app.editor.getHtml(true);
    var temp_content = app.editor.getContent(true);
    setSelectedEmailTemplateHtml(temp_html);
    setSelectedEmailTemplate(temp_content);
    if (
      empty == true ||
      "" == temp_html ||
      null == temp_html ||
      "" == temp_content ||
      null == temp_content
    ) {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.CAMPAIGN_MODULE,
        alertDescription: Constants.EMAIL_TEMPLATE_EMPTY,
      });
    } else {
      onSendTestEmailModelOpen();
    }
  };

  const campaignTypeGroup = getCampaignTypeRootProps();

  const {
    getRootProps: getEmailTemplateRootProps,
    getRadioProps: getEmailTemplateRadioProps,
    setValue: setEmailTemplateValue,
  } = useRadioGroup({
    name: "email_template",
    defaultValue: "",
    onChange: (value) => {
      let selectedEmailTemplateObject = predefinedEmailTemplates.find(
        (o) => o.value == value
      );
      setSelectedEmailTemplate(value);
      if (
        selectedEmailTemplateObject &&
        undefined != selectedEmailTemplateObject.subject_line &&
        null != selectedEmailTemplateObject.subject_line &&
        "" != selectedEmailTemplateObject.subject_line
      ) {
        setSubjectLineValue(selectedEmailTemplateObject.subject_line.trim());
        setTemplateName(selectedEmailTemplateObject.name);
      } else {
        setSubjectLineValue("");
        setTemplateName("Blank Page");
      }
    },
  });
  const emailTemplateGroup = getEmailTemplateRootProps();

  const {
    getRootProps: getAudienceTypeRootProps,
    getRadioProps: getAudienceTypeRadioProps,
    setValue: setAudienceValue,
  } = useRadioGroup({
    name: "select_audience",
    defaultValue: "",
    onChange: (selectedAudienceType) => {
      formik.setFieldValue("select_audience", selectedAudienceType);
      if (selectedAudienceType == Constants.SELECT_SEGMENT) {
        setSelectedUpgradePlanOption("");
        setSelectSegmentFlag(1);
        if (
          undefined != formik.values.selectedSegments &&
          null != formik.values.selectedSegments &&
          "" != formik.values.selectedSegments &&
          formik.values.selectedSegments.length > 0 &&
          maxSmsPlanSelection == 1
        ) {
          setMaxSmsPlanSelection(1);
        } else {
          setMaxSmsPlanSelection(0);
        }
        formik.setFieldValue("per_sms_charge_flag", 0);
        formik.setFieldValue("per_sms_charge", null);
        setNumberOfCampUser(0);
        setNumberOfCampUserEmail(0);
        setAdditionalSms(0);
      } else {
        setSelectSegmentFlag(0);
        setNumberOfCampUserEmail(0);
        formik.setFieldValue("selectedSegments", []);
        if (formik.values.marketing_type != Constants.EMAIL) {
          checkCampaignSMSAvailability(selectedAudienceType, [], formik.values.marketing_type);
        }
        else {
          checkCampaignEmailAvailability(selectedAudienceType, [], formik.values.marketing_type);
        }
      }
    },
  });
  const audienceTypeGroup = getAudienceTypeRootProps();

  const couponDetailColumns = [
    {
      columnNames: "Coupon Id",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Coupon Name",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Coupon Type",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Product Details",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Discount Type",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Discount Value",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Minimum Amount",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Max Quantity of Product Per Coupon",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Max Usage Per Reward Customer",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Delete",
      sortName: "",
      width: "",
    },
  ];

  const couponProductViewColumns = [
    {
      columnNames: "Id",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Name",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Barcode",
      sortName: "",
      width: "",
    },
  ];

  const smsPlanTableColumns = [
    {
      columnNames: "Name",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Number Of SMS",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Price",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Select",
      sortName: "",
      width: "",
    },
  ];

  const handleNextBtnClickRedirection = (currentPage) => {
    if (
      formik.values.marketing_type == Constants.SMS &&
      formik.values.campaign_type == Constants.GENERIC
    ) {
      if (currentPage == Constants.BASIC_DETAILS_SCREEN) {
        setActivePage(Constants.SMS_DESIGN_SCREEN);
      } else if (currentPage == Constants.SMS_DESIGN_SCREEN) {
        setActivePage(Constants.AUDIENCE_SCREEN);
      }
    } else if (
      formik.values.marketing_type == Constants.SMS_EMAIL_BOTH &&
      formik.values.campaign_type == Constants.GENERIC
    ) {
      if (currentPage == Constants.BASIC_DETAILS_SCREEN) {
        setActivePage(Constants.EMAIL_TEMPLATE_SELECTION_SCREEN);
      } else if (currentPage == Constants.EMAIL_TEMPLATE_SELECTION_SCREEN) {
        updateEmailTemaplteData();
        setActivePage(Constants.EMAIL_DESIGN_SCREEN);
      } else if (currentPage == Constants.EMAIL_DESIGN_SCREEN) {
        var app = Redactor("#designEmail");
        let empty = app.editor.isEmpty();
        var temp_rehtml = app.editor.getEmail(true);
        var temp_content = app.editor.getContent(true);
        if (
          empty == false &&
          "" != temp_rehtml &&
          null != temp_rehtml &&
          "" != temp_content &&
          null != temp_content
        ) {
          updateEmailTemaplteData();
          setActivePage(Constants.SMS_DESIGN_SCREEN);
        } else {
          addToast({
            alertStatus: Constants.TOAST_TYPE_WARNING,
            alertTitle: Constants.CAMPAIGN_MODULE,
            alertDescription: Constants.EMAIL_TEMPLATE_EMPTY,
          });
        }
      } else if (currentPage == Constants.SMS_DESIGN_SCREEN) {
        setActivePage(Constants.AUDIENCE_SCREEN);
      }
    } else if (
      formik.values.marketing_type == Constants.SMS &&
      formik.values.campaign_type == Constants.TARGETED
    ) {
      if (currentPage == Constants.BASIC_DETAILS_SCREEN) {
        setActivePage(Constants.AUDIENCE_SCREEN);
      }
    } else {
      if (currentPage == Constants.BASIC_DETAILS_SCREEN) {
        setActivePage(Constants.EMAIL_TEMPLATE_SELECTION_SCREEN);
      } else if (currentPage == Constants.EMAIL_TEMPLATE_SELECTION_SCREEN) {
        updateEmailTemaplteData();
        setActivePage(Constants.EMAIL_DESIGN_SCREEN);
      } else if (currentPage == Constants.EMAIL_DESIGN_SCREEN) {
        var app = Redactor("#designEmail");
        let empty = app.editor.isEmpty();
        var temp_rehtml = app.editor.getEmail(true);
        var temp_content = app.editor.getContent(true);
        if (
          empty == false &&
          "" != temp_rehtml &&
          null != temp_rehtml &&
          "" != temp_content &&
          null != temp_content
        ) {
          updateEmailTemaplteData();
          setActivePage(Constants.AUDIENCE_SCREEN);
        } else {
          addToast({
            alertStatus: Constants.TOAST_TYPE_WARNING,
            alertTitle: Constants.CAMPAIGN_MODULE,
            alertDescription: Constants.EMAIL_TEMPLATE_EMPTY,
          });
        }
      }
    }
  };

  const updateEmailTemaplteData = () => {
    var app = Redactor("#designEmail");
    if (app != undefined && null != app && "" != app) {
      var reHTML = app.editor.getContent(true);
      var HTML = app.editor.getEmail(true);
      htmlRef.current = HTML;
      reHtmlRef.current = reHTML;
      setSelectedEmailTemplate(reHtmlRef.current);
      setSelectedEmailTemplateHtml(htmlRef.current);
    } else {
      effectTemplate.current = true;
    }
  };

  const handlePreviousBtnClickRedirection = (currentPage) => {
    if (
      formik.values.marketing_type == Constants.SMS &&
      formik.values.campaign_type == Constants.GENERIC
    ) {
      if (currentPage == Constants.AUDIENCE_SCREEN) {
        setActivePage(Constants.SMS_DESIGN_SCREEN);
      } else if (currentPage == Constants.SMS_DESIGN_SCREEN) {
        setActivePage(Constants.BASIC_DETAILS_SCREEN);
      }
    } else if (
      formik.values.marketing_type == Constants.SMS_EMAIL_BOTH &&
      formik.values.campaign_type == Constants.GENERIC
    ) {
      if (currentPage == Constants.AUDIENCE_SCREEN) {
        setActivePage(Constants.SMS_DESIGN_SCREEN);
      } else if (currentPage == Constants.SMS_DESIGN_SCREEN) {
        updateEmailTemaplteData();
        setActivePage(Constants.EMAIL_DESIGN_SCREEN);
      } else if (currentPage == Constants.EMAIL_DESIGN_SCREEN) {
        updateEmailTemaplteData();
        setActivePage(Constants.EMAIL_TEMPLATE_SELECTION_SCREEN);
      } else if (currentPage == Constants.EMAIL_TEMPLATE_SELECTION_SCREEN) {
        setActivePage(Constants.BASIC_DETAILS_SCREEN);
      }
    } else if (
      formik.values.marketing_type == Constants.SMS &&
      formik.values.campaign_type == Constants.TARGETED
    ) {
      if (currentPage == Constants.AUDIENCE_SCREEN) {
        setActivePage(Constants.BASIC_DETAILS_SCREEN);
      }
    } else {
      if (currentPage == Constants.AUDIENCE_SCREEN) {
        updateEmailTemaplteData();
        setActivePage(Constants.EMAIL_DESIGN_SCREEN);
      } else if (currentPage == Constants.EMAIL_DESIGN_SCREEN) {
        updateEmailTemaplteData();
        setActivePage(Constants.AUDIENCE_SCREEN);
        setActivePage(Constants.EMAIL_TEMPLATE_SELECTION_SCREEN);
      } else if (currentPage == Constants.EMAIL_TEMPLATE_SELECTION_SCREEN) {
        setActivePage(Constants.BASIC_DETAILS_SCREEN);
      }
    }
  };

  function goToCampaignTypeScreen() {
    const previousMarketingType = formik.values.marketing_type;
    const previousCampaignType = formik.values.campaign_type;
    const smsLeftInCurrentMonthPlanSub = formik.values.smsLeftInCurrentMonthPlanSub;
    formik.resetForm();
    setAudienceValue("");
    setEmailTemplateValue("");
    setSmsLastMinDate("");
    setSmsLastDate("");
    formik.setFieldValue("marketing_type", previousMarketingType);
    formik.setFieldValue("campaign_type", previousCampaignType);
    formik.setFieldValue("scheduled_type", scheduledOptions[0].value);
    formik.setFieldValue(
      "smsLeftInCurrentMonthPlanSub",
      smsLeftInCurrentMonthPlanSub
    );
    setMaxEmailSendDate("");
    setSelectedCouponDetails({});
    setSelectedCouponsArray([]);
    setSelectedCouponProdViewDetails([]);
    setSelectedEmailTemplate("");
    setSelectedEmailTemplateHtml("");
    setSelectedEmailAddToSendTestEmail("");
    setValidationErrors("");
    setPerSmsChargeObject({});
    setAdditionalSmsOptions([
      { name: "Upgrade the subscription plan", key: "upgrade_plan" },
      { name: "Charge additional SMS as a $xyz / SMS", key: "per_sms_charge" },
    ]);
    setCustomerSmsPlanSubscribed(0);
    setScheduleNextMonth(0);
    setSmsEligibility(0);
    setAdditionalSms(0);
    setNumberOfCampUser(0);
    setNumberOfCampUserEmail(0);
    setCurrentPlanForNextMonth({});
    setMaxSmsPlanSelection(0);
    setSmsPlanListToDisplayInModel([]);
    setSelectedPlanIndex("");
    setSelectedSmsPlan({});
    setSelectedUpgradePlanOption("");
    setSmsPlanModelLabel("");
    setSelectSegmentFlag(0);
    setMaxUsageValidationError("");
    setCouponMaxUsageValue("");
    setSubjectLineValue("");
    setTemplateName("Blank Page");
    setSubjectLineValidationError("");
    setSmsTextValue("");
    setSmsTextValidationError("");
    onClose();
    setActivePage(Constants.CAMPAIGN_TYPE_SCREEN);
  }

  function onChangeDate() {
    var startDate = moment(formik.values.start_date).format("YYYY-MM-DD");
    var endDate = moment(formik.values.end_date).format("YYYY-MM-DD");
    var dateTime = moment(dateTimeValue).format("YYYY-MM-DD");
    if (startDate == dateTime) {
      if (dateTime > formik.values.start_date) {
        formik.setFieldValue("start_date", dateTimeValue);
      } else {
        var currentMinute = moment(dateTimeValue, "HH:mm:ss").minutes();
        var startDateMinute = moment(
          formik.values.start_date,
          "HH:mm:ss"
        ).minutes();
        if (currentMinute > startDateMinute) {
          formik.setFieldValue(
            "start_date",
            new Date(
              moment(formik.values.start_date).set({ minute: currentMinute })
            )
          );
        }
      }
    }
    if (formik.values.start_date > formik.values.end_date) {
      formik.values.end_date = "";
      formik.setFieldTouched("end_date", true);
    }
    if (
      null != formik.values.start_date &&
      "" != formik.values.start_date &&
      null != smsLastDate &&
      "" != smsLastDate
    ) {
      if (startDate > moment(smsLastDate).format("YYYY-MM-DD")) {
        setSmsLastMinDate(formik.values.start_date);
      } else {
        setSmsLastMinDate(
          new Date(
            moment(smsLastDate).add(1, "days").format("YYYY-MM-DD HH:mm:ss")
          )
        );
      }
    }
    if (
      null != formik.values.end_date &&
      "" != formik.values.end_date &&
      formik.values.scheduled_start_date_time > formik.values.end_date
    ) {
      formik.setFieldValue("scheduled_start_date_time", "");
    }
    if (formik.values.marketing_type != Constants.EMAIL) {
      if (formik.values.end_date != null && formik.values.end_date != "") {
        if (formik.values.start_date > formik.values.end_date) {
          formik.setFieldValue("end_date", formik.values.start_date);
          var endDateValue = formik.values.start_date;
        } else {
          var endDateValue = formik.values.end_date;
        }
        var endDateDiffFromCrtDate = moment(endDateValue).diff(
          moment(),
          "days"
        );
        var actSendDateDiffFromCrtDate = moment()
          .add(1, "M")
          .endOf("month")
          .subtract(1, "days")
          .diff(moment(), "days");
        if (endDateDiffFromCrtDate > actSendDateDiffFromCrtDate) {
          setMaxEmailSendDate(
            new Date(moment().add(1, "M").endOf("month").subtract(1, "days"))
          ); // Restrict max send date as end date of next month.
        } else {
          setMaxEmailSendDate(
            new Date(moment(endDateValue).subtract(1, "days"))
          ); //Subtract the 1 day from the 'end_date'.
        }
      } else {
        setMaxEmailSendDate(
          new Date(moment().add(1, "M").endOf("month").subtract(1, "days"))
        );
      }
    } else {
      if (formik.values.end_date != null && formik.values.end_date != "") {
        setMaxEmailSendDate(
          new Date(moment(formik.values.end_date).subtract(1, "days"))
        ); //Subtract the 1 day from the 'end_date'.
      }
    }
    if (
      formik.values.start_date != null &&
      formik.values.start_date != "" &&
      formik.values.end_date != null &&
      formik.values.end_date != "" &&
      startDate == endDate
    ) {
      setMaxEmailSendDate(new Date(formik.values.start_date));
    }
  }

  function onChangeScheduleDateTime() {
    //Parse and formats a start_date, end_date, today date and scheduled_start_date_time  to moment.
    var startDate = moment(formik.values.start_date).format("YYYY-MM-DD");
    var endDate = moment(formik.values.end_date).format("YYYY-MM-DD");
    var dateTime = moment(dateTimeValue).format("YYYY-MM-DD");
    var scheduledStartDateTime = moment(
      formik.values.scheduled_start_date_time
    ).format("YYYY-MM-DD");
    var subEndDate = moment(formik.values.end_date)
      .subtract(1, "days")
      .format("YYYY-MM-DD"); //Subtract the 1 day from the 'end_date'.
    if (scheduledStartDateTime == dateTime) {
      if (dateTimeValue > formik.values.scheduled_start_date_time) {
        formik.setFieldValue("scheduled_start_date_time", dateTimeValue);
      } else {
        //Gets a current minutes from the today date and scheduled_start_date_time.
        var currentMinute = moment(dateTimeValue, "HH:mm:ss").minutes();
        var startDateMinute = moment(
          formik.values.scheduled_start_date_time,
          "HH:mm:ss"
        ).minutes();
        if (currentMinute > startDateMinute) {
          //Sets the current minute to the scheduled_start_date_time field.
          formik.setFieldValue(
            "scheduled_start_date_time",
            new Date(
              moment(formik.values.scheduled_start_date_time).set({
                minute: currentMinute,
              })
            )
          );
        }
      }
    }
    if (
      formik.values.start_date != null &&
      formik.values.start_date != "" &&
      formik.values.end_date != null &&
      formik.values.end_date != "" &&
      startDate == endDate
    ) {
      if (formik.values.start_date < formik.values.scheduled_start_date_time) {
        formik.setFieldValue(
          "scheduled_start_date_time",
          formik.values.start_date
        );
      } else {
        setMaxEmailSendDate(new Date(formik.values.start_date));
      }
    } else {
      if (scheduledStartDateTime == subEndDate) {
        if (maxEmailSendDate < formik.values.scheduled_start_date_time) {
          formik.setFieldValue("scheduled_start_date_time", maxEmailSendDate);
        } else {
          //Gets a current minutes from the 'maxEmailSendDate' and 'maxEmailSendDate' date.
          var currentMinute = moment(maxEmailSendDate, "HH:mm:ss").minutes();
          var startDateMinute = moment(
            formik.values.scheduled_start_date_time,
            "HH:mm:ss"
          ).minutes();
          if (currentMinute < startDateMinute) {
            //Sets the current minute to the scheduled_start_date_time field.
            formik.setFieldValue(
              "scheduled_start_date_time",
              new Date(
                moment(formik.values.scheduled_start_date_time).set({
                  minute: currentMinute,
                })
              )
            );
          }
        }
      }
    }
    var scheduledDate = moment(formik.values.scheduled_start_date_time)
      .endOf("month")
      .format("YYYY-MM-DD");
    var currentDate = moment(dateTimeValue).endOf("month").format("YYYY-MM-DD");
    if (moment(scheduledDate).isAfter(currentDate, "month") == true) {
      setScheduleNextMonth(1);
      formik.setFieldValue("scheduleNextMonth", 1);
      setAdditionalSmsOptions([
        { name: "Upgrade the subscription plan", key: "upgrade_plan" },
      ]);
    } else {
      setScheduleNextMonth(0);
      formik.setFieldValue("scheduleNextMonth", 0);
      if (
        undefined != perSmsChargeObject["price"] &&
        null != perSmsChargeObject["price"] &&
        "" != perSmsChargeObject["price"]
      ) {
        setAdditionalSmsOptions([
          { name: "Upgrade the subscription plan", key: "upgrade_plan" },
          {
            name:
              "Charge additional SMS as a $" +
              perSmsChargeObject["price"] +
              " / SMS",
            key: "per_sms_charge",
          },
        ]);
      } else {
        setAdditionalSmsOptions([
          { 
            name: "Upgrade the subscription plan", key: "upgrade_plan" 
          },
          {
            name: "Charge additional SMS as a $xyz / SMS",
            key: "per_sms_charge",
          },
        ]);
      }
    }
    if (
      null != formik.values.select_audience &&
      "" != formik.values.select_audience
    ) {
      if (
        formik.values.select_audience == Constants.SELECT_SEGMENT &&
        formik.values.selectedSegments.length > 0
      ) {
        checkCampaignSMSAvailability(
          formik.values.select_audience,
          formik.values.selectedSegments,
          formik.values.marketing_type
        );
      } else if (formik.values.select_audience == Constants.SELECT_ALL) {
        checkCampaignSMSAvailability(formik.values.select_audience, [], formik.values.marketing_type);
      }
    }
  }

  const onChangeScheduleType = (event) => {
    if (event.value == Constants.SCHEDULED_LATER) {
      if (
        (null == formik.values.end_date || "" == formik.values.end_date) &&
        formik.values.marketing_type != Constants.EMAIL
      ) {
        setMaxEmailSendDate(
          new Date(moment().add(1, "M").endOf("month").subtract(1, "days"))
        );
      }
    } else {
      formik.setFieldValue("scheduled_start_date_time", "");
      formik.setFieldTouched("scheduled_start_date_time", false);
      formik.values.scheduled_start_date_time = null;
      formik.values.scheduled_type = Constants.SCHEDULED_NOW;
      formik.setFieldValue("scheduled_type", Constants.SCHEDULED_NOW);
      if (
        undefined != perSmsChargeObject["price"] &&
        null != perSmsChargeObject["price"] &&
        "" != perSmsChargeObject["price"]
      ) {
        setAdditionalSmsOptions([
          { name: "Upgrade the subscription plan", key: "upgrade_plan" },
          {
            name:
              "Charge additional SMS as a $" +
              perSmsChargeObject["price"] +
              " / SMS",
            key: "per_sms_charge",
          },
        ]);
      } else {
        setAdditionalSmsOptions([
          { name: "Upgrade the subscription plan", key: "upgrade_plan" },
          {
            name: "Charge additional SMS as a $xyz / SMS",
            key: "per_sms_charge",
          },
        ]);
      }
    }
    if (formik.values.marketing_type != Constants.EMAIL) {
      if (
        null != formik.values.select_audience &&
        "" != formik.values.select_audience
      ) {
        if (
          formik.values.select_audience == Constants.SELECT_SEGMENT &&
          formik.values.selectedSegments.length > 0
        ) {
          checkCampaignSMSAvailability(
            formik.values.select_audience,
            formik.values.selectedSegments,
            formik.values.marketing_type
          );
        } else if (formik.values.select_audience == Constants.SELECT_ALL) {
          checkCampaignSMSAvailability(formik.values.select_audience, [], formik.values.marketing_type);
        }
      }
    }
    else {
      checkCampaignEmailAvailability(formik.values.select_audience, [], formik.values.marketing_type);
    }
  };

  const onSelectCoupon = (event) => {
    const distinctCheck = selectedCouponsArray.find(
      (element) => element["id"] == event.value.id
    );
    if (undefined != distinctCheck) {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.CAMPAIGN_MODULE,
        alertDescription: Constants.COUPON_ALREADY_ADDED_ERROR,
      });
    } else {
      const getCurrentCouponType = event.value.coupon_type;
      if (
        selectedCouponsArray.find(
          (element) => element["coupon_type"] == Constants.ORDER
        ) != undefined &&
        getCurrentCouponType == "ord"
      ) {
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.CAMPAIGN_MODULE,
          alertDescription: Constants.COUPON_ORDER_TYPE_ERROR,
        });
      } else {
        let data = Object.assign({}, event.value);
        setSelectedCouponDetails(data);
        onCoupFormModelOpen();
      }
    }
  };

  const onDeleteCoupon = (couponId) => {
    const updatedSelectedCouponsArray = selectedCouponsArray.filter(
      (coupon) => coupon.id !== couponId
    );
    setSelectedCouponsArray(updatedSelectedCouponsArray);
  };

  function onChangeMaxUsage(value) {
    setCouponMaxUsageValue(value);
    if (!value.trim()) {
      setMaxUsageValidationError(Constants.MAX_USAGE_REQUIRED);
    } else if (parseFloat(value) < 1) {
      setMaxUsageValidationError(Constants.MAX_USAGE_MIN_ERROR);
    } else if (parseFloat(value) > 999999) {
      setMaxUsageValidationError(Constants.MAX_USAGE_MAX_ERROR);
    } else if (isPositiveInteger(value)) {
      setMaxUsageValidationError("");
    }
  }

  const isPositiveInteger = (val) => {
    let str = String(val);
    str = str.trim();
    if (!str) {
      return false;
    }
    str = str.replace(/^0+/, "") || "0";
    let n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  };

  function onCancelClickCoupMaxUsgFormModel() {
    setSelectedCouponDetails({});
    onCoupFormModelClose();
    setCouponMaxUsageValue("");
    setMaxUsageValidationError("");
  }

  function onAddCouponMaxUsage() {
    const selectedCoupon = selectedCouponDetails;
    selectedCoupon.coupon_type =
      selectedCoupon.coupon_type == Constants.COUPON_TYPE_PRODUCT
        ? Constants.PRODUCT
        : Constants.ORDER;
    selectedCoupon.product_details =
      selectedCoupon.coupon_type == Constants.PRODUCT ? (
        selectedCoupon.apply_on_all_prods == 0 ? (
          <IconButton
            sx={{ color: Colors.posNavbarLink }}
            onClick={() => viewProductDetails(selectedCoupon.products)}
          >
            <VisibilityIcon />
          </IconButton>
        ) : (
          "Apply to all Products"
        )
      ) : (
        "N.A."
      );
    selectedCoupon.discount_type =
      selectedCoupon.discount_type == Constants.DISCOUNT_TYPE_AMOUNT
        ? Constants.AMOUNT
        : Constants.PERCENTAGE;
    selectedCoupon.min_amount =
      selectedCoupon.coupon_type != Constants.PRODUCT
        ? selectedCoupon.min_amount
        : "N.A.";
    selectedCoupon.max_qty_per_coupon =
      selectedCoupon.coupon_type == Constants.PRODUCT
        ? selectedCoupon.max_qty_per_coupon
        : "N.A.";
    selectedCoupon.max_usage = parseInt(couponMaxUsageValue);
    setSelectedCouponsArray([...selectedCouponsArray, selectedCoupon]);
    setSelectedCouponDetails({});
    onCoupFormModelClose();
    setCouponMaxUsageValue("");
    setMaxUsageValidationError("");
  }

  const viewProductDetails = (productsDetails) => {
    var uniqueProducts = productsDetails.reduce((uniqueProdArray, element) => {
      if (!uniqueProdArray.find((v) => v["barcode"] === element["barcode"])) {
        const productPayload = {
          id: element.id,
          name: element.name,
          barcode: element.barcode,
        };
        uniqueProdArray.push(productPayload);
      }
      return uniqueProdArray;
    }, []);
    setSelectedCouponProdViewDetails(uniqueProducts);
    onCoupProdViewModelOpen();
  };

  function onChangeSubjectLine(value) {
    setSubjectLineValue(value);
    if (!value.trim()) {
      setSubjectLineValidationError(Constants.SUBJECT_REQUIRED);
    } else if (value.trim().length > 191) {
      setSubjectLineValidationError(Constants.SUBJECT_LENGTH_GREATER_THEN_191);
    } else {
      setSubjectLineValidationError("");
    }
  }

  function onChangeSmsText(value) {
    setSmsTextValue(value.substring(0, 160));
    if (!value.trim()) {
      setSmsTextValidationError(Constants.SMS_DETAILS_REQUIRED);
    } else {
      setSmsTextValidationError("");
    }
  }

  const emailValue = (email) => {
    const re = new RegExp(Constants.EMAIL_REGEX);
    const testResult = re.test(String(email).trim().toLowerCase());
    if (!testResult) {
      setValidationErrors(email + ` is not a valid email address.`);
    } else {
      setSelectedEmailAddToSendTestEmail([
        ...selectedEmailAddToSendTestEmail,
        email,
      ]);
      setValidationErrors("");
    }
  };

  function onCancelClickSendTestEmailModel() {
    setSelectedEmailAddToSendTestEmail([]);
    setValidationErrors("");
    onSendTestEmailModelClose();
  }

  const sendTestEmailForTemplate = () => {
    var payload = {
      email: selectedEmailAddToSendTestEmail,
      template_html: selectedEmailTemplateHtml,
      subject_line: subjectLineValue,
    };
    myContext.handleLoading(true);
    setButtonDisable(true);
    try {
      sendTestEmail(payload)
        .then((response) => {
          if (
            undefined !== response?.data?.flag &&
            null !== response?.data?.flag &&
            response?.data?.flag == true
          ) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.CAMPAIGN_MODULE,
              alertDescription: response.data.message,
            });
            myContext.handleLoading(false);
            setButtonDisable(false);
            setSelectedEmailAddToSendTestEmail([]);
            setValidationErrors("");
            onSendTestEmailModelClose();
          } else {
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
            setButtonDisable(false);
          }
        })
        .catch((err) => {
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
          setButtonDisable(false);
        });
    } catch (error) {
      myContext.handleLoading(false);
      setButtonDisable(false);
    }
  };

  const getSmsPlanDetails = () => {
    if (formik.values.marketing_type != Constants.EMAIL) {
      myContext.handleLoading(true);
      setButtonDisable(true);
      try {
        getSmsPlanData()
          .then((response) => {
            if (
              undefined !== response?.data?.flag &&
              null !== response?.data?.flag &&
              response?.data?.flag == true
            ) {
              myContext.handleLoading(false);
              setButtonDisable(false);
              if (
                null != response.data.data.user_details.customer_card_details &&
                "" != response.data.data.user_details.customer_card_details
              ) {
                setSmsPlans(response.data.data.smsPlans);
                var getPerSmsPlanDetails = response.data.data.smsPlans.filter(
                  (s) => s.no_of_sms == 0
                );
                if (
                  getPerSmsPlanDetails.length == 1 &&
                  getPerSmsPlanDetails[0].no_of_sms == 0
                ) {
                  setPerSmsChargeObject(getPerSmsPlanDetails[0]);
                  if (
                    undefined != getPerSmsPlanDetails[0]["price"] &&
                    null != getPerSmsPlanDetails[0]["price"] &&
                    "" != getPerSmsPlanDetails[0]["price"]
                  ) {
                    setAdditionalSmsOptions([
                      {
                        name: "Upgrade the subscription plan",
                        key: "upgrade_plan",
                      },
                      {
                        name:
                          "Charge additional SMS as a $" +
                          getPerSmsPlanDetails[0]["price"] +
                          " / SMS",
                        key: "per_sms_charge",
                      },
                    ]);
                  } else {
                    setAdditionalSmsOptions([
                      {
                        name: "Upgrade the subscription plan",
                        key: "upgrade_plan",
                      },
                      {
                        name: "Charge additional SMS as a $xyz / SMS",
                        key: "per_sms_charge",
                      },
                    ]);
                  }
                } else {
                  setPerSmsChargeObject({});
                  setAdditionalSmsOptions([
                    {
                      name: "Upgrade the subscription plan",
                      key: "upgrade_plan",
                    },
                    {
                      name: "Charge additional SMS as a $xyz / SMS",
                      key: "per_sms_charge",
                    },
                  ]);
                }
                setCustomerSmsPlanSubscribed(
                  response.data.data.sms_plan_subscribed
                );
                formik.setFieldValue(
                  "ms_cust_id",
                  response.data.data.ms_cust_id
                );
              } else {
                addToast({
                  alertStatus: Constants.TOAST_TYPE_WARNING,
                  alertTitle: Constants.CAMPAIGN_MODULE,
                  alertDescription: Constants.CARD_DETAILS_GET_ERROR,
                });
                props.navigate(Constants.BILLINGS_VIEW_DETAILS);
              }
            } else {
              let actionData = response;
              error({ actionData });
              myContext.handleLoading(false);
              setButtonDisable(false);
            }
          })
          .catch((err) => {
            let actionData = err;
            error({ actionData });
            myContext.handleLoading(false);
            setButtonDisable(false);
          });
      } catch (error) {
        myContext.handleLoading(false);
        setButtonDisable(false);
      }
    }
  };

  function checkCampaignSMSAvailability(audienceType, selectedSegments, marketingType) {
    myContext.handleLoading(true);
    setButtonDisable(true);
    if (formik.values.scheduled_type == Constants.SCHEDULED_NOW) {
      var month = moment().format("M");
      var year = moment().format("YYYY");
    } else {
      var month = moment(formik.values.scheduled_start_date_time).format("M");
      var year = moment(formik.values.scheduled_start_date_time).format("YYYY");
      if (scheduleNextMonth == 1) {
        setAdditionalSmsOptions([
          { name: "Upgrade the subscription plan", key: "upgrade_plan" },
        ]);
      }
    }
    if (
      formik.values.scheduled_type == Constants.SCHEDULED_NOW ||
      (formik.values.scheduled_type == Constants.SCHEDULED_LATER &&
        undefined != formik.values.scheduled_start_date_time &&
        null != formik.values.scheduled_start_date_time &&
        "" != formik.values.scheduled_start_date_time)
    ) {
      var payload = {
        audience_type: audienceType,
        selected_segment: selectedSegments,
        month: month,
        year: year,
        smsPlanList: smsPlans,
        scheduleNextMonth: scheduleNextMonth,
        scheduled_start_time: formik.values.scheduled_start_date_time,
        marketing_type: marketingType,
      };
      try {
        checkSMSAvailability(payload)
          .then((response) => {
            if (
              undefined !== response?.data?.flag &&
              null !== response?.data?.flag &&
              response?.data?.flag == true
            ) {
              myContext.handleLoading(false);
              setButtonDisable(false);
              if (
                response.data.data.hasOwnProperty("eligibilityObject") &&
                Object.keys(response.data.data.eligibilityObject).length > 0
              ) {
                setSmsEligibility(
                  response.data.data.eligibilityObject.sms_eligibility
                );
                formik.setFieldValue(
                  "sms_counter_id",
                  response.data.data.eligibilityObject.sms_counter_id
                );
                setAdditionalSms(
                  response.data.data.eligibilityObject.additional_sms
                );
                setNumberOfCampUser(response.data.data.camp_user_count);
                setNumberOfCampUserEmail(response.data.data.camp_email_user_count);
              } else {
                setCustomerSmsPlanSubscribed(0); // set flag as 0 because currently no plan subscribe for current month.
                setNumberOfCampUser(response.data.data.camp_user_count);
                setNumberOfCampUserEmail(response.data.data.camp_email_user_count);
              }
              if (
                response.data.data.hasOwnProperty("sms_distribution") &&
                Object.keys(response.data.data.sms_distribution).length > 0
              ) {
                response.data.data.sms_distribution.map((dist, i) => {
                  dist.date = onlyDateFormate(dist.date);
                });
                const lastElement =
                  response.data.data.sms_distribution[
                    response.data.data.sms_distribution.length - 1
                  ].date;
                setSmsDistribution(response.data.data.sms_distribution);
                setSmsLastDate(
                  new Date(moment(lastElement).format("YYYY-MM-DD HH:mm:ss"))
                );
                var lastSmsDate = new Date(
                  moment(lastElement).format("YYYY-MM-DD HH:mm:ss")
                ); //Add the 1 day from the 'end_date'.
                if (
                  undefined != formik.values.end_date &&
                  null != formik.values.end_date &&
                  "" != formik.values.end_date
                ) {
                  var subEndDate = moment(formik.values.end_date).format(
                    "YYYY-MM-DD HH:mm:ss"
                  ); //Subtract the 1 day from the 'end_date'.
                  formik.setFieldValue("end_date", null);
                  formik.values.end_date = "";
                  formik.setFieldTouched("end_date", true);
                  if (lastSmsDate >= subEndDate) {
                    setSmsLastMinDate(
                      new Date(
                        moment(lastElement)
                          .add(1, "days")
                          .format("YYYY-MM-DD HH:mm:ss")
                      )
                    );
                  } else {
                    setSmsLastMinDate(
                      new Date(
                        moment(lastElement)
                          .add(1, "days")
                          .format("YYYY-MM-DD HH:mm:ss")
                      )
                    );
                  }
                } else {
                  if (formik.values.start_date > lastSmsDate) {
                    setSmsLastMinDate(
                      new Date(
                        moment(formik.values.start_date).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )
                      )
                    );
                  } else {
                    setSmsLastMinDate(
                      new Date(
                        moment(lastElement)
                          .add(1, "days")
                          .format("YYYY-MM-DD HH:mm:ss")
                      )
                    );
                  }
                }
              }
              if (response.data.data.hasOwnProperty("sms_per_day")) {
                setSmsPerDay(response.data.data.sms_per_day);
              }
              if (
                response.data.data.hasOwnProperty("nextMonthSmsPlan") &&
                Object.keys(response.data.data.nextMonthSmsPlan).length > 0
              ) {
                setCurrentPlanForNextMonth(
                  response.data.data.nextMonthSmsPlan.plan
                );
                if (response.data.data.nextMonthSmsPlan.plan_not_found == 1) {
                  setCustomerSmsPlanSubscribed(1); // set flag as 1 because plan already subscribe to current month but they try to add plan for next month.
                  formik.setFieldValue(
                    "createNextMonthPlan",
                    response.data.data.nextMonthSmsPlan.plan
                  );
                } else {
                  setCustomerSmsPlanSubscribed(1);
                  var getPlan = smsPlans.filter(
                    (s) =>
                      s.no_of_sms >=
                      response.data.data.eligibilityObject.additional_sms +
                        response.data.data.nextMonthSmsPlan.plan.no_of_sms
                  );
                  if (getPlan.length > 0) {
                    setMaxSmsPlanSelection(0);
                  } else {
                    setMaxSmsPlanSelection(1);
                  }
                }
              } else {
                formik.setFieldValue("createNextMonthPlan", []);
                formik.setFieldValue("updateNextMonthPlan", []);
              }
            } else {
              let actionData = response;
              error({ actionData });
              myContext.handleLoading(false);
              setButtonDisable(false);
            }
          })
          .catch((err) => {
            let actionData = err;
            error({ actionData });
            myContext.handleLoading(false);
            setButtonDisable(false);
          });
      } catch (error) {
        myContext.handleLoading(false);
        setButtonDisable(false);
      }
    } else {
      myContext.handleLoading(false);
      setButtonDisable(false);
    }
  }

  function checkCampaignEmailAvailability(audienceType, selectedSegments, marketingType) {
    myContext.handleLoading(true);
    setButtonDisable(true);
    if (formik.values.scheduled_type == Constants.SCHEDULED_NOW) {
      var month = moment().format("M");
      var year = moment().format("YYYY");
    } else {
      var month = moment(formik.values.scheduled_start_date_time).format("M");
      var year = moment(formik.values.scheduled_start_date_time).format("YYYY");
      if (scheduleNextMonth == 1) {
        setAdditionalSmsOptions([
          { name: "Upgrade the subscription plan", key: "upgrade_plan" },
        ]);
      }
    }
    if (
      formik.values.scheduled_type == Constants.SCHEDULED_NOW ||
      (formik.values.scheduled_type == Constants.SCHEDULED_LATER &&
        undefined != formik.values.scheduled_start_date_time &&
        null != formik.values.scheduled_start_date_time &&
        "" != formik.values.scheduled_start_date_time)
    ) {
      var payload = {
        audience_type: audienceType,
        selected_segment: selectedSegments,
        month: month,
        year: year,
        scheduleNextMonth: scheduleNextMonth,
        scheduled_start_time: formik.values.scheduled_start_date_time,
        marketing_type: marketingType,
      };
      try {
        checkEmailAvailability(payload)
          .then((response) => {
            if (
              undefined !== response?.data?.flag &&
              null !== response?.data?.flag &&
              response?.data?.flag == true
            ) {
              myContext.handleLoading(false);
              setButtonDisable(false);
              if (response.data.data.camp_email_user_count > 0) {
                setNumberOfCampUserEmail(response.data.data.camp_email_user_count);
              }
            } else {
              let actionData = response;
              error({ actionData });
              myContext.handleLoading(false);
              setButtonDisable(false);
            }
          })
          .catch((err) => {
            let actionData = err;
            error({ actionData });
            myContext.handleLoading(false);
            setButtonDisable(false);
          });
      } catch (error) {
        myContext.handleLoading(false);
        setButtonDisable(false);
      }
    } else {
      myContext.handleLoading(false);
      setButtonDisable(false);
    }
  }

  function onSelectUpgradePlanOption(option) {
    setSelectedUpgradePlanOption(option);
    if (option.key == Constants.UPGRADE_PLAN) {
      setSmsEligibility(0);
      formik.setFieldValue("per_sms_charge_flag", 0);
      formik.setFieldValue("per_sms_charge", null);
      openSmsPlanModel(0);
    } else {
      setSmsEligibility(1);
      formik.setFieldValue("per_sms_charge_flag", 1);
      formik.setFieldValue("per_sms_charge", perSmsChargeObject.price);
      checkCampaignSMSAvailability(
        formik.values.select_audience,
        formik.values.selectedSegments,
        formik.values.marketing_type
      );
    }
  }

  function openSmsPlanModel(subscribeNewPlan) {
    if (customerCancelPlanSubscription == 1 && scheduleNextMonth == 1) {
      setSmsPlanModelLabel("Choose SMS Plan");
      setSmsPlanListToDisplayInModel(
        smsPlans.filter(function (item) {
          return item["no_of_sms"] > 0;
        })
      );
    } else {
      if (scheduleNextMonth == 0) {
        if (subscribeNewPlan == 1) {
          setSmsPlanModelLabel("Choose SMS Plan");
          // display plan as no. of sms greater then campaign user when no plan subscribed and try to subscribe new one.
          setSmsPlanListToDisplayInModel(
            smsPlans.filter((s) => s.no_of_sms >= numberOfCampUser)
          );
        } else {
          setSmsPlanModelLabel("Upgrade SMS Plan");
          // display plan as no. of sms greater then additional campaign user when plan already subscribed but less sms left in subscription plan in comparison to campaign user.
          setSmsPlanListToDisplayInModel(
            smsPlans.filter((s) => s.no_of_sms >= additionalSms)
          );
        }
      } else {
        // when campaign is scheduled for next month then sms plan display as no. of sms greater then sum of additional campaign user and previous plan's no of sms.
        setSmsPlanModelLabel("Upgrade SMS Plan");
        setSmsPlanListToDisplayInModel(
          smsPlans.filter(
            (s) =>
              s.no_of_sms >= additionalSms + currentPlanForNextMonth.no_of_sms
          )
        );
      }
    }
    onSmsPlanModelOpen();
  }

  function handleSmsPlanRadioChange(index, smsPlan) {
    setSelectedPlanIndex(index);
    setSelectedSmsPlan(smsPlan);
  }

  function submitPlan() {
    if (formik.values.scheduled_type == Constants.SCHEDULED_NOW) {
      var month = moment().format("M");
      var year = moment().format("YYYY");
    } else {
      var month = moment(formik.values.scheduled_start_date_time).format("M");
      var year = moment(formik.values.scheduled_start_date_time).format("YYYY");
    }
    if (scheduleNextMonth == 0 || customerCancelPlanSubscription == 1) {
      var payload = {
        month: month,
        year: year,
        scheduleNextMonth: scheduleNextMonth,
        customerCancelPlanSubscription: customerCancelPlanSubscription,
        applyToNextMonth: 0,
        selectedSmsPlan: selectedSmsPlan,
      };
      myContext.handleLoading(true);
      setButtonDisable(true);
      try {
        updateSMSPlan(payload)
          .then((response) => {
            if (
              undefined !== response?.data?.flag &&
              null !== response?.data?.flag &&
              response?.data?.flag == true
            ) {
              addToast({
                alertStatus: Constants.TOAST_TYPE_SUCESS,
                alertTitle: Constants.CAMPAIGN_MODULE,
                alertDescription: response.data.message,
              });
              setCustomerSmsPlanSubscribed(1);
              setCustomerCancelPlanSubscription(0);
              setSelectedPlanIndex("");
              setSelectedSmsPlan({});
              onSmsPlanModelClose();
              myContext.handleLoading(false);
              setButtonDisable(false);
              if (numberOfCampUser > 0) {
                checkCampaignSMSAvailability(
                  formik.values.select_audience,
                  formik.values.selectedSegments,
                  formik.values.marketing_type
                );
              }
            } else {
              let actionData = response;
              error({ actionData });
              myContext.handleLoading(false);
              setButtonDisable(false);
            }
          })
          .catch((err) => {
            let actionData = err;
            error({ actionData });
            myContext.handleLoading(false);
            setButtonDisable(false);
          });
      } catch (error) {
        myContext.handleLoading(false);
        setButtonDisable(false);
      }
    } else {
      if (
        null != selectedSmsPlan &&
        "" != selectedSmsPlan &&
        Object.keys(selectedSmsPlan).length > 0
      ) {
        formik.setFieldValue("updateNextMonthPlan", selectedSmsPlan);
        setSmsEligibility(1);
        setAdditionalSms(0);
        setSelectedPlanIndex("");
        setSelectedSmsPlan({});
        onSmsPlanModelClose();
      }
    }
  }

  const onSelectSegment = (e) => {
    setNumberOfCampUser(0);
    setNumberOfCampUserEmail(0);
    formik.setFieldValue("selectedSegments", e.value);
    if (e.value.length == 0) {
      formik.setFieldValue("select_audience", "");
      formik.values.select_audience = "";
    }
  };

  const onHideSegmentDropdownPanel = () => {
    if (
      formik.values.marketing_type != Constants.EMAIL &&
      formik.values.selectedSegments != undefined &&
      formik.values.selectedSegments.length > 0
    ) {
      checkCampaignSMSAvailability(
        formik.values.select_audience,
        formik.values.selectedSegments,
        formik.values.marketing_type
      );
    }
    else {
      checkCampaignEmailAvailability(formik.values.select_audience,
        formik.values.selectedSegments,
        formik.values.marketing_type);
    }
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      start_date: "",
      end_date: "",
      scheduled_start_date_time: "",
      scheduled_type: "",
      marketing_type: "",
      campaign_type: "",
      select_audience: "",
      selectedSegments: [],
      save_as_draft: 0,
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .trim()
        .required(Constants.NAME_REQUIRED)
        .max(190, Constants.NAME_LENGTH_GREATER_THEN_191),
      start_date: Yup.string().when("campaign_type", {
        is: Constants.TARGETED,
        then: (schema) => schema.required(Constants.START_DATE_REQUIRED),
        otherwise: (schema) => schema.optional(),
      }),
      end_date: Yup.string().when("campaign_type", {
        is: Constants.TARGETED,
        then: (schema) => schema.required(Constants.END_DATE_REQUIRED),
        otherwise: (schema) => schema.optional(),
      }),
      scheduled_type: Yup.string(),
      scheduled_start_date_time: Yup.string().when("scheduled_type", {
        is: Constants.SCHEDULED_LATER,
        then: (schema) =>
          schema.required(Constants.SCHEDULED_SEND_DATE_TIME_REQUIRED),
        otherwise: (schema) => schema.optional(),
      }),
      select_audience: Yup.string()
        .trim()
        .required(Constants.AUDIENCE_TYPE_REQUIRED),
      selectedSegments: Yup.array().when("select_audience", {
        is: Constants.SELECT_SEGMENT,
        then: (schema) => schema.min(1, Constants.SEGMENT_IS_REQUIRED),
        otherwise: (schema) => schema.optional(),
      }),
    }),
    onSubmit: async (values) => {
      myContext.handleLoading(true);
      setButtonDisable(true);
      effectRun.current = true;
      if (formik.values.marketing_type != Constants.EMAIL) {
        if (customerCancelPlanSubscription == 1 && scheduleNextMonth == 1) {
          addToast({
            alertStatus: Constants.TOAST_TYPE_WARNING,
            alertTitle: Constants.CAMPAIGN_MODULE,
            alertDescription:
              Constants.CAMPAIGN_CREATE_FOR_NEXT_MONTH_WITH_CANCEL_PLAN_ERROR,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
          return;
        }
        if (
          (customerSmsPlanSubscribed == 0 &&
            customerCancelPlanSubscription == 0) ||
          (customerCancelPlanSubscription == 1 &&
            smsLeftInCurrentMonthPlanSub == 1 &&
            smsEligibility == 0)
        ) {
          addToast({
            alertStatus: Constants.TOAST_TYPE_WARNING,
            alertTitle: Constants.CAMPAIGN_MODULE,
            alertDescription: Constants.PLAN_SUBSCRIPTION_REQUIRED,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
          return;
        }
        if (customerSmsPlanSubscribed == 1 && smsEligibility == 0 && (values.per_sms_charge_flag == 0 || undefined == values.per_sms_charge_flag)) {
          addToast({
            alertStatus: Constants.TOAST_TYPE_WARNING,
            alertTitle: Constants.CAMPAIGN_MODULE,
            alertDescription: Constants.FORM_FILLING_ERROR,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
          return;
        }
      }
      if (values.marketing_type != Constants.SMS) {
        values.template_re_html = selectedEmailTemplate;
        values.template_html = selectedEmailTemplateHtml;
        values.subject_line = subjectLineValue;
        values.template_name = templateName;
        values.image = allEmailImages;
      }
      if (values.marketing_type != Constants.EMAIL) {
        values.scheduleNextMonth = scheduleNextMonth;
        values.sms_dtls = smsTextValue;
      }
      if (values.campaign_type == Constants.TARGETED) {
        values.selectedCoupons = selectedCouponsArray;
      }
      if (values.marketing_type == Constants.SMS) {
        var marketingType = [Constants.SMS];
      } else if (values.marketing_type == Constants.EMAIL) {
        var marketingType = [Constants.EMAIL];
      } else {
        var marketingType = [Constants.SMS, Constants.EMAIL];
      }
      values.marketing_type = marketingType;
      values.total_user = numberOfCampUser > 0 ? numberOfCampUser : 0;
      try {
        campaignCreate(values)
          .then((response) => {
            if (
              undefined !== response?.data?.flag &&
              null !== response?.data?.flag &&
              response?.data?.flag == true
            ) {
              addToast({
                alertStatus: Constants.TOAST_TYPE_SUCESS,
                alertTitle: Constants.CAMPAIGN_MODULE,
                alertDescription: response.data.message,
              });
              props.navigate(Constants.MARKETING_CAMPAIGN_PATH, {
                state: paramData,
              });
              myContext.handleLoading(false);
              setButtonDisable(false);
            } else {
              let actionData = response;
              error({ actionData });
              myContext.handleLoading(false);
              setButtonDisable(false);
            }
          })
          .catch((err) => {
            let actionData = err;
            error({ actionData });
            myContext.handleLoading(false);
            setButtonDisable(false);
          });
      } catch (error) {
        myContext.handleLoading(false);
        setButtonDisable(false);
      }
    },
  });

  const onSaveAsDraft = (values) => {
    myContext.handleLoading(true);
    setButtonDisable(true);
    effectRun.current = true;
    var app = Redactor("#designEmail");
    if (app != undefined && null != app && "" != app) {
      var reHTML = app.editor.getContent(true); //Returns the formatted html code of the '#predefinedEmail' editor.
      var HTML = app.editor.getEmail(true); //Returns the formatted html code of the '#predefinedEmail' editor.
      htmlRef.current = HTML;
      reHtmlRef.current = reHTML;
      setSelectedEmailTemplate(reHtmlRef.current);
      setSelectedEmailTemplateHtml(htmlRef.current);
    } else {
      htmlRef.current = "";
      reHtmlRef.current = "";
    }
    if (values.marketing_type != Constants.SMS) {
      values.template_re_html =
        undefined != reHtmlRef.current &&
        null != reHtmlRef.current &&
        "" != reHtmlRef.current
          ? reHtmlRef.current
          : selectedEmailTemplate;
      values.template_html =
        undefined != htmlRef.current &&
        null != htmlRef.current &&
        "" != htmlRef.current
          ? htmlRef.current
          : selectedEmailTemplateHtml;
      values.subject_line = subjectLineValue;
      values.template_name = templateName;
      values.image = allEmailImages;
    }
    if (values.marketing_type != Constants.EMAIL) {
      values.scheduleNextMonth = scheduleNextMonth;
      values.sms_dtls = smsTextValue;
    }
    if (values.campaign_type == Constants.TARGETED) {
      values.selectedCoupons = selectedCouponsArray;
    }
    if (values.marketing_type == Constants.SMS) {
      var marketingType = [Constants.SMS];
      var typeValue = [Constants.SMS];
    } else if (values.marketing_type == Constants.EMAIL) {
      var marketingType = [Constants.EMAIL];
      var typeValue = [Constants.EMAIL];
    } else {
      var marketingType = [Constants.SMS, Constants.EMAIL];
      var typeValue = Constants.SMS_EMAIL_BOTH;
    }
    values.marketing_type = marketingType;
    values.typeValue = typeValue;
    values.total_user = numberOfCampUser > 0 ? numberOfCampUser : 0;
    try {
      campaignCreate(values)
        .then((response) => {
          if (
            undefined !== response?.data?.flag &&
            null !== response?.data?.flag &&
            response?.data?.flag == true
          ) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.CAMPAIGN_MODULE,
              alertDescription: "Campaign draft created successfully.",
            });
            props.navigate(Constants.MARKETING_CAMPAIGN_PATH, {
              state: paramData,
            });
            myContext.handleLoading(false);
            setButtonDisable(false);
          } else {
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
            setButtonDisable(false);
          }
        })
        .catch((err) => {
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
          setButtonDisable(false);
        });
    } catch (error) {
      myContext.handleLoading(false);
      setButtonDisable(false);
    }
  };
  
  useEffect(() => {
    if (effectRun.current === true) {
      onChangeDate();
      effectRun.current = false;
    }
  }, [formik.values.start_date, formik.values.end_date]);

  useEffect(() => {
    if (effectRun.current === true) {
      onChangeScheduleDateTime();
      effectRun.current = false;
    }
  }, [formik.values.scheduled_start_date_time]);

  const finalSelectedCoupons = selectedCouponsArray.map((coupon) => ({
    id: coupon.id,
    title: coupon.title,
    coupon_type: coupon.coupon_type,
    productDetailsIcon: coupon.product_details,
    discount_type: coupon.discount_type,
    discount_value: coupon.discount_value,
    min_amount: coupon.min_amount,
    max_qty_per_coupon: coupon.max_qty_per_coupon,
    max_usage: parseInt(coupon.max_usage),
  }));

  const planListDetails = smsPlanListToDisplayInModel.map((smsPlan, index) => ({
    name: smsPlan.name,
    no_of_sms: smsPlan.no_of_sms,
    price: smsPlan.price,
    select: (
      <Radio
        isChecked={index === selectedPlanIndex}
        onChange={() => handleSmsPlanRadioChange(index, smsPlan)}
      />
    ),
  }));

  const selectedCouponTemplate = (option, props) => {
    if (option) {
      return (
        <Box>
          <Stack direction={"row"} spacing="0.38rem">
            <Box>
              <Image src={couponOfferImg} height={"2.19rem"} />
            </Box>
            <Box>
              <Stack direction={"column"} spacing="0.44rem">
                <Box>
                  <Heading
                    color={Colors.posTextInfo}
                    fontSize={"0.94rem"}
                    fontStyle={"normal"}
                    fontWeight={"500"}
                    lineHeight={"normal"}
                    letterSpacing={"-0.01rem"}
                  >
                    {option.title}
                  </Heading>
                </Box>
                <List spacing={"0.19rem"}>
                  <ListItem>
                    <Flex>
                      <Text
                        flex="1"
                        color={Colors.couponDropDownLabelColor}
                        fontSize={"0.94rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.01rem"}
                      >
                        Coupon Type:
                      </Text>
                      <Text
                        flex="1"
                        pl={"0.44rem"}
                        color={Colors.couponDropDownLabelColor}
                        fontSize={"0.94rem"}
                        fontStyle={"normal"}
                        fontWeight={"300"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.01rem"}
                      >
                        {option.coupon_type == Constants.COUPON_TYPE_PRODUCT
                          ? Constants.PRODUCT
                          : Constants.ORDER}
                      </Text>
                    </Flex>
                  </ListItem>
                  <ListItem>
                    <Flex>
                      <Text
                        flex="1"
                        color={Colors.couponDropDownLabelColor}
                        fontSize={"0.94rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.01rem"}
                      >
                        Discount Type:
                      </Text>
                      <Text
                        flex="1"
                        pl={"0.44rem"}
                        color={Colors.couponDropDownLabelColor}
                        fontSize={"0.94rem"}
                        fontStyle={"normal"}
                        fontWeight={"300"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.01rem"}
                      >
                        {option.discount_type == Constants.DISCOUNT_TYPE_AMOUNT
                          ? Constants.AMOUNT
                          : Constants.PERCENTAGE}
                      </Text>
                    </Flex>
                  </ListItem>
                  <ListItem>
                    <Flex>
                      <Text
                        flex="1"
                        color={Colors.couponDropDownLabelColor}
                        fontSize={"0.94rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.01rem"}
                      >
                        Discount Value:
                      </Text>
                      <Text
                        flex="1"
                        pl={"0.44rem"}
                        color={Colors.couponDropDownLabelColor}
                        fontSize={"0.94rem"}
                        fontStyle={"normal"}
                        fontWeight={"300"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.01rem"}
                      >
                        {option.discount_value}
                      </Text>
                    </Flex>
                  </ListItem>
                  <ListItem>
                    <Flex>
                      <Text
                        flex="1"
                        color={Colors.couponDropDownLabelColor}
                        fontSize={"0.94rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.01rem"}
                      >
                        Max Qty Per Coupon:
                      </Text>
                      <Text
                        flex="1"
                        pl={"0.44rem"}
                        color={Colors.couponDropDownLabelColor}
                        fontSize={"0.94rem"}
                        fontStyle={"normal"}
                        fontWeight={"300"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.01rem"}
                      >
                        {option.max_qty_per_coupon}
                      </Text>
                    </Flex>
                  </ListItem>
                </List>
              </Stack>
            </Box>
          </Stack>
        </Box>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const couponOptionTemplate = (option) => {
    return (
      <Box>
        <Stack direction={"row"} spacing="0.38rem">
          <Box>
            <Image src={couponOfferImg} height={"2.19rem"} />
          </Box>
          <Box>
            <Stack direction={"column"} spacing="0.44rem">
              <Box>
                <Heading
                  color={Colors.posTextInfo}
                  fontSize={"0.94rem"}
                  fontStyle={"normal"}
                  fontWeight={"500"}
                  lineHeight={"normal"}
                  letterSpacing={"-0.01rem"}
                >
                  {option.title}
                </Heading>
              </Box>
              <List spacing={"0.19rem"}>
                <ListItem>
                  <Flex>
                    <Text
                      flex="1"
                      color={Colors.couponDropDownLabelColor}
                      fontSize={"0.94rem"}
                      fontStyle={"normal"}
                      fontWeight={"500"}
                      lineHeight={"normal"}
                      letterSpacing={"-0.01rem"}
                    >
                      Coupon Type:
                    </Text>
                    <Text
                      flex="1"
                      pl={"0.44rem"}
                      color={Colors.couponDropDownLabelColor}
                      fontSize={"0.94rem"}
                      fontStyle={"normal"}
                      fontWeight={"300"}
                      lineHeight={"normal"}
                      letterSpacing={"-0.01rem"}
                    >
                      {option.coupon_type == Constants.COUPON_TYPE_PRODUCT
                        ? Constants.PRODUCT
                        : Constants.ORDER}
                    </Text>
                  </Flex>
                </ListItem>
                <ListItem>
                  <Flex>
                    <Text
                      flex="1"
                      color={Colors.couponDropDownLabelColor}
                      fontSize={"0.94rem"}
                      fontStyle={"normal"}
                      fontWeight={"500"}
                      lineHeight={"normal"}
                      letterSpacing={"-0.01rem"}
                    >
                      Discount Type:
                    </Text>
                    <Text
                      flex="1"
                      pl={"0.44rem"}
                      color={Colors.couponDropDownLabelColor}
                      fontSize={"0.94rem"}
                      fontStyle={"normal"}
                      fontWeight={"300"}
                      lineHeight={"normal"}
                      letterSpacing={"-0.01rem"}
                    >
                      {option.discount_type == Constants.DISCOUNT_TYPE_AMOUNT
                        ? Constants.AMOUNT
                        : Constants.PERCENTAGE}
                    </Text>
                  </Flex>
                </ListItem>
                <ListItem>
                  <Flex>
                    <Text
                      flex="1"
                      color={Colors.couponDropDownLabelColor}
                      fontSize={"0.94rem"}
                      fontStyle={"normal"}
                      fontWeight={"500"}
                      lineHeight={"normal"}
                      letterSpacing={"-0.01rem"}
                    >
                      Discount Value:
                    </Text>
                    <Text
                      flex="1"
                      pl={"0.44rem"}
                      color={Colors.couponDropDownLabelColor}
                      fontSize={"0.94rem"}
                      fontStyle={"normal"}
                      fontWeight={"300"}
                      lineHeight={"normal"}
                      letterSpacing={"-0.01rem"}
                    >
                      {option.discount_value}
                    </Text>
                  </Flex>
                </ListItem>
                <ListItem>
                  <Flex>
                    <Text
                      flex="1"
                      color={Colors.couponDropDownLabelColor}
                      fontSize={"0.94rem"}
                      fontStyle={"normal"}
                      fontWeight={"500"}
                      lineHeight={"normal"}
                      letterSpacing={"-0.01rem"}
                    >
                      Max Qty Per Coupon:
                    </Text>
                    <Text
                      flex="1"
                      pl={"0.44rem"}
                      color={Colors.couponDropDownLabelColor}
                      fontSize={"0.94rem"}
                      fontStyle={"normal"}
                      fontWeight={"300"}
                      lineHeight={"normal"}
                      letterSpacing={"-0.01rem"}
                    >
                      {option.max_qty_per_coupon}
                    </Text>
                  </Flex>
                </ListItem>
              </List>
            </Stack>
          </Box>
        </Stack>
      </Box>
    );
  };
  const savedAsDraftClick = () => {
    formik.setFieldValue("save_as_draft", 1);
    formik.values.save_as_draft = 1;
    onSaveAsDraft(formik.values);
  };
  const CreateClick = () => {
    formik.setFieldValue("save_as_draft", 0);
    formik.values.save_as_draft = 0;
    formik.handleSubmit();
  };

  return (
    <Flex bg={Colors.loginAuthBackground}>
      <Box flex="1" w={isScreenSize480 ? "100vw" : "calc(100vw - 263.2px)"}>
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={"flex-start"}
          pt={{ base: "1.5rem", md: "3.06rem" }}
          pb={{ base: "1.5rem", md: "0" }}
          /* pl={"1.88rem"} */
          pl={{ base: "1rem", lg: "1.88rem" }}
          pr={{ base: "1.31rem", md: "3.31rem" }}
          gap={2}
        >
          <PosBreadCrumb
            handleClick={() => {
              props.navigate(Constants.MARKETING_CAMPAIGN_PATH, {
                state: stateData.state,
              });
            }}
            breadCrumNames={["Campaigns", "Create Campaign"]}
            breadCrumTitle={"Create Campaign"}
          />
          <Spacer />
          <Stack direction={"row"} alignSelf={"flex-end"}>
            <PosFormButton
              onClick={() => {
                props.navigate(Constants.MARKETING_CAMPAIGN_PATH, {
                  state: stateData.state,
                });
              }}
              buttonText={"Cancel"}
              CancelButton={true}
              isDisabled={buttonDisable}
            />
          </Stack>
        </Flex>
        <Box
          mt={"1rem"}
          pl={{ base: "1.31rem", md: "3.31rem" }}
          pr={{ base: "1.31rem", md: "3.31rem" }}
          pb={{ base: "4.06rem", md: "4.06rem" }}
        >
          {msResponseNotGetFlag == 0 &&
            (null == businessName || "" == businessName) && (activePage === Constants.CAMPAIGN_TYPE_SCREEN || activePage === Constants.MARKETING_TYPE_SCREEN) && (
              <PosAlert
                alertStatus={"warning"}
                alertTitle={""}
                bg={Colors.errorsign}
                image={warnignIcon}
                color={Colors.orangeButton}
                width={"100%"}
                alertDescription={
                  <Text>
                    Your business name is used for SMS and Email communications.{" "}
                    <Link
                      fontWeight={"500"}
                      color={Colors.posNavbarLink}
                      onClick={() =>
                        props.navigate(
                          `${Constants.SYS_ALL_LOC_GLOBAL_SETTING}${businessRoute}`
                        )
                      }
                    >
                      Click here to add it now
                    </Link>
                    .
                  </Text>
                  // <Text>System is using "business name" while sending SMS & email for this campaign. So first, <Link fontWeight={"500"} color={Colors.posNavbarLink} onClick={() => props.navigate(Constants.PROFILE_EDIT)}>Click here</Link> to add your business name.</Text>
                }
              />
            )}
          {activePage === Constants.MARKETING_TYPE_SCREEN && (
            <Box
              mt={{ base: "2rem", md: "2rem" }}
              bg={Colors.posPageDataBackground}
            >
              <Box
                m={{ base: "2rem", md: "0" }}
                mb={0}
                pt={{ base: "4.44rem", md: "4.44rem" }}
                textAlign={"center"}
              >
                <Heading
                  fontSize={"1.50rem"}
                  fontStyle={"normal"}
                  fontWeight={"600"}
                  lineHeight={"normal"}
                  letterSpacing={"-0.02rem"}
                  color={Colors.posTextInfo}
                >
                  How would you like to send your campaign?
                </Heading>
                <Text
                  fontSize={"0.94rem"}
                  fontStyle={"normal"}
                  fontWeight={"500"}
                  lineHeight={"normal"}
                  letterSpacing={"-0.01rem"}
                  color={Colors.taxCreateExemptEbt}
                >
                  Please select a suitable way you want your customers to
                  receive your marketing campaign
                </Text>
              </Box>
              <Divider
                pt={{ base: "2rem", md: "2rem" }}
                h={"0.8px"}
                color={Colors.dividerColor}
              />
              {emailRestriction == 1 && smsRestriction == 1 ? (
                <Box
                  pt={{ base: "2rem", md: "1rem" }}
                  pl={{ base: "2.5rem", md: "4.5rem" }}
                  pr={{ base: "2.5rem", md: "4.5rem" }}
                >
                  <PosAlert
                    alertStatus={"error"}
                    alertTitle={""}
                    image={errorIcon}
                    bg={Colors.errorsign}
                    color={Colors.errorColor}
                    width={"100%"}
                    alertDescription={
                      <Text>
                        To continue, please verify your email addrress by{" "}
                        <Link
                          fontWeight={"500"}
                          color={Colors.posNavbarLink}
                          onClick={() =>
                            props.navigate(
                              Constants.MARKETING_VERIFY_EMAIL_PATH
                            )
                          }
                        >
                          clicking here
                        </Link>{" "}
                        & subscribe to an SMS plan by{" "}
                        <Link
                          fontWeight={"500"}
                          color={Colors.posNavbarLink}
                          onClick={() =>
                            props.navigate(Constants.BILLINGS_VIEW_DETAILS)
                          }
                        >
                          {" "}
                          clicking here
                        </Link>
                        .
                      </Text>
                    }
                  />
                </Box>
              ) : (
                <>
                  {emailRestriction == 1 &&
                    formik.values.marketing_type != Constants.SMS && (
                      <Box
                        pt={{ base: "2rem", md: "1rem" }}
                        pl={{ base: "2.5rem", md: "4.5rem" }}
                        pr={{ base: "2.5rem", md: "4.5rem" }}
                      >
                        <PosAlert
                          alertStatus={"error"}
                          alertTitle={""}
                          image={errorIcon}
                          bg={Colors.errorsign}
                          color={Colors.errorColor}
                          width={"100%"}
                          alertDescription={
                            <Text>
                              To continue, please verify your email address by{" "}
                              <Link
                                fontWeight={"500"}
                                color={Colors.posNavbarLink}
                                onClick={() =>
                                  props.navigate(
                                    Constants.MARKETING_VERIFY_EMAIL_PATH
                                  )
                                }
                              >
                                clicking here
                              </Link>
                              .
                            </Text>
                          }
                        />
                      </Box>
                    )}
                  {smsRestriction == 1 &&
                    formik.values.marketing_type != Constants.EMAIL && (
                      <Box
                        pt={{ base: "2rem", md: "1rem" }}
                        pl={{ base: "2.5rem", md: "4.5rem" }}
                        pr={{ base: "2.5rem", md: "4.5rem" }}
                      >
                        <PosAlert
                          alertStatus={"error"}
                          alertTitle={""}
                          image={errorIcon}
                          bg={Colors.errorsign}
                          color={Colors.errorColor}
                          width={"100%"}
                          alertDescription={
                            <Text>
                              To continue, please subscribe to an SMS plan by{" "}
                              <Link
                                fontWeight={"500"}
                                color={Colors.posNavbarLink}
                                onClick={() =>
                                  props.navigate(
                                    Constants.BILLINGS_VIEW_DETAILS
                                  )
                                }
                              >
                                {" "}
                                clicking here
                              </Link>
                              .
                            </Text>
                          }
                        />
                      </Box>
                    )}
                  {cardDetailsError == 1 && (
                    <Box
                      pt={{ base: "2rem", md: "1rem" }}
                      pl={{ base: "2.5rem", md: "4.5rem" }}
                      pr={{ base: "2.5rem", md: "4.5rem" }}
                    >
                      <PosAlert
                        alertStatus={"error"}
                        alertTitle={""}
                        image={errorIcon}
                        bg={Colors.errorsign}
                        color={Colors.errorColor}
                        width={"100%"}
                        alertDescription={
                          emailRestriction == 0 &&
                          formik.values.marketing_type == Constants.SMS ? (
                            <Text>
                              To continue, you need to add card details by{" "}
                              <Link
                                fontWeight={"500"}
                                color={Colors.posNavbarLink}
                                onClick={() =>
                                  props.navigate(
                                    Constants.BILLINGS_VIEW_DETAILS
                                  )
                                }
                              >
                                clicking here
                              </Link>
                              .
                            </Text>
                          ) : (
                            <Text>
                              To continue, you need to add card details by{" "}
                              <Link
                                fontWeight={"500"}
                                color={Colors.posNavbarLink}
                                onClick={() =>
                                  props.navigate(
                                    Constants.BILLINGS_VIEW_DETAILS
                                  )
                                }
                              >
                                clicking here
                              </Link>
                              . To continue, please verify your email address by{" "}
                              <Link
                                fontWeight={"500"}
                                color={Colors.posNavbarLink}
                                onClick={() =>
                                  props.navigate(
                                    Constants.MARKETING_VERIFY_EMAIL_PATH
                                  )
                                }
                              >
                                clicking here
                              </Link>
                              .
                            </Text>
                          )
                        }
                      />
                    </Box>
                  )}
                </>
              )}
              <Box
                display={"flex"}
                justifyContent={"center"}
                m={{ base: "2.5rem", md: "2rem" }}
              >
                <Stack
                  {...marketingTypeGroup}
                  direction={!isMobile ? "row" : "column"}
                  spacing={"1.5rem"}
                >
                  {marketingOptions.map((option, index) => {
                    const radio = getMarketingTypeRadioProps({
                      value: option.value,
                    });
                    return (
                      <RadioCard
                        key={index}
                        {...radio}
                        isChecked={
                          formik.values.marketing_type === option.value
                        }
                        maxWidth="18.50rem"
                        borderRadius="0.63rem"
                      >
                        <Card h={"17.55rem"} borderRadius="0.63rem">
                          <CardBody p={"0rem"}>
                            <Box
                              h={"10.38rem"}
                              bg={Colors.campCardBgColor}
                              position={"relative"}
                            >
                              <Image
                                margin={"auto"}
                                position={"absolute"}
                                top={"0"}
                                left={"0"}
                                right={"0"}
                                bottom={"0"}
                                src={option.imageName}
                                alt="Octopos"
                              />
                            </Box>
                            <Box
                              paddingX={{ base: "1rem", md: "1.5rem" }}
                              pt={{ base: "1.81rem", md: "1.81rem" }}
                              textAlign={"center"}
                            >
                              <Heading
                                fontSize={"1.13rem"}
                                fontStyle={"normal"}
                                fontWeight={"500"}
                                lineHeight={"normal"}
                                letterSpacing={"-0.02rem"}
                                color={Colors.posTextInfo}
                              >
                                {option.type}
                              </Heading>
                              <Text
                                fontSize={"0.94rem"}
                                fontStyle={"normal"}
                                fontWeight={"500"}
                                lineHeight={"normal"}
                                letterSpacing={"-0.01rem"}
                                color={Colors.taxCreateExemptEbt}
                              >
                                {option.description}
                              </Text>
                            </Box>
                          </CardBody>
                        </Card>
                      </RadioCard>
                    );
                  })}
                </Stack>
              </Box>
              <Box pb={{ base: "4.25rem", md: "4.25rem" }} textAlign={"center"}>
                <PosFormButton
                  buttonsubmit={"Next Step"}
                  width={!isScreenSize480 ? "22.13rem" : "11.60rem"}
                  onClick={() => {
                    if (msResponseNotGetFlag == 1) {
                      addToast({
                        alertStatus: Constants.TOAST_TYPE_WARNING,
                        alertTitle: Constants.CAMPAIGN_MODULE,
                        alertDescription:
                          Constants.MICROSERVICE_RESPONSE_GET_ERROR,
                      });
                    } else if (smsNumberExist == 0) {
                      onSmsNumberNotFoundAlertOpen();
                    } else {
                      setActivePage(Constants.CAMPAIGN_TYPE_SCREEN);
                    }
                  }}
                  isDisabled={
                    null == formik.values.marketing_type ||
                    "" == formik.values.marketing_type ||
                    emailRestriction == 1 ||
                    smsRestriction == 1 ||
                    cardDetailsError == 1
                  }
                />
              </Box>
            </Box>
          )}
          {activePage === Constants.CAMPAIGN_TYPE_SCREEN && (
            <Box
              mt={{ base: "2rem", md: "2rem" }}
              bg={Colors.posPageDataBackground}
            >
              <Stack direction={"column"}>
                <Box margin={!isMobile ? "0" : "0 auto"}>
                  <Link
                    mt={"1.44rem"}
                    ml={"2rem"}
                    bg={Colors.forgotBackButtone}
                    h={12}
                    w={12}
                    border-radius={2.5}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    onClick={() =>
                      setActivePage(Constants.MARKETING_TYPE_SCREEN)
                    }
                  >
                    <ArrowBackIcon boxSize={6} color={Colors.blackIcon} />
                  </Link>
                </Box>
                <Box m={{ base: "2rem", md: "0" }} mb={0} textAlign={"center"}>
                  <Heading
                    fontSize={"1.50rem"}
                    fontStyle={"normal"}
                    fontWeight={"600"}
                    lineHeight={"normal"}
                    letterSpacing={"-0.02rem"}
                    color={Colors.posTextInfo}
                  >
                    What type of campaign would you like to send?
                  </Heading>
                  <Text
                    fontSize={"0.94rem"}
                    fontStyle={"normal"}
                    fontWeight={"500"}
                    lineHeight={"normal"}
                    letterSpacing={"-0.01rem"}
                    color={Colors.taxCreateExemptEbt}
                  >
                    Choose campaign type you consider your customers will
                    benefit from
                  </Text>
                </Box>
              </Stack>
              <Divider
                pt={{ base: "2rem", md: "2rem" }}
                h={"0.8px"}
                color={Colors.dividerColor}
              />
              <Box
                display={"flex"}
                justifyContent={"center"}
                m={{ base: "2.5rem", md: "2rem" }}
              >
                <Stack
                  {...campaignTypeGroup}
                  direction={!isMobile ? "row" : "column"}
                  spacing={"1.5rem"}
                >
                  {campaignOptions.map((option, index) => {
                    const optionValue = option.value;
                    const radio = getCampaignTypeRadioProps({
                      value: option.value,
                    });
                    return (
                      <RadioCard
                        key={optionValue}
                        {...radio}
                        isChecked={formik.values.campaign_type === option.value}
                        maxWidth="18.50rem"
                        borderRadius="0.63rem"
                      >
                        <Card h={"19rem"} borderRadius="0.63rem">
                          <CardBody p={"0rem"}>
                            <Box
                              h={"10.38rem"}
                              bg={Colors.campCardBgColor}
                              position={"relative"}
                            >
                              <Image
                                margin={"auto"}
                                position={"absolute"}
                                top={"0"}
                                left={"0"}
                                right={"0"}
                                bottom={"0"}
                                src={option.imageName}
                                alt="Octopos"
                              />
                            </Box>
                            <Box
                              paddingX={{ base: "1rem", md: "1.5rem" }}
                              pt={{ base: "1.81rem", md: "1.81rem" }}
                              textAlign={"center"}
                            >
                              <Heading
                                fontSize={"1.13rem"}
                                fontStyle={"normal"}
                                fontWeight={"500"}
                                lineHeight={"normal"}
                                letterSpacing={"-0.02rem"}
                                color={Colors.posTextInfo}
                              >
                                {option.type}
                              </Heading>
                              <Text
                                fontSize={"0.94rem"}
                                fontStyle={"normal"}
                                fontWeight={"500"}
                                lineHeight={"normal"}
                                letterSpacing={"-0.01rem"}
                                color={Colors.taxCreateExemptEbt}
                              >
                                {option.description}
                              </Text>
                            </Box>
                          </CardBody>
                        </Card>
                      </RadioCard>
                    );
                  })}
                </Stack>
              </Box>
              <Box pb={{ base: "4.25rem", md: "4.25rem" }} textAlign={"center"}>
                <PosFormButton
                  buttonsubmit={"Set Up Campaign"}
                  width={!isScreenSize480 ? "22.13rem" : "11.60rem"}
                  onClick={() => {
                    setActivePage(Constants.BASIC_DETAILS_SCREEN);
                    getSmsPlanDetails();
                  }}
                  isDisabled={
                    null == formik.values.marketing_type ||
                    "" == formik.values.marketing_type ||
                    null == formik.values.campaign_type ||
                    "" == formik.values.campaign_type
                  }
                />
              </Box>
            </Box>
          )}
          {activePage === Constants.BASIC_DETAILS_SCREEN && (
            <Box mt={{ base: "2rem", md: "2rem" }}>
              <Stack spacing={"0rem"}>
                <Heading
                  fontSize={"1.13rem"}
                  fontStyle={"normal"}
                  fontWeight={"500"}
                  lineHeight={"normal"}
                  letterSpacing={"-0.02rem"}
                  color={Colors.posTextInfo}
                >
                  Please Provide details for your Campaign
                </Heading>
                <Text
                  fontSize={"0.94rem"}
                  fontStyle={"normal"}
                  fontWeight={"500"}
                  lineHeight={"normal"}
                  letterSpacing={"-0.01rem"}
                  color={Colors.taxCreateExemptEbt}
                >
                  Create a successful campaign with clear and well-defined
                  information
                </Text>
              </Stack>
              <Flex
                mt={{ base: "2rem", md: "2rem" }}
                bg={Colors.posPageDataBackground}
              >
                <Box flex={"1"} p={{ base: "2.31rem", md: "2.31rem" }}>
                  <Box>
                    <Heading
                      as={"h3"}
                      color={Colors.posTextInfo}
                      fontSize={"1.13rem"}
                      fontStyle={"normal"}
                      fontWeight={"500"}
                      lineHeight={"normal"}
                      letterSpacing={"-0.02rem"}
                    >
                      Basic Details
                    </Heading>
                  </Box>
                  <Stack
                    direction={{ base: "column", md: "row" }}
                    gap={isMobile ? "0" : "3rem"}
                  >
                    <Box>
                      <PosLable
                        label={true}
                        name={"Campaign Category"}
                        fontWeight={"500"}
                      />
                      <PosInput
                        id="name"
                        alt="Input Image"
                        placeholder=""
                        posInputGroup={true}
                        inputType={"text"}
                        inputValue={
                          undefined !=
                          marketingOptions.find(
                            (element) =>
                              element.value == formik.values.marketing_type
                          )
                            ? marketingOptions.find(
                                (element) =>
                                  element.value == formik.values.marketing_type
                              ).type
                            : marketingOptions.find(
                                (element) =>
                                  element.value == formik.values.typeValue
                              ).type
                        }
                        disabled={true}
                      >
                        <DriveFileRenameOutlineIcon
                          sx={{
                            height: "1.21rem",
                            width: "1.21rem",
                            background: Colors.posInputGroupBackground,
                            borderRadius: "0.31rem",
                            color: Colors.posNavbarLink,
                          }}
                        />
                      </PosInput>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Campaign Type"}
                        fontWeight={"500"}
                      />
                      <PosInput
                        id="name"
                        alt="Input Image"
                        placeholder=""
                        posInputGroup={true}
                        inputType={"text"}
                        inputValue={
                          campaignOptions.find(
                            (element) =>
                              element.value == formik.values.campaign_type
                          ).type
                        }
                        disabled={true}
                      >
                        <DriveFileRenameOutlineIcon
                          sx={{
                            height: "1.21rem",
                            width: "1.21rem",
                            background: Colors.posInputGroupBackground,
                            borderRadius: "0.31rem",
                            border: "none",
                            color: Colors.posNavbarLink,
                          }}
                        />
                      </PosInput>
                    </Box>
                  </Stack>
                  <Box>
                    <PosLable
                      requiredLabel={true}
                      name={"Name"}
                      mt="0.31rem"
                      fontWeight={"500"}
                    />
                    <PosInput
                      id="title"
                      alt="Input Image"
                      placeholder={"Name"}
                      handleInputChange={formik.handleChange}
                      inputValue={formik.values.title}
                      handleBlur={(e) => {
                        formik.setFieldValue(
                          "title",
                          formik.values.title.trim()
                        );
                        formik.handleBlur(e);
                      }}
                      posInputGroup={true}
                      inputType={"text"}
                      inputError={formik.touched.title && formik.errors.title}
                    >
                      <DriveFileRenameOutlineIcon
                        sx={{
                          height: "1.21rem",
                          width: "1.21rem",
                          background: Colors.posInputGroupBackground,
                          borderRadius: "0.31rem",
                          color: Colors.posNavbarLink,
                        }}
                      />
                    </PosInput>
                    <Text color={Colors.errorColor}>
                      {formik.touched.title && formik.errors.title ? (
                        <span>{formik.errors.title}</span>
                      ) : null}
                    </Text>
                  </Box>
                  {formik.values.campaign_type == Constants.TARGETED && (
                    <Box mt={{ base: "2rem", md: "2rem" }}>
                      <Heading
                        color={Colors.posTextInfo}
                        fontSize={"0.94rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.01rem"}
                      >
                        Campaign Duration
                      </Heading>
                      <Stack
                        direction={{ base: "column", md: "row" }}
                        gap={isMobile ? "0" : "2rem"}
                        pt={{ base: "0.88rem", md: "0.88rem" }}
                      >
                        <Box>
                          <Text fontSize={"0.94rem"}>
                            Start Date
                            <Text as="span" color={Colors.errorColor}>
                              *
                            </Text>
                          </Text>
                          <PosDatePicker
                            labelColor={Colors.posShowFields}
                            value={formik.values.start_date}
                            label={"Select Start Date"}
                            selectOtherMonths={true}
                            minDate={dateTimeValue}
                            showButtonBar={true}
                            readOnlyInput={true}
                            // monthNavigator={true}
                            // yearNavigator={true}
                            // yearRange={"2000:2030"}
                            showTime={true}
                            handleSelectData={(e) => {
                              effectRun.current = true;
                              formik.setFieldValue("start_date", e.value);
                            }}
                            onFocus={() => {
                              formik.setFieldTouched("start_date", true);
                            }}
                          />
                          <Text color={Colors.errorColor}>
                            {formik.touched.start_date &&
                            formik.errors.start_date ? (
                              <span>{formik.errors.start_date}</span>
                            ) : null}
                          </Text>
                        </Box>
                        <Box mt={{ base: "2rem", md: "0" }}>
                          <Text fontSize={"0.94rem"}>
                            End Date
                            <Text as="span" color={Colors.errorColor}>
                              *
                            </Text>
                          </Text>
                          <PosDatePicker
                            labelColor={Colors.posShowFields}
                            value={formik.values.end_date}
                            label={"Select End Date"}
                            selectOtherMonths={true}
                            // minDate={null != formik.values.start_date && '' != formik.values.start_date ? formik.values.start_date : dateTimeValue}
                            minDate={
                              null != smsLastMinDate &&
                              "" != smsLastMinDate &&
                              smsLastMinDate > formik.values.start_date
                                ? smsLastMinDate
                                : null != formik.values.start_date &&
                                  "" != formik.values.start_date
                                ? formik.values.start_date
                                : dateTimeValue
                            }
                            showButtonBar={true}
                            readOnlyInput={true}
                            // monthNavigator={true}
                            // yearNavigator={true}
                            // yearRange={"2000:2030"}
                            showTime={true}
                            handleSelectData={(e) => {
                              effectRun.current = true;
                              formik.setFieldValue("end_date", e.value);
                            }}
                            onFocus={() => {
                              formik.setFieldTouched("end_date", true);
                            }}
                          />
                          <Text color={Colors.errorColor}>
                            {formik.touched.end_date &&
                            formik.errors.end_date ? (
                              <span>{formik.errors.end_date}</span>
                            ) : null}
                          </Text>
                        </Box>
                      </Stack>
                    </Box>
                  )}
                  <Stack
                    direction={{ base: "column", md: "row" }}
                    gap={isMobile ? "0" : "5.2rem"}
                    pb={{ base: "2.81rem", md: "2.81rem" }}
                  >
                    <Box>
                      <PosLable
                        requiredLabel={true}
                        name={"When you want to Schedule?"}
                        fontWeight={"500"}
                      />
                      <SelectButton
                        id="scheduled_type"
                        value={formik.values.scheduled_type}
                        onChange={(e) => {
                          formik.handleChange(e);
                          onChangeScheduleType(e);
                        }}
                        options={scheduledOptions}
                        allowEmpty={false}
                      />
                    </Box>
                    {formik.values.scheduled_type ==
                      Constants.SCHEDULED_LATER && (
                      <Box>
                        <PosLable
                          requiredLabel={true}
                          name={"When do you want to schedule this Campaign?"}
                          fontWeight={"500"}
                        />
                        <PosDatePicker
                          value={formik.values.scheduled_start_date_time}
                          label={"Select Schedule"}
                          selectOtherMonths={true}
                          minDate={dateTimeValue}
                          maxDate={
                            null != maxEmailSendDate
                              ? maxEmailSendDate
                              : dateTimeValue
                          }
                          showButtonBar={true}
                          readOnlyInput={true}
                          // monthNavigator={true}
                          // yearNavigator={true}
                          // yearRange={"2000:2030"}
                          showTime={true}
                          handleSelectData={(e) => {
                            effectRun.current = true;
                            formik.setFieldValue(
                              "scheduled_start_date_time",
                              e.value
                            );
                          }}
                          onFocus={() => {
                            formik.setFieldTouched(
                              "scheduled_start_date_time",
                              true
                            );
                          }}
                        />
                        <Text color={Colors.errorColor}>
                          {formik.touched.scheduled_start_date_time &&
                          formik.errors.scheduled_start_date_time ? (
                            <span>
                              {formik.errors.scheduled_start_date_time}
                            </span>
                          ) : null}
                        </Text>
                      </Box>
                    )}
                  </Stack>
                </Box>
              </Flex>
              {formik.values.campaign_type == Constants.TARGETED && (
                <Box mt={{ base: "0.87rem" }} bg={Colors.posPageDataBackground}>
                  <Box
                    pt={{ base: "2.31rem", md: "2.31rem" }}
                    pb={{ base: "2.31rem", md: "2.31rem" }}
                    pl={{ base: "2.31rem", md: "2.31rem" }}
                    pr={{ base: "2.31rem", md: "2.31rem" }}
                  >
                    <Heading
                      color={Colors.posTextInfo}
                      fontSize={"1.13rem"}
                      fontStyle={"normal"}
                      fontWeight={"500"}
                      lineHeight={"normal"}
                      letterSpacing={"-0.02rem"}
                    >
                      Coupon Details
                    </Heading>
                    <Box>
                      <PosLable
                        requiredLabel={true}
                        name={"Please select a Coupon"}
                        fontWeight={"500"}
                      />
                      <Dropdown
                        onChange={(e) => onSelectCoupon(e)}
                        options={couponData}
                        optionLabel="title"
                        placeholder="--Select a Coupon--"
                        filter
                        valueTemplate={selectedCouponTemplate}
                        itemTemplate={couponOptionTemplate}
                        className="w-full md:w-14rem"
                        style={{ width: "100%" }}
                        resetFilterOnHide={true}
                      />
                    </Box>
                    {selectedCouponsArray.length > 0 && (
                      <Box mt={{ base: "2rem", md: "2rem" }}>
                        <PosTable
                          columnNames={couponDetailColumns}
                          rawdata={finalSelectedCoupons}
                          totalcount={selectedCouponsArray.length}
                          footerHide={true}
                          singleDeleteButton={true}
                          goSingleDelete={(id) => onDeleteCoupon(id)}
                          sortColumnName={null}
                          page={null}
                          rowsPerPage={null}
                          order={null}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
              <Flex mt={{ base: "3rem", md: "3rem" }}>
                <PosFormButton
                  onClick={() => onOpen()}
                  buttonText={"Back"}
                  ghostButton={true}
                />
                <Spacer />
                <PosFormButton
                  isDisabled={
                    formik.values.title.length <= 0 || formik.errors.title
                      ? true
                      : false
                  }
                  // buttonsubmit={"Save as Draft"}
                  // SubmitButton={true}
                  buttonText={"Save as Draft"}
                  CancelButton={true}
                  onClick={() => {
                    savedAsDraftClick();
                  }}
                />
                <PosFormButton
                  isDisabled={
                    formik.values.title.length <= 0 ||
                    formik.errors.title ||
                    formik.errors.start_date ||
                    formik.errors.end_date ||
                    formik.errors.scheduled_type ||
                    (Constants.SCHEDULED_LATER ==
                      formik.values.scheduled_type &&
                      (undefined == formik.values.scheduled_start_date_time ||
                        null == formik.values.scheduled_start_date_time ||
                        "" == formik.values.scheduled_start_date_time)) ||
                    (formik.values.campaign_type == Constants.TARGETED &&
                      selectedCouponsArray.length == 0)
                      ? true
                      : false
                  }
                  buttonsubmit={"Next"}
                  SubmitButton={true}
                  onClick={() =>
                    handleNextBtnClickRedirection(
                      Constants.BASIC_DETAILS_SCREEN
                    )
                  }
                />
              </Flex>
            </Box>
          )}
          {activePage === Constants.EMAIL_TEMPLATE_SELECTION_SCREEN && (
            <Box mt={{ base: "2rem", md: "2rem" }}>
              <Stack spacing={"0rem"}>
                <Heading
                  fontSize={"1.13rem"}
                  fontStyle={"normal"}
                  fontWeight={"500"}
                  lineHeight={"normal"}
                  letterSpacing={"-0.02rem"}
                  color={Colors.posTextInfo}
                >
                  New Email
                </Heading>
                <Text
                  fontSize={"0.94rem"}
                  fontStyle={"normal"}
                  fontWeight={"500"}
                  lineHeight={"normal"}
                  letterSpacing={"-0.01rem"}
                  color={Colors.taxCreateExemptEbt}
                >
                  Choose a starting point for your email design
                </Text>
              </Stack>
              <Box
                display={"flex"}
                justifyContent={"center"}
                mt={{ base: "2rem", md: "2rem" }}
              >
                <Wrap
                  {...emailTemplateGroup}
                  spacing={"1.13rem"}
                  justify="center"
                >
                  {predefinedEmailTemplates.map((option, index) => {
                    const radio = getEmailTemplateRadioProps({
                      value: option.value,
                    });
                    return (
                      <WrapItem key={index}>
                        <Stack direction={"column"} spacing={"0.88rem"}>
                          <RadioCard
                            {...radio}
                            maxWidth="18.50rem"
                            borderRadius="0.63rem"
                          >
                            <Card borderRadius="0.63rem">
                              <CardBody p={"0rem"}>
                                <Box
                                  h={"16rem"}
                                  pl={{ base: "2.81rem", md: "2.81rem" }}
                                  pr={{ base: "2.81rem", md: "2.81rem" }}
                                  pt={{ base: "3.06rem", md: "3.06rem" }}
                                  pb={{ base: "2.88rem", md: "2.88rem" }}
                                >
                                  <Box
                                    bg={Colors.posPageDataBackground}
                                    h={"10.06rem"}
                                    pl={{ base: "2.63rem", md: "2.63rem" }}
                                    pr={{ base: "2.63rem", md: "2.63rem" }}
                                    pt={{ base: "1.44rem", md: "1.44rem" }}
                                  >
                                    <Image
                                      h={"2.25rem"}
                                      src={emailTempBoxImg}
                                      alt="Octopos"
                                    />
                                    <List
                                      spacing={"0.56rem"}
                                      mt={{ base: "1.38rem", md: "1.38rem" }}
                                    >
                                      <ListItem>
                                        <Divider
                                          h={"0.44rem"}
                                          bg={Colors.emailTempDividerColor}
                                          borderRadius={"0.63rem"}
                                          border={"0.5px solid #E8E8E8"}
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <Divider
                                          h={"0.44rem"}
                                          bg={Colors.emailTempDividerColor}
                                          borderRadius={"0.63rem"}
                                          border={"0.5px solid #E8E8E8"}
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <Divider
                                          h={"0.44rem"}
                                          bg={Colors.emailTempDividerColor}
                                          borderRadius={"0.63rem"}
                                          border={"0.5px solid #E8E8E8"}
                                        />
                                      </ListItem>
                                    </List>
                                  </Box>
                                </Box>
                              </CardBody>
                            </Card>
                          </RadioCard>
                          <Box wordBreak={"break-all"} textAlign={"center"}>
                            {option.name}
                          </Box>
                        </Stack>
                      </WrapItem>
                    );
                  })}
                </Wrap>
              </Box>
              <Flex mt={{ base: "3rem", md: "3rem" }}>
                <PosFormButton
                  onClick={() =>
                    handlePreviousBtnClickRedirection(
                      Constants.EMAIL_TEMPLATE_SELECTION_SCREEN
                    )
                  }
                  buttonText={"Previous"}
                  ghostButton={true}
                />
                <Spacer />
                <PosFormButton
                  isDisabled={
                    formik.values.title.length <= 0 || formik.errors.title
                      ? true
                      : false
                  }
                  buttonText={"Save as Draft"}
                  CancelButton={true}
                  onClick={() => {
                    savedAsDraftClick();
                  }}
                />
                <PosFormButton
                  buttonsubmit={"Next"}
                  SubmitButton={true}
                  onClick={() => {
                    handleNextBtnClickRedirection(
                      Constants.EMAIL_TEMPLATE_SELECTION_SCREEN
                    );
                  }}
                />
              </Flex>
            </Box>
          )}
          {activePage === Constants.EMAIL_DESIGN_SCREEN && (
            <Box mt={{ base: "2rem", md: "2rem" }}>
              <Flex
                flexDirection={!isMobile ? "row" : "column"}
                minWidth="max-content"
                alignItems="center"
                gap="2"
                bg={Colors.headerColor}
                borderRadius={"0.50rem"}
              >
                <Box
                  pl={{ base: "1.13rem", md: "1.13rem" }}
                  pt={{ base: "1rem", md: "1rem" }}
                  pb={{ base: "1rem", md: "1rem" }}
                  pr={!isMobile ? "0rem" : "1.13rem"}
                  w={{ base: "100%", md: "60%" }}
                >
                  <PosInput
                    posInputGroup={true}
                    id={"subject_line"}
                    inputType={"text"}
                    placeholder={"Email Subject Here"}
                    inputValue={subjectLineValue}
                    handleInputChange={(e) =>
                      onChangeSubjectLine(e.target.value)
                    }
                    handleBlur={(e) => {
                      setSubjectLineValue(e.target.value.trim());
                    }}
                  >
                    <EditIcon style={{ fill: Colors.taxInfo }} />
                  </PosInput>
                  <Text color={Colors.errorColor}>
                    {subjectLineValidationError && (
                      <span>{subjectLineValidationError}</span>
                    )}
                  </Text>
                </Box>
                <Spacer />
                <Box
                  pr={{ base: "1.13rem", md: "1.13rem" }}
                  pb={!isMobile ? "0rem" : "1rem"}
                  justifyContent={"flex-end"}
                >
                  <Flex flexDirection={{ base: "column", md: "row" }} gap={2}>
                    <PosIconButton
                      disabled={
                        undefined == subjectLineValue ||
                        null == subjectLineValue ||
                        "" == subjectLineValue ||
                        subjectLineValidationError
                          ? // || !selectedEmailTemplate
                            true
                          : false
                      }
                      name={"Send Test Email"}
                      onClick={() => {
                        // updateEmailTemaplteData();
                        openSendEmailModal();
                      }}
                      sendIcon={true}
                    />
                  </Flex>
                </Box>
              </Flex>
              <Box
                maxWidth={"100%"}
                mt={{ base: "0.75rem", md: "0.75rem" }}
                ref={editorRef}
                id="designEmail"
              ></Box>
              <Flex mt={{ base: "3rem", md: "3rem" }}>
                <PosFormButton
                  onClick={() =>
                    handlePreviousBtnClickRedirection(
                      Constants.EMAIL_DESIGN_SCREEN
                    )
                  }
                  buttonText={"Previous"}
                  ghostButton={true}
                />
                <Spacer />
                <PosFormButton
                  isDisabled={
                    formik.values.title.length <= 0 || formik.errors.title
                      ? true
                      : false
                  }
                  // buttonsubmit={"Save as Draft"}
                  // SubmitButton={true}
                  buttonText={"Save as Draft"}
                  CancelButton={true}
                  onClick={() => {
                    savedAsDraftClick();
                  }}
                />
                <PosFormButton
                  isDisabled={
                    undefined == subjectLineValue ||
                    null == subjectLineValue ||
                    "" == subjectLineValue ||
                    subjectLineValidationError
                      ? // || !selectedEmailTemplate
                        true
                      : false
                  }
                  buttonsubmit={"Next"}
                  SubmitButton={true}
                  onClick={() => {
                    handleNextBtnClickRedirection(
                      Constants.EMAIL_DESIGN_SCREEN
                    );
                  }}
                />
              </Flex>
            </Box>
          )}
          {activePage === Constants.SMS_DESIGN_SCREEN && (
            <Box mt={{ base: "2rem", md: "2rem" }}>
              <Box>SMS Design Screen</Box>
              <Flex
                mt={{ base: "2rem", md: "2rem" }}
                gap={"0.63rem"}
                flexDirection={!isMobile ? "row" : "column"}
              >
                <Box flex={1}>
                  <PosLable
                    label={true}
                    name={"SMS Content"}
                    fontStyle="normal"
                    fontSize="0.94rem"
                    fontWeight={300}
                    lineHeight="normal"
                    letterSpacing="-0.01rem"
                  />
                  <Textarea
                    id="sms_dtls"
                    placeholder=""
                    borderRadius={"0.63rem"}
                    border={Colors.posTextAreaBorder}
                    background={Colors.posInputBackground}
                    value={smsTextValue}
                    onChange={(e) => onChangeSmsText(e.target.value)}
                    onBlur={(e) => onChangeSmsText(e.target.value.trim())}
                  />
                  {null != smsTextValue &&
                    "" != smsTextValue &&
                    160 - smsTextValue.length > 0 && (
                      <Text>
                        {160 - smsTextValue.length} characters left for sms
                        text.
                      </Text>
                    )}
                  <Text color={Colors.errorColor}>
                    {smsTextValidationError && (
                      <span>{smsTextValidationError}</span>
                    )}
                  </Text>
                </Box>
                <Box
                  flex={1}
                  mt={{ base: "1rem", md: "0" }}
                  justifyContent={"center"}
                  alignContent={"center"}
                  alignSelf={"center"}
                >
                  <div className="mobile-screen">
                    {smsTextValue.replace(/ /g, "").length > 0 && (
                      <div className="sms-preview">{smsTextValue}</div>
                    )}
                  </div>
                </Box>
              </Flex>
              <Flex mt={{ base: "3rem", md: "3rem" }}>
                <PosFormButton
                  onClick={() =>
                    handlePreviousBtnClickRedirection(
                      Constants.SMS_DESIGN_SCREEN
                    )
                  }
                  buttonText={"Previous"}
                  ghostButton={true}
                />
                <Spacer />
                <PosFormButton
                  isDisabled={
                    formik.values.title.length <= 0 || formik.errors.title
                      ? true
                      : false
                  }
                  // buttonsubmit={"Save as Draft"}
                  // SubmitButton={true}
                  buttonText={"Save as Draft"}
                  CancelButton={true}
                  onClick={() => {
                    savedAsDraftClick();
                  }}
                />
                <PosFormButton
                  isDisabled={
                    undefined == smsTextValue ||
                    null == smsTextValue ||
                    "" == smsTextValue ||
                    smsTextValidationError
                      ? true
                      : false
                  }
                  buttonsubmit={"Next"}
                  SubmitButton={true}
                  onClick={() => {
                    handleNextBtnClickRedirection(Constants.SMS_DESIGN_SCREEN);
                  }}
                />
              </Flex>
            </Box>
          )}
          {activePage === Constants.AUDIENCE_SCREEN && (
            <Box mt={{ base: "2rem", md: "2rem" }}>
              <Flex
                flexDirection={isMobile ? "column" : "row"}
                gap={4}
                alignItems="stretch"
              >
                <Box
                  p={"1.25rem"}
                  bg={Colors.posPageDataBackground}
                  width={isMobile ? "100%" : "50%"}
                >
                  <Stack spacing={"0rem"}>
                    <Heading
                      fontSize={"1.13rem"}
                      fontStyle={"normal"}
                      fontWeight={"500"}
                      lineHeight={"normal"}
                      letterSpacing={"-0.02rem"}
                      color={Colors.posTextInfo}
                    >
                      Ready to Send?
                    </Heading>
                    <Text
                      fontSize={"0.94rem"}
                      fontStyle={"normal"}
                      fontWeight={"500"}
                      lineHeight={"normal"}
                      letterSpacing={"-0.01rem"}
                      color={Colors.taxCreateExemptEbt}
                    >
                      Choose user(s) you want to receive this campaign
                    </Text>
                  </Stack>
                  <Stack
                    mt={{ base: "0.88rem", md: "0.88rem" }}
                    spacing={"1.06rem"}
                    {...audienceTypeGroup}
                    flexDirection={"row"}
                  >
                    {audienceType.map((option, index) => {
                      const radio = getAudienceTypeRadioProps({
                        value: option.value,
                      });
                      return (
                        <RadioCard
                          key={index}
                          isChecked={
                            formik.values.select_audience === option.value
                          }
                          maxWidth="25.50rem"
                          borderRadius="0.63rem"
                          {...radio}
                          isDisabled={
                            customerCancelPlanSubscription == 1 &&
                            scheduleNextMonth == 1
                          }
                        >
                          <Box
                            p={"1.5rem"}
                            bg={Colors.posPageDataBackground}
                            borderRadius="0.63rem"
                          >
                            <Text
                              fontSize={"0.94rem"}
                              fontStyle={"normal"}
                              fontWeight={"500"}
                              lineHeight={"normal"}
                              letterSpacing={"-0.01rem"}
                              color={Colors.posTextInfo}
                            >
                              {option.label}
                            </Text>
                            <Text
                              pt={{ base: "0.63rem", md: "0.63rem" }}
                              fontSize={"0.94rem"}
                              fontStyle={"normal"}
                              fontWeight={"300"}
                              lineHeight={"normal"}
                              letterSpacing={"-0.01rem"}
                              color={Colors.couponDropDownLabelColor}
                            >
                              {option.description}
                            </Text>
                          </Box>
                        </RadioCard>
                      );
                    })}
                  </Stack>
                  {formik.values.select_audience == Constants.SELECT_SEGMENT &&
                    selectSegmentFlag == 1 && (
                      <Box mt={{ base: "2rem", md: "2rem" }}>
                        <PosLable
                          label={true}
                          name={"Select Segment"}
                          fontWeight={"500"}
                        />
                        <PosDropDown
                          options={segmentData}
                          value={formik.values.selectedSegments}
                          onChange={onSelectSegment}
                          onBlur={(e) => {
                            formik.setFieldTouched("selectedSegments", true);
                            formik.handleBlur(e);
                          }}
                          onHide={onHideSegmentDropdownPanel}
                          multiSelect={true}
                          optionLabel="name"
                          placeholder="--Select Segment--"
                          width={!isScreenSize768 ? "60%" : "100%"}
                          height={"2.5rem"}
                          className="w-full md:w-20rem"
                          maxSelectedLabels={2}
                          resetFilterOnHide={true}
                        />
                        <Text color={Colors.errorColor}>
                          {formik.touched.selectedSegments &&
                          formik.errors.selectedSegments ? (
                            <span>{formik.errors.selectedSegments}</span>
                          ) : null}
                        </Text>
                      </Box>
                    )}
                  {formik.values.marketing_type != Constants.EMAIL ? (
                    <Box mt={{ base: "2rem", md: "2rem" }}>
                      {scheduleNextMonth == 0 &&
                        customerSmsPlanSubscribed == 0 &&
                        numberOfCampUser > 0 &&
                        customerCancelPlanSubscription == 0 && (
                          <Card>
                            <CardHeader>
                              <Heading color={Colors.errorColor} size="md">
                                Alert!!
                              </Heading>
                            </CardHeader>
                            <Divider />
                            <CardBody>
                              <Text>
                              {numberOfCampUserEmail > 0 ? numberOfCampUserEmail+" Email user(s) are eligible for this campaign & " : (formik.values.marketing_type == Constants.EMAIL || formik.values.marketing_type == Constants.SMS_EMAIL_BOTH) ? "No Email user(s) are eligible for this campaign & " : ""}
                                {numberOfCampUser} SMS user(s) are eligible for this
                                campaign. To proceed further please{" "}
                                <Link
                                  fontSize={"0.94rem"}
                                  fontStyle={"normal"}
                                  fontWeight={"500"}
                                  lineHeight={"normal"}
                                  letterSpacing={"-0.01rem"}
                                  color={Colors.posNavbarLink}
                                  onClick={() => openSmsPlanModel(1)}
                                >
                                  Click here
                                </Link>{" "}
                                to subscribe the SMS plan.
                              </Text>
                            </CardBody>
                          </Card>
                        )}
                      {maxSmsPlanSelection == 1 && (
                        <Card>
                          <CardHeader>
                            <Heading color={Colors.errorColor} size="md">
                              Alert!!
                            </Heading>
                          </CardHeader>
                          <Divider />
                          <CardBody>
                            <Text>
                              Plan subscription SMS have been used. Please
                              create this campaign in next month.
                            </Text>
                          </CardBody>
                        </Card>
                      )}
                      {(customerSmsPlanSubscribed == 1 ||
                        customerCancelPlanSubscription == 1) &&
                        numberOfCampUser > 0 &&
                        additionalSms == 0 && (
                          <>
                            <Heading
                              mb={4}
                              fontSize={"1.13rem"}
                              fontStyle={"normal"}
                              fontWeight={"300"}
                              lineHeight={"normal"}
                              letterSpacing={"-0.02rem"}
                              color={Colors.posTextInfo}
                            >
                              {numberOfCampUserEmail > 0 ? numberOfCampUserEmail+" Email user(s) are eligible for this campaign & " : (formik.values.marketing_type == Constants.EMAIL || formik.values.marketing_type == Constants.SMS_EMAIL_BOTH) ? "No Email user(s) are eligible for this campaign & " : ""}
                              {numberOfCampUser} SMS user(s) are eligible for this
                              campaign.{" "}
                              {formik.values.campaign_type == Constants.TARGETED && ('' === formik.values.end_date || null == formik.values.end_date || undefined == formik.values.end_date)
                                ? "You need to change the end date of the campaign on or after last SMS sent date."
                                : ""}{" "}
                            </Heading>
                            {formik.values.campaign_type ==
                              Constants.TARGETED && (
                              <Box mt={{ base: "2rem", md: "2rem" }}>
                                <Heading
                                  color={Colors.posTextInfo}
                                  fontSize={"0.94rem"}
                                  fontStyle={"normal"}
                                  fontWeight={"500"}
                                  lineHeight={"normal"}
                                  letterSpacing={"-0.01rem"}
                                >
                                  Campaign Duration
                                </Heading>
                                <Stack
                                  direction={{ base: "column", md: "row" }}
                                  gap={isMobile ? "0" : "2rem"}
                                  pt={{ base: "0.88rem", md: "0.88rem" }}
                                >
                                  <Box>
                                    <Text fontSize={"0.94rem"}>
                                      Start Date
                                      <Text as="span" color={Colors.errorColor}>
                                        *
                                      </Text>
                                    </Text>
                                    <PosDatePicker
                                      labelColor={Colors.posShowFields}
                                      value={formik.values.start_date}
                                      label={"Select Start Date"}
                                      selectOtherMonths={true}
                                      minDate={dateTimeValue}
                                      showButtonBar={true}
                                      readOnlyInput={true}
                                      showTime={true}
                                      handleSelectData={(e) => {
                                        effectRun.current = true;
                                        formik.setFieldValue(
                                          "start_date",
                                          e.value
                                        );
                                      }}
                                      onFocus={() => {
                                        formik.setFieldTouched(
                                          "start_date",
                                          true
                                        );
                                      }}
                                    />
                                    <Text color={Colors.errorColor}>
                                      {formik.touched.start_date &&
                                      formik.errors.start_date ? (
                                        <span>{formik.errors.start_date}</span>
                                      ) : null}
                                    </Text>
                                  </Box>
                                  <Box mt={{ base: "2rem", md: "0" }}>
                                    <Text fontSize={"0.94rem"}>
                                      End Date
                                      <Text as="span" color={Colors.errorColor}>
                                        *
                                      </Text>
                                    </Text>
                                    <PosDatePicker
                                      labelColor={Colors.posShowFields}
                                      value={formik.values.end_date}
                                      label={"Select End Date"}
                                      selectOtherMonths={true}
                                      minDate={
                                        null != smsLastMinDate &&
                                        "" != smsLastMinDate &&
                                        smsLastMinDate >
                                          formik.values.start_date
                                          ? smsLastMinDate
                                          : null != formik.values.start_date &&
                                            "" != formik.values.start_date
                                          ? formik.values.start_date
                                          : dateTimeValue
                                      }
                                      showButtonBar={true}
                                      readOnlyInput={true}
                                      showTime={true}
                                      handleSelectData={(e) => {
                                        effectRun.current = true;
                                        formik.setFieldValue(
                                          "end_date",
                                          e.value
                                        );
                                      }}
                                      onFocus={() => {
                                        formik.setFieldTouched(
                                          "end_date",
                                          true
                                        );
                                      }}
                                    />
                                    <Text color={Colors.errorColor}>
                                      {formik.touched.end_date &&
                                      formik.errors.end_date ? (
                                        <span>{formik.errors.end_date}</span>
                                      ) : null}
                                    </Text>
                                  </Box>
                                </Stack>
                              </Box>
                            )}
                          </>
                        )}
                      {customerSmsPlanSubscribed == 1 &&
                        additionalSms > 0 &&
                        maxSmsPlanSelection == 0 &&
                        customerCancelPlanSubscription == 0 && (
                          <>
                            <Card>
                              <CardHeader>
                                {numberOfCampUser - additionalSms > 0 && (
                                  <Heading
                                    fontSize={"1.13rem"}
                                    fontStyle={"normal"}
                                    fontWeight={"300"}
                                    lineHeight={"normal"}
                                    letterSpacing={"-0.02rem"}
                                    color={Colors.posTextInfo}
                                    size="sm"
                                  >
                                {numberOfCampUserEmail > 0 ? numberOfCampUserEmail+" Email user(s) are eligible for this campaign & " : (formik.values.marketing_type == Constants.EMAIL || formik.values.marketing_type == Constants.SMS_EMAIL_BOTH) ? "No Email user(s) are eligible for this campaign & " : ""}
                                    {numberOfCampUser} SMS user(s) are eligible for
                                    this campaign. In your current subscription
                                    plan only {numberOfCampUser - additionalSms}{" "}
                                    sms are left.{" "}
                                    {scheduleNextMonth === 1
                                      ? "You have to upgrade your plan,"
                                      : "You have two choice,"}
                                  </Heading>
                                )}
                                {numberOfCampUser - additionalSms == 0 && (
                                  <Heading
                                    fontSize={"1.13rem"}
                                    fontStyle={"normal"}
                                    fontWeight={"300"}
                                    lineHeight={"normal"}
                                    letterSpacing={"-0.02rem"}
                                    color={Colors.posTextInfo}
                                    size="sm"
                                  >
                                    {numberOfCampUserEmail > 0 ? "Email user(s) are eligible for this campaign." : (formik.values.marketing_type == Constants.EMAIL || formik.values.marketing_type == Constants.SMS_EMAIL_BOTH) ? "No Email user(s) are eligible for this campaign & " : ""}
                                    {numberOfCampUser} SMS user(s) are eligible for
                                    this campaign. In your current subscription
                                    plan no sms are left.{" "}
                                    {scheduleNextMonth === 1
                                      ? "You have to upgrade your plan,"
                                      : "You have two choice,"}
                                  </Heading>
                                )}
                              </CardHeader>
                              <Divider />
                              <CardBody>
                                {additionalSmsOptions.map((option) => {
                                  return (
                                    <Stack
                                      flexDirection={"row"}
                                      key={option.key}
                                    >
                                      <RadioButton
                                        inputId={option.key}
                                        name="option"
                                        value={option}
                                        onChange={(e) =>
                                          onSelectUpgradePlanOption(e.value)
                                        }
                                        checked={
                                          selectedUpgradePlanOption.key ===
                                          option.key
                                        }
                                        unstyled
                                      />
                                      <Text
                                        htmlFor={option.key}
                                        className="ml-2"
                                      >
                                        {option.name}
                                      </Text>
                                    </Stack>
                                  );
                                })}
                              </CardBody>
                            </Card>
                            {formik.values.campaign_type ==
                              Constants.TARGETED &&
                              formik.values.per_sms_charge_flag == 1 && (
                                <Box mt={{ base: "2rem", md: "2rem" }}>
                                  <Heading
                                    color={Colors.posTextInfo}
                                    fontSize={"0.94rem"}
                                    fontStyle={"normal"}
                                    fontWeight={"500"}
                                    lineHeight={"normal"}
                                    letterSpacing={"-0.01rem"}
                                  >
                                    Campaign Duration
                                  </Heading>
                                  <Stack
                                    direction={{ base: "column", md: "row" }}
                                    gap={isMobile ? "0" : "2rem"}
                                    pt={{ base: "0.88rem", md: "0.88rem" }}
                                  >
                                    <Box>
                                      <Text fontSize={"0.94rem"}>
                                        Start Date
                                        <Text
                                          as="span"
                                          color={Colors.errorColor}
                                        >
                                          *
                                        </Text>
                                      </Text>
                                      <PosDatePicker
                                        labelColor={Colors.posShowFields}
                                        value={formik.values.start_date}
                                        label={"Select Start Date"}
                                        selectOtherMonths={true}
                                        minDate={dateTimeValue}
                                        showButtonBar={true}
                                        readOnlyInput={true}
                                        showTime={true}
                                        handleSelectData={(e) => {
                                          effectRun.current = true;
                                          formik.setFieldValue(
                                            "start_date",
                                            e.value
                                          );
                                        }}
                                        onFocus={() => {
                                          formik.setFieldTouched(
                                            "start_date",
                                            true
                                          );
                                        }}
                                      />
                                      <Text color={Colors.errorColor}>
                                        {formik.touched.start_date &&
                                        formik.errors.start_date ? (
                                          <span>
                                            {formik.errors.start_date}
                                          </span>
                                        ) : null}
                                      </Text>
                                    </Box>
                                    <Box mt={{ base: "2rem", md: "0" }}>
                                      <Text fontSize={"0.94rem"}>
                                        End Date
                                        <Text
                                          as="span"
                                          color={Colors.errorColor}
                                        >
                                          *
                                        </Text>
                                      </Text>
                                      <PosDatePicker
                                        labelColor={Colors.posShowFields}
                                        value={formik.values.end_date}
                                        label={"Select End Date"}
                                        selectOtherMonths={true}
                                        minDate={
                                          null != smsLastMinDate &&
                                          "" != smsLastMinDate &&
                                          smsLastMinDate >
                                            formik.values.start_date
                                            ? smsLastMinDate
                                            : null !=
                                                formik.values.start_date &&
                                              "" != formik.values.start_date
                                            ? formik.values.start_date
                                            : dateTimeValue
                                        }
                                        showButtonBar={true}
                                        readOnlyInput={true}
                                        showTime={true}
                                        handleSelectData={(e) => {
                                          effectRun.current = true;
                                          formik.setFieldValue(
                                            "end_date",
                                            e.value
                                          );
                                        }}
                                        onFocus={() => {
                                          formik.setFieldTouched(
                                            "end_date",
                                            true
                                          );
                                        }}
                                      />
                                      <Text color={Colors.errorColor}>
                                        {formik.touched.end_date &&
                                        formik.errors.end_date ? (
                                          <span>{formik.errors.end_date}</span>
                                        ) : null}
                                      </Text>
                                    </Box>
                                  </Stack>
                                </Box>
                              )}
                          </>
                        )}
                      {customerSmsPlanSubscribed == 0 &&
                        additionalSms > 0 &&
                        maxSmsPlanSelection == 0 &&
                        customerCancelPlanSubscription == 1 && (
                          <Card>
                            <CardHeader>
                              <Heading color={Colors.errorColor} size="md">
                                Alert!!
                              </Heading>
                            </CardHeader>
                            <Divider />
                            <CardBody>
                              <Text>
                                {numberOfCampUserEmail > 0 ? numberOfCampUserEmail+" Email user(s) are eligible for this campaign & " : (formik.values.marketing_type == Constants.EMAIL || formik.values.marketing_type == Constants.SMS_EMAIL_BOTH) ? "No Email user(s) are eligible for this campaign & " : ""}
                                {numberOfCampUser} SMS user(s) are eligible for this
                                campaign. In your current subscription plan only{" "}
                                {numberOfCampUser - additionalSms} sms are left.
                                To use more sms please{" "}
                                <Link
                                  fontSize={"0.94rem"}
                                  fontStyle={"normal"}
                                  fontWeight={"500"}
                                  lineHeight={"normal"}
                                  letterSpacing={"-0.01rem"}
                                  color={Colors.posNavbarLink}
                                  onClick={() => openSmsPlanModel(0)}
                                >
                                  Click here
                                </Link>{" "}
                                to subscribe an SMS plan.
                              </Text>
                            </CardBody>
                          </Card>
                        )}
                      {customerCancelPlanSubscription == 1 &&
                        scheduleNextMonth == 1 && (
                          <Card>
                            <CardHeader>
                              <Heading color={Colors.errorColor} size="md">
                                Alert!!
                              </Heading>
                            </CardHeader>
                            <Divider />
                            <CardBody>
                              <Text>
                                To create a campaign for next month you have to
                                subscribe an SMS plan. Please{" "}
                                <Link
                                  fontSize={"0.94rem"}
                                  fontStyle={"normal"}
                                  fontWeight={"500"}
                                  lineHeight={"normal"}
                                  letterSpacing={"-0.01rem"}
                                  color={Colors.posNavbarLink}
                                  onClick={() => openSmsPlanModel(0)}
                                >
                                  Click here
                                </Link>{" "}
                                to subscribe.
                              </Text>
                            </CardBody>
                          </Card>
                        )}
                    </Box>
                  ):(
                    <Box pt={"1rem"}>
                      <Text>
                        {numberOfCampUserEmail > 0 ? numberOfCampUserEmail+" Email user(s) are eligible for this campaign" : ""}
                      </Text>
                    </Box>
                  )}
                </Box>
                {(((customerSmsPlanSubscribed == 1 ||
                  customerCancelPlanSubscription == 1) &&
                  numberOfCampUser > 0 &&
                  additionalSms == 0) ||
                  formik.values.per_sms_charge_flag == 1) && (
                  <Flex
                    maxHeight={"28rem"}
                    width={isMobile ? "100%" : "50%"}
                    flexDirection="column"
                    alignItems="stretch"
                    overflowY="auto"
                  >
                    <Box p={"1.25rem"} bg={Colors.posPageDataBackground}>
                      <Heading
                        fontSize={"1.13rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.02rem"}
                        color={Colors.posTextInfo}
                      >
                        SMS Throughput
                      </Heading>
                      <Text
                        fontSize={"0.94rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.01rem"}
                        color={Colors.taxCreateExemptEbt}
                      >
                        Campaign Registry & Mobile Carriers have restricted your
                        per-day messages to {smsPerDay} per day. All messages
                        will be sent as per below schedule.
                      </Text>
                      <ul>
                        {smsDistribution.map((option, i) => {
                          return (
                            <li
                              key={i}
                              style={{
                                marginTop: "1rem",
                                listStyleType: "disc",
                                marginLeft: "1.1rem",
                              }}
                            >
                              <Stack flexDirection={"row"}>
                                <Text
                                  fontSize={"1.13rem"}
                                  fontStyle={"normal"}
                                  fontWeight={"300"}
                                  lineHeight={"normal"}
                                  letterSpacing={"-0.02rem"}
                                  color={Colors.posTextInfo}
                                  htmlFor={option.key}
                                >
                                  {option.date}: {option.sms_allotted} SMS
                                </Text>
                              </Stack>
                            </li>
                          );
                        })}
                      </ul>
                    </Box>
                  </Flex>
                )}
              </Flex>
              <Flex mt={{ base: "3rem", md: "3rem" }}>
                <PosFormButton
                  onClick={() =>
                    handlePreviousBtnClickRedirection(Constants.AUDIENCE_SCREEN)
                  }
                  buttonText={"Previous"}
                  ghostButton={true}
                />
                <Spacer />
                <PosFormButton
                  isDisabled={
                    formik.values.title.length <= 0 || formik.errors.title
                      ? true
                      : false
                  }
                  buttonText={"Save as Draft"}
                  CancelButton={true}
                  onClick={() => {
                    savedAsDraftClick();
                  }}
                />
                <PosFormButton
                  isDisabled={
                    undefined == formik.values.select_audience ||
                    null == formik.values.select_audience ||
                    "" == formik.values.select_audience ||
                    (Constants.SELECT_SEGMENT ==
                      formik.values.select_audience &&
                      (undefined == formik.values.selectedSegments ||
                        null == formik.values.selectedSegments ||
                        "" == formik.values.selectedSegments)) ||
                    formik.errors.start_date ||
                    formik.errors.end_date ||
                    formik.errors.scheduled_type ||
                    (Constants.SCHEDULED_LATER ==
                      formik.values.scheduled_type &&
                      (undefined == formik.values.scheduled_start_date_time ||
                        null == formik.values.scheduled_start_date_time ||
                        "" == formik.values.scheduled_start_date_time)) ||
                    (formik.values.campaign_type == Constants.TARGETED &&
                      selectedCouponsArray.length == 0) ||
                    buttonDisable
                      ? true
                      : false
                  }
                  buttonsubmit={"Submit"}
                  SubmitButton={true}
                  onClick={() => {
                    CreateClick();
                  }}
                />
              </Flex>
            </Box>
          )}
        </Box>
        <Modal
          isOpen={isCoupFormModelOpen}
          size={modalSize}
          isCentered
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              mt={{ base: "0", md: "1.56rem" }}
              style={{ display: "inline-flex", justifyContent: "center" }}
            >
              Add Max Usage Per Reward Customer To Coupon
            </ModalHeader>
            <Divider />
            <ModalBody
              pb={{ base: 4, md: "1.38rem" }}
              pl={{ base: 4, lg: "1.06rem" }}
              pr={{ base: 4, md: "1.75rem" }}
            >
              <Box>
                <PosLable
                  requiredLabel={true}
                  name={"Max Usage Per Reward Customer"}
                  fontWeight={"500"}
                />
                <PosInput
                  id="max_usage"
                  placeholder={"Enter Max Usage Per Reward Customer"}
                  inputValue={couponMaxUsageValue}
                  posNumberInput={true}
                  inputType={"number"}
                  handleInputChange={(e) => onChangeMaxUsage(e.target.value)}
                  handleBlur={(e) => onChangeMaxUsage(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "-" || e.key === "." || e.key === "e") {
                      e.preventDefault();
                    }
                  }}
                />
                <Text color={Colors.errorColor}>
                  {maxUsageValidationError && (
                    <span>{maxUsageValidationError}</span>
                  )}
                </Text>
              </Box>
            </ModalBody>
            <ModalFooter
              flexDirection={"column"}
              alignItems={"end"}
              style={{ paddingInlineEnd: "var(--chakra-space-4" }}
            >
              <Flex gap={{ base: 3, md: 1 }} mb={"1.69rem"}>
                <PosFormButton
                  buttonText={"Cancel"}
                  CancelButton={true}
                  onClick={() => {
                    onCancelClickCoupMaxUsgFormModel();
                  }}
                />
                <PosFormButton
                  isDisabled={
                    maxUsageValidationError ||
                    buttonDisable ||
                    couponMaxUsageValue == null ||
                    couponMaxUsageValue == ""
                      ? true
                      : false
                  }
                  buttonsubmit={"Submit"}
                  SubmitButton={true}
                  onClick={() => {
                    onAddCouponMaxUsage();
                  }}
                />
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
      <PosDialogAlert
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        title={"WARNING"}
        discription={
          "Data you have entered so far will be cleared. Are you sure you want to go on home page?"
        }
        onClick={goToCampaignTypeScreen}
        buttonNames={["Cancel", "Yes"]}
      />
      <PosDialogAlert
        leastDestructiveRef={cancelRef}
        onClose={onSmsNumberNotFoundAlertClose}
        isOpen={isSmsNumberNotFoundAlertOpen}
        title={"WARNING"}
        discription={
          "Your SMS Number is still in the approval process. It can take anywhere from one week to four weeks to be approved. Once it’s approved, you can start creating and sending campaigns."
        }
        onClick={onSmsNumberNotFoundAlertClose}
        alertDialogHeight={"auto"}
        footerSingleButton={true}
        buttonNames={"Ok"}
      />
      <Modal
        isOpen={isCoupProdViewModelOpen}
        onClose={onCoupProdViewModelClose}
        size={"xl"}
        isCentered
        closeOnOverlayClick={false}
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Product Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <PosTable
              columnNames={couponProductViewColumns}
              rawdata={selectedCouponProdViewDetails}
              totalcount={selectedCouponProdViewDetails.length}
              footerHide={true}
              noAction={true}
              sortColumnName={null}
              page={null}
              rowsPerPage={null}
              order={null}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isSendTestEmailModelOpen}
        size={modalSize}
        onClose={onCancelClickSendTestEmailModel}
        isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Send Test Emails</ModalHeader>
          <Divider />
          <ModalBody
            pb={{ base: 4, md: "1.38rem" }}
            pl={{ base: 4, lg: "1.06rem" }}
            pr={{ base: 4, md: "1.75rem" }}
          >
            <Flex mt={{ base: "2rem", md: "2rem" }}>
              <Text
                color={Colors.couponDropDownLabelColor}
                fontSize={"0.94rem"}
                fontStyle={"normal"}
                fontWeight={"500"}
                lineHeight={"normal"}
                letterSpacing={"-0.01rem"}
              >
                Email Subject:
              </Text>
              <Text
                flex="1"
                pl={"0.44rem"}
                color={Colors.posTextInfo}
                fontSize={"0.94rem"}
                fontStyle={"normal"}
                fontWeight={"300"}
                lineHeight={"normal"}
                letterSpacing={"-0.01rem"}
              >
                {subjectLineValue}
              </Text>
            </Flex>
            <Box mt={{ base: "2rem", md: "2rem" }}>
              <PosChipsInput
                placeholder={"Type any Email Address"}
                onAdd={(e) => emailValue(e.value)}
                onRemove={(e) =>
                  setSelectedEmailAddToSendTestEmail(
                    selectedEmailAddToSendTestEmail.filter(
                      (value) => value != e.value
                    )
                  )
                }
                value={selectedEmailAddToSendTestEmail}
                addOnBlur={true}
                allowDuplicate={false}
              ></PosChipsInput>
              <Text color={Colors.errorColor}>{validationErrors}</Text>
            </Box>
            <Text
              pt={{ base: "3.13rem", md: "3.13rem" }}
              fontSize={"0.94rem"}
              fontStyle={"normal"}
              fontWeight={"300"}
              lineHeight={"normal"}
              letterSpacing={"-0.01rem"}
              color={Colors.sendTestEmailTextInfoColor}
            >
              If you want to check what the email looks when sent, you can send
              test emails from here.
            </Text>
          </ModalBody>
          <Divider />
          <ModalFooter
            pt={{ base: "2rem", md: "2rem" }}
            flexDirection={"column"}
            alignItems={"end"}
            style={{ paddingInlineEnd: "var(--chakra-space-4" }}
          >
            <Flex gap={{ base: 3, md: 1 }} mb={"1.69rem"}>
              <PosFormButton
                buttonText={"Cancel"}
                CancelButton={true}
                onClick={() => {
                  onCancelClickSendTestEmailModel();
                }}
              />
              <PosFormButton
                isDisabled={
                  selectedEmailAddToSendTestEmail.length == 0 || buttonDisable
                    ? true
                    : false
                }
                buttonsubmit={"Submit"}
                SubmitButton={true}
                onClick={sendTestEmailForTemplate}
              />
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isSmsPlanModelOpen}
        onClose={onSmsPlanModelClose}
        isCentered
        size={"2xl"}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{smsPlanModelLabel}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <PosTable
              columnNames={smsPlanTableColumns}
              rawdata={planListDetails}
              totalcount={planListDetails.length}
              footerHide={true}
              noAction={true}
              sortColumnName={null}
              page={null}
              rowsPerPage={null}
              order={null}
            />
            {scheduleNextMonth == 1 && customerCancelPlanSubscription == 0 && (
              <Box
                mt={{ base: "2rem", md: "2rem" }}
                mb={{ base: "0.5rem", md: "0.5rem" }}
              >
                <PosAlert
                  alertStatus={"success"}
                  alertTitle={""}
                  width={"100%"}
                  alertDescription={
                    "Selected plan will be applicable from the next month."
                  }
                />
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            <PosFormButton
              isDisabled={
                Object.keys(selectedSmsPlan).length === 0 || buttonDisable
                  ? true
                  : false
              }
              buttonsubmit={"Submit"}
              SubmitButton={true}
              onClick={submitPlan}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default WithRouter(CampaignCreate);