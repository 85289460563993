import {
  Box,
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useRadioGroup,
} from "@chakra-ui/react";
import AddIcon from "@mui/icons-material/Add";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useSubmit,
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosChipsInput,
  PosDropDown,
  PosFormButton,
  PosInput,
  PosLable,
  PosSwitchButton,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { addGlobalSettingCategory } from "./MasterAdminGlobalSettingService";

const MasterAdminGlobalSettingCreate = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const stateData = useLocation();
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const effectRun = useRef(true);
  const [options, setOptions] = useState([]);
  const [validationErrors, setValidationErrors] = useState(
    "Please enter dropdown options."
  );
  const [categories, setGlobalSettingsCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);

  // const cancelRef = React.useRef();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const {
    isOpen: isRewardModalOpen,
    onOpen: onRewardModalOpen,
    onClose: onRewardModalClose,
  } = useDisclosure();
  const [paramData, setParamData] = useState(stateData.state); 

  useEffect(() => {
    if (props.isRewardOpen) {
      myContext.handleLoading(true);
    }
    return () => {};
  }, [props.isRewardOpen]);

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          if (
            undefined != loaderResponse.data?.data &&
            null != loaderResponse.data?.data &&
            "" != loaderResponse.data?.data &&
            loaderResponse.data?.data.length > 0
          ) {
            setGlobalSettingsCategories(loaderResponse.data?.data);
          }
          myContext.handleLoading(false);
        } else {
          let actionData = loaderResponse;
          error({ actionData });
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else {
        if(undefined != JSON.parse(localStorage.getItem("currentUser")) && JSON.parse(localStorage.getItem("currentUser")).role==Constants.SUPER_ADMIN_USER_ROLE){
          props.navigate(
              Constants.MASTER_ADMIN_GLOBLE_SETTINGS_PATH, {
                state: paramData,
              }
          );
        }
        else{
        props.navigate(
          "/" +
            process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE +
            Constants.MASTER_ADMIN_GLOBLE_SETTINGS_PATH, {
              state: paramData,
            }
        );
        }
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.MASTER_ADMIN_GLOBAL_MODULE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
      /* else {
          let actionData = actionResponse;
          error({ actionData });
        } */
    }
  }, [actionResponse]);
  const handleInputTypeChange = (value) => {
    formik.setFieldValue("input_type", value);
    setOptions([]);
    setValidationErrors("Please enter dropdown options.");
  };

  const typeValue = (type) => {
    options.push(type);
    var count = options.filter(
      (x) => x.toUpperCase() == type.toUpperCase()
    ).length;
    if (count >= 2) {
      options.pop();
    }
    setOptions([...options]);
    setValidationErrors("");
  };

  const handleRemove = (chipToRemove) => {
    let dt = options.filter((chip) => chip != chipToRemove);
    if (dt.length <= 0) {
      setValidationErrors("Please enter dropdown options.");
    }
    setOptions(dt);
  };

  const {
    getRootProps: getInputTypeRootProps,
    getRadioProps: getInputTypeRadioProps,
  } = useRadioGroup({
    name: "value",
    defaultValue: "",
    onChange: handleInputTypeChange,
  });
  const CardRadioSlot = ({ children, isChecked, ...props }) => {
    return (
      <Box as="label">
        <input type="radio" {...props} style={{ display: "none" }} />
        <Box
          cursor="pointer"
          borderWidth="2px"
          borderRadius="0.625rem"
          // h={"3.25rem"}
          // w={"7rem"}
          boxShadow="md"
          // bg={isChecked ? "teal.600" : "white"}
          color={isChecked ? "white" : "black"}
          borderColor={isChecked ? Colors.primeTaxInfo : "rgba(109, 145, 254, 0.08)"}
          _hover={{
            bg: isChecked ? "messenger" : "gray.100",
          }}
          _checked={{
            bg: "teal.600",
            color: "white",
            borderColor: "#5881FE",
          }}
          px={5}
          py={3}
        >
          {children}
        </Box>
      </Box>
    );
  };
  const formik = useFormik({
    initialValues: {
      setting_display_name: "",
      setting_key: "",
      selectedCategory: "",
      field_sort: "",
      input_type: 1,
      required: false,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      setting_display_name: Yup.string()
        .trim()
        .required(Constants.TAX_NAME_REQUIRED)
        .max(191, Constants.TAX_NAME_LENGTH_GREATE_THEN_191),
      setting_key: Yup.string()
        .trim()
        .required(Constants.GLOBAL_SETTING_KEY_REQUIRED)
        .max(191, Constants.GLOBAL_SETTING_KEY_LENGTH_GREATE_THEN_191),
      selectedCategory: Yup.string().required(
        Constants.GLOBAL_SETTING_CATEGORY_REQUIRED
      ),
      input_type: Yup.string().required(Constants.TAX_TYPE_REQUIRED),
      field_sort: Yup.number()
        .required(Constants.GLOBAL_SETTING_FIELD_SORT_REQUIRED)
        .integer(Constants.GLOBAL_SETTING_FIELD_SORT_NOT_VALID)
        .typeError(Constants.GLOBAL_SETTING_FIELD_SORT_NOT_VALID)
        .min(1, Constants.GLOBAL_SETTING_FIELD_SORT_MIN_VALUE)
        .max(100, Constants.GLOBAL_SETTING_FIELD_SORT_MAX_VALUE),
    }),
    onSubmit: async (values) => {
      const modCategory = {
        value: selectedCategory.id,
      };
      if (
        undefined != values.input_type &&
        null != values.input_type &&
        "" != values.input_type &&
        1 == values.input_type &&
        options.length > 0
      ) {
        values.options = JSON.stringify(options);
      }
      let data = {
        ...values,
        category: JSON.stringify(modCategory),
      };
      myContext.handleLoading(true);
      setButtonDisable(true);
      submit(data, {
        method: Constants.POST,
        path: Constants.GLOBAL_SETTING_CREATE_API,
      });
    },
  });
  const handleClose = () => {
    formikModal.resetForm();
    if (props.onRewardClose) {
      props.onRewardClose();
    }
  };

  const formikModal = useFormik({
    initialValues: {
      name: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required(Constants.TAX_NAME_REQUIRED)
        .max(191, Constants.TAX_NAME_LENGTH_GREATE_THEN_191),
    }),
    onSubmit: async (values) => {
      setButtonDisable(true);
      myContext.handleLoading(true);
      addGlobalSettingCategory(values)
        .then((response) => {
          if (
            undefined !== response?.data?.flag &&
            null !== response?.data?.flag &&
            response?.data?.flag == true
          ) {
            setGlobalSettingsCategories(response.data?.data?.allCategory);
            let cat = response.data?.data?.allCategory.filter(
              (chip) => chip.id == response.data?.data?.id
            );
            setSelectedCategory(cat[0]);
            formik.setFieldValue("selectedCategory", response.data?.data?.id);
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.MASTER_ADMIN_GLOBAL_MODULE,
              alertDescription: response.data.message,
            });
            setButtonDisable(false);
            myContext.handleLoading(false);
            handleClose();
            onRewardModalClose();
          } else {
            myContext.handleLoading(false);
            setButtonDisable(false);
            let actionData = response;
            error({ actionData });
          }
        })
        .catch((err) => {
          setButtonDisable(false);
          myContext.handleLoading(false);
          let actionData = err;
          error({ actionData });
        });
    },
  });

  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(
        formik.values.setting_display_name.length <= 0 ||
        formik.errors.setting_display_name ||
        formik.errors.type ||
        formik.errors.field_sort ||
        buttonDisable
      )
    ) {
      formik.handleSubmit();
    }
  };

  const inputTypeOptions = [
    {
      title: "Dropdown",
      value: 1,
    },
    {
      title: "Text Field",
      value: 0,
    },
  ];
  const handleOptionSelect = (e) => {
    if(undefined != e.value &&  null != e.value && '' != e.value){
      setSelectedCategory(e.value);
      formik.setFieldValue("selectedCategory", e.value.id);  
    }
  };
  const openDialog = (index) => {
    setIsDialogOpen(true);
    onRewardModalOpen();
  };
  const handleCloseDialog = () => {
    formikModal.resetForm();
    setIsDialogOpen(false);
    onRewardModalClose();
  };

  return (
    <Flex bg={Colors.loginAuthBackground}>
      <Box flex="1">
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={"flex-start"}
          pt={{ base: "1.5rem", md: "3.06rem" }}
          pb={{ base: "1.5rem", md: "0" }}
          /* pl={"1.88rem"} */
          pl={{ base: "1rem", lg: "1.88rem" }}
          pr={{ base: "0.5rem", md: "1.88rem" }}
          gap={2}
        >
          <PosBreadCrumb
            handleClick={(i) => {
              if(undefined != JSON.parse(localStorage.getItem("currentUser")) && JSON.parse(localStorage.getItem("currentUser")).role==Constants.SUPER_ADMIN_USER_ROLE){
                props.navigate(
                    Constants.MASTER_ADMIN_GLOBLE_SETTINGS_PATH,
                  {
                    state: stateData.state,
                  }
                );
              }
              else{
              // pass param data in tax list page
              props.navigate(
                "/" +
                  process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE +
                  Constants.MASTER_ADMIN_GLOBLE_SETTINGS_PATH,
                {
                  state: stateData.state,
                }
              );
              }
            }}
            breadCrumNames={["Global Settings", "Create a Global Setting"]}
            breadCrumTitle={"Create a Global Setting"}
          />
          <Spacer />
          <Stack direction={"row"} alignSelf={"flex-end"}>
            <PosFormButton
              onClick={() => {
                if(undefined != JSON.parse(localStorage.getItem("currentUser")) && JSON.parse(localStorage.getItem("currentUser")).role==Constants.SUPER_ADMIN_USER_ROLE){
                  props.navigate(
                      Constants.MASTER_ADMIN_GLOBLE_SETTINGS_PATH,
                    {
                      state: stateData.state,
                    }
                  );
                }
                else{
                // pass param data in tax list page
                props.navigate(
                  "/" +
                    process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE +
                    Constants.MASTER_ADMIN_GLOBLE_SETTINGS_PATH,
                  {
                    state: stateData.state,
                  }
                );
                }
              }}
              buttonText={"Cancel"}
              CancelButton={true}
              isDisabled={buttonDisable}
            />
            <PosFormButton
              isDisabled={
                formik.values.setting_display_name.length <= 0 ||
                formik.errors.setting_display_name ||
                formik.errors.input_type ||
                formik.errors.field_sort ||
                (undefined != formik.values.input_type &&
                  null != formik.values.input_type &&
                  "" != formik.values.input_type &&
                  1 == formik.values.input_type &&
                  options.length <= 0) ||
                buttonDisable
                  ? true
                  : false
              }
              buttonsubmit={"Submit"}
              SubmitButton={true}
              onClick={formik.handleSubmit}
            />
          </Stack>
        </Flex>
        <Box
          mt={{ base: 4, lg: 12 }}
          ml={{ base: 4, lg: 14 }}
          mr={{ base: 4, lg: 14 }}
          borderRadius="0.63rem"
          bg={Colors.posPageDataBackground}
          boxShadow={"0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow}
          p="8"
        >
          <Text
            fontSize="1.125rem"
            w="10.5rem"
            color={Colors.posTextInfo}
            style={{
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "-0.27px",
            }}
          >
            Basic Details
          </Text>
          <Stack spacing={3}>
            <PosLable
              fontWeight={"500"}
              requiredLabel={true}
              name={"Display Name: (Ex: Pax IP)"}
            />
            <PosInput
              id="setting_display_name"
              placeholder={"Enter Display Name"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.setting_display_name}
              handleBlur={(e) => {
                formik.setFieldValue(
                  "setting_display_name",
                  formik.values.setting_display_name.trim()
                );
                formik.handleBlur(e);
              }}
              onKeyDown={handleKeyDown}
              posInput={true}
              inputType={"text"}
              inputError={
                formik.touched.setting_display_name &&
                formik.errors.setting_display_name
              }
            />
            <Text color={Colors.errorColor}>
              {formik.touched.setting_display_name &&
              formik.errors.setting_display_name ? (
                <span>{formik.errors.setting_display_name}</span>
              ) : null}
            </Text>
          </Stack>
          <PosLable
            fontWeight={"500"}
            requiredLabel={true}
            name={"Key: (Ex: pax_ip)"}
          />
          <PosInput
            id="setting_key"
            placeholder={"Enter Key"}
            handleInputChange={formik.handleChange}
            inputValue={formik.values.setting_key}
            handleBlur={(e) => {
              formik.setFieldValue(
                "setting_key",
                formik.values.setting_key.trim()
              );
              formik.handleBlur(e);
            }}
            onKeyDown={handleKeyDown}
            posInput={true}
            inputType={"text"}
            inputError={formik.touched.setting_key && formik.errors.setting_key}
          />
          <Text color={Colors.errorColor}>
            {formik.touched.setting_key && formik.errors.setting_key ? (
              <span>{formik.errors.setting_key}</span>
            ) : null}
          </Text>
          <PosLable
            fontWeight={"500"}
            requiredLabel={true}
            name="Value: (Select Input Type)"
          />
          <Stack direction={{ md: "row" }}>
            {inputTypeOptions.map((option, index) => {
              const radio = getInputTypeRadioProps({
                value: option.value,
              });
              return (
                <CardRadioSlot
                  key={index}
                  {...radio}
                  isChecked={formik.values.input_type == option.value}
                >
                  <Flex flexDirection="row" alignItems="center">
                    <Text
                      fontWeight="bold"
                      color={Colors.posTextInfo}
                      textAlign="center"
                    >
                      {option.title}
                    </Text>
                  </Flex>
                </CardRadioSlot>
              );
            })}
            {/* </HStack> */}
          </Stack>
          <Text color={Colors.errorColor}>
            {formik.touched.type && formik.errors.type ? (
              <span>{formik.errors.type}</span>
            ) : null}
          </Text>
          {formik.values.input_type == 1 && (
            <Stack direction={{ base: "column", md: "row" }}>
              <Box>
                <PosLable
                  fontWeight={"500"}
                  requiredLabel={true}
                  name="Dropdown Options (Press Enter or Tab to add options)"
                />
                <PosChipsInput
                  style={{
                    backgroundColor: Colors.posviewbgcolor,
                    display: "flex",
                    padding: "0.1rem",
                    paddingLeft: "0.1rem",
                    gap: "0.1rem",
                    borderRadius: "0.1rem",
                    border: "1px solid #E3E3E3",
                  }}
                  addOnTab={true}
                  onAdd={(e) => {
                    typeValue(e.value);
                  }}
                  onRemove={(e) => {
                    handleRemove(e.value);
                  }}
                  addOnBlur={true}
                  allowDuplicate={false}
                  placeholder="Add Options Here..."
                  value={options}
                />
                <Text color={Colors.errorColor}>{validationErrors}</Text>
                <Text color={Colors.errorColor}>
                  {formik.touched.type && formik.errors.type ? (
                    <span>{formik.errors.type}</span>
                  ) : null}
                </Text>
              </Box>
            </Stack>
          )}
          <Stack spacing={3} mt="1rem">
            <Box display={"flex"}>
              <Box w={{ base: "100%", md: "100%" }}>
                <PosLable
                  name={"Category"}
                  label={true}
                  requiredLabel={true}
                  fontWeight={500}
                ></PosLable>
                <PosDropDown
                  width={"100%"}
                  value={selectedCategory}
                  onChange={handleOptionSelect}
                  onBlur={(e) => {
                    formik.setFieldTouched("selectedCategory");
                    formik.handleBlur(e);
                  }}
                  options={categories}
                  optionLabel={"name"}
                  lableAvailable={true}
                  className="w-full md:w-20rem"
                  style={{ width: "100%" }}
                  placeholder={"Select Category"}
                ></PosDropDown>
                <Text color={Colors.errorColor}>
                  {formik.touched.selectedCategory &&
                  formik.errors.selectedCategory ? (
                    <span>{formik.errors.selectedCategory}</span>
                  ) : null}
                </Text>
              </Box>
              <Box
                display={"flex"}
                alignItems={
                  formik.errors?.selectedCategory?.length > 0
                    ? "center"
                    : "flex-end"
                }
                justifyContent={"center"}
                w={"3rem"}
                ml={"1rem"}
              >
                <IconButton
                  onClick={() => openDialog()}
                  sx={{
                    marginTop:
                      formik.errors?.selectedCategory?.length > 0 ? "2rem" : 0,
                  }}
                  isRound={true}
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>
            <PosLable
              fontWeight={"500"}
              requiredLabel={true}
              name={"Field Sort"}
            />
            <PosInput
              id="field_sort"
              placeholder={"Enter Field Sort"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.field_sort}
              handleBlur={(e) => {
                formik.handleBlur(e);
                formik.setFieldValue("field_sort", formik.values.field_sort);
              }}
              onKeyDown={handleKeyDown}
              posNumberInput={true}
              inputType={"number"}
              inputError={formik.touched.field_sort && formik.errors.field_sort}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.field_sort && formik.errors.field_sort ? (
                <span>{formik.errors.field_sort}</span>
              ) : null}
            </Text>
          </Stack>
          <Flex
          flexDirection={"row"} >
          <PosLable
            fontWeight={"500"}
            requiredLabel={true}
            label={true}
            name={"Required"}
          />
            <PosSwitchButton
              id="required"
              switchValue={formik.values.required}
              onChange={formik.handleChange}
              mt={"2rem"}
              ml={"1.5rem"}
            />
            </Flex>
        </Box>
        <>
          <Modal
            isOpen={isRewardModalOpen}
            onClose={onRewardModalClose}
            size={"lg"}
            scrollBehavior={"inside"}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Add New Category</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <PosLable
                  fontWeight={500}
                  requiredLabel={true}
                  name={"Name"}
                  label={true}
                />
                <PosInput
                  id="name"
                  placeholder={"Enter Category Name"}
                  color={Colors.posTextInfo}
                  handleInputChange={formikModal.handleChange}
                  inputValue={formikModal.values.name}
                  handleBlur={formikModal.handleBlur}
                  onKeyDown={handleKeyDown}
                  posInput={true}
                  inputType={"text"}
                  inputError={
                    formikModal.touched.name && formikModal.errors.name
                  }
                />
                <Text color={Colors.errorColor}>
                  {formikModal.touched.name && formikModal.errors.name ? (
                    <span>{formikModal.errors.name}</span>
                  ) : null}
                </Text>
              </ModalBody>
              <ModalFooter>
                <Flex mb={"2.69rem"}>
                  <PosFormButton
                    buttonText={"Cancel"}
                    CancelButton={true}
                    // ref={cancelRef}
                    onClick={handleCloseDialog}
                    isDisabled={buttonDisable}
                  />
                  <PosFormButton
                    isDisabled={
                      formikModal.values.name.length <= 0 ||
                      formikModal.errors.name ||
                      buttonDisable
                        ? true
                        : false
                    }
                    buttonsubmit={"Submit"}
                    SubmitButton={true}
                    onClick={formikModal.handleSubmit}
                  />
                  {/* <Button
                    colorScheme="messenger"
                    ml={3}
                    SubmitButton={true}
                    onClick={formikModal.handleSubmit}
                    isDisabled={
                      formikModal.values.name.length <= 0 ||
                      formikModal.errors.name ||
                      buttonDisable
                        ? true
                        : false
                    }
                  >
                    {"Submit"}
                  </Button> */}
                </Flex>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      </Box>
    </Flex>
  );
};

export default WithRouter(MasterAdminGlobalSettingCreate);
